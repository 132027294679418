import { computeDynamicRequirement } from '@trustlayer/dynamic-requirement';

import { CustomFieldType } from '@modules/custom-field/constants';

export const overrideAttrs = (
  requirement: {
    attributeLabel: string;
    attributeDescription?: string;
    attributeId: string;
    masterDocumentAttributeId: string;
  },
  customField: {
    type: string;
    options: { key: string; value: string }[];
  },
  customFieldValue: string,
) => {
  let value: string | undefined = customFieldValue;
  if (customField.type === CustomFieldType.Dropdown) {
    const option = customField.options.find((opt) => opt.key === value);
    value = option?.value;
  }

  return computeDynamicRequirement({
    requirement,
    input: { value: value?.toString() || '' },
  });
};
