import { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  Typography,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import isEqual from 'lodash/isEqual';
import type React from 'react';
import { useState } from 'react';
import styled from 'styled-components';

import Card from '@common/components/Card';
import RouteLeavingGuardModal from '@common/components/RouteLeavingGuardModal';
import Table from '@common/components/Table.legacy';
import { useMutation, useQuery } from '@graphql/hooks';
import { UPDATE_PARTY_CUSTOM_FIELD_MUTATION } from '@modules/custom-field/mutations';

import { type CustomField, CustomFieldType } from '../../constants';
import { PARTY_CUSTOM_FIELDS_QUERY } from '../../queries';

import {
  isCustomFieldValueValid,
  mapCustomFieldsToFormValues,
  parseCustomFieldValue,
} from './PartyCustomFields.utils';

const EditableCell = ({
  children,
  isEditing,
  customField,
  ...rest
}: {
  children: React.ReactNode;
  isEditing: boolean;
  customField: CustomField;
}) => {
  const getInputByType = (
    type: (typeof CustomFieldType)[keyof typeof CustomFieldType],
  ) => {
    const placeholder = `${
      customField.type === CustomFieldType.Dropdown ? 'Select' : 'Insert'
    } ${customField.name}`;

    switch (type) {
      case CustomFieldType.Number:
        return <InputNumber placeholder={placeholder} />;
      case CustomFieldType.Text:
        return <Input placeholder={placeholder} type="text" />;
      case CustomFieldType.Dropdown:
        return (
          <Select
            placeholder={placeholder}
            data-cy="partyCustomFieldsDropdown"
            allowClear
            showSearch
          >
            {customField?.options?.map((option) => (
              <Select.Option key={option._id} value={JSON.stringify(option)}>
                {option.value}
              </Select.Option>
            ))}
          </Select>
        );

      default:
        break;
    }
  };

  return (
    <StyledInputWrapper {...{ isEditing, ...rest }}>
      {isEditing ? (
        <Form.Item
          name={customField._id}
          rules={
            customField.type === CustomFieldType.Number
              ? [
                  {
                    validator: (_, value) => {
                      if (value !== null) {
                        if (isNaN(value)) {
                          return Promise.reject('Value must be a number');
                        }
                        return Promise.resolve();
                      }
                      return Promise.resolve();
                    },
                  },
                ]
              : []
          }
        >
          {getInputByType(customField.type)}
        </Form.Item>
      ) : (
        children
      )}
    </StyledInputWrapper>
  );
};

const PartyCustomFieldsTable = ({
  partyId,
  isInProjectContext,
}: {
  partyId: string;
  isInProjectContext: boolean;
}) => {
  const [form] = Form.useForm();

  const { data, loading } = useQuery(PARTY_CUSTOM_FIELDS_QUERY, {
    variables: { partyId },
  });

  const [updateCustomField, { loading: isUpdating }] = useMutation(
    UPDATE_PARTY_CUSTOM_FIELD_MUTATION,
  );

  const [editModeFieldId, setEditModeFieldId] = useState<null | string>(null);
  const [hasValueChanged, setHasValueChanged] = useState(false);

  const columns: ColumnsType<CustomField> = [
    {
      title: 'Name',
      width: '40%',
      dataIndex: 'name',
      key: 'name',
      render: (_: unknown, record) => {
        return (
          <Space direction="vertical" key={record._id}>
            <Typography.Text strong>{record.name}</Typography.Text>
            {record.description && (
              <StyledSecondaryText type="secondary">
                {record.description}
              </StyledSecondaryText>
            )}
          </Space>
        );
      },
    },
    {
      title: 'Value',
      width: '30%',
      dataIndex: 'value',
      key: 'value',
      render: (_: unknown, record) => {
        return (
          <EditableCell
            isEditing={editModeFieldId === record._id}
            customField={record}
            key={record._id}
          >
            {isCustomFieldValueValid(record.value) ? (
              <Typography.Text>{record.value}</Typography.Text>
            ) : (
              <Typography.Text type="secondary">Not Set</Typography.Text>
            )}
          </EditableCell>
        );
      },
    },
    {
      dataIndex: 'actions',
      width: '30%',
      key: 'actions',
      render: (_, record) => {
        return (
          <StyledActionsWrapper>
            {editModeFieldId === record._id ? (
              <Space direction="horizontal">
                <Button
                  onClick={handleOnCancel}
                  size="small"
                  disabled={isUpdating}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  type="primary"
                  onClick={form.submit}
                  disabled={isUpdating}
                  loading={isUpdating}
                >
                  Save changes
                </Button>
              </Space>
            ) : (
              <Button
                icon={<EditOutlined />}
                size="small"
                onClick={() => setEditModeFieldId(record._id)}
                disabled={loading}
              >
                Edit
              </Button>
            )}
          </StyledActionsWrapper>
        );
      },
    },
  ];

  const formInitialValues = mapCustomFieldsToFormValues(
    //@ts-expect-error - probably a bug in the graphql api schema, nodes value is optional
    data?.getPartyDataV2?.customFields?.nodes,
  );

  const handleUpdate = async (value: Record<string, string>) => {
    const currentValueKey = Object.keys(value)[0];
    const currentValues = parseCustomFieldValue(value[currentValueKey]);
    updateCustomField({
      variables: {
        data: {
          partyId: partyId,
          customField: {
            _id: currentValueKey,
            ...currentValues,
          },
        },
      },
    });
    setEditModeFieldId(null);
    setHasValueChanged(false);
  };

  const handleOnFinish = (value: Record<string, any>) => {
    if (
      !isInProjectContext &&
      data?.getPartyDataV2?.projectStats?.totalProjectsCount
    ) {
      Modal.confirm({
        title: 'Overwrite in projects?',
        content: (
          <>
            Updating this custom field will affect{' '}
            <Typography.Text strong>
              {data?.getPartyDataV2.projectStats.totalProjectsCount} projects
            </Typography.Text>
            . Are you sure you want to continue?
          </>
        ),
        onOk: () => handleUpdate(value),
        okText: 'Yes, overwrite',
      });
    } else {
      handleUpdate(value);
    }
  };

  const handleOnCancel = () => {
    setEditModeFieldId(null);
    setHasValueChanged(false);
    form.resetFields();
  };

  return (
    <div>
      <RouteLeavingGuardModal
        when={Boolean(editModeFieldId) && hasValueChanged}
        shouldCancelLeavesPage={true}
        onConfirm={async () => await handleUpdate(form.getFieldsValue(true))}
        okText="Save changes"
        message={
          <>
            You have unsaved changes.{' '}
            {!isInProjectContext &&
            data?.getPartyDataV2?.projectStats?.totalProjectsCount ? (
              <>
                Updating this custom field will affect{' '}
                <Typography.Text strong>
                  {data?.getPartyDataV2.projectStats.totalProjectsCount}{' '}
                  projects
                </Typography.Text>
                .{' '}
              </>
            ) : null}
            Are you sure you want to continue?
          </>
        }
        cancelText="Close without saving"
      />
      <Form
        component={false}
        initialValues={formInitialValues}
        form={form}
        onFinish={handleOnFinish}
        onValuesChange={(val: Record<string, any>, allValues) => {
          setHasValueChanged(!isEqual(formInitialValues, allValues));
        }}
      >
        <StyledCard
          title={
            <Space direction="horizontal">
              <span>Custom Fields</span>
              {data && (
                <StyledCountWrapper>
                  {data.getPartyDataV2?.customFields?.totalCount}
                </StyledCountWrapper>
              )}
            </Space>
          }
        >
          {/* @ts-ignore - need to investigate why this is not working */}
          <StyledTable
            loading={loading}
            columns={columns}
            dataSource={data?.getPartyDataV2?.customFields?.nodes ?? []}
            pagination={false}
            editModeFieldId={editModeFieldId}
            rowKey={(record: CustomField) => record._id}
            rowClassName={(record: CustomField) =>
              editModeFieldId === record._id && 'active'
            }
            selectedRowKeys={[editModeFieldId]}
          />
        </StyledCard>
      </Form>
    </div>
  );
};

const StyledCard = styled(Card)`
  .ant-card-head-title {
    font-size: 16px;
    font-weight: 400;
  }
`;

const StyledTable = styled(Table)`
  thead > tr > th {
    font-weight: 600 !important;
  }

  tbody > tr {
    height: 69.84px;
    background-color: ${(props) => props.theme.colors.white};

    &.active {
      background-color: ${(props) => props.theme.colors.lightBlue};
    }
  }
`;

const StyledActionsWrapper = styled.div`
  text-align: right;
`;

const StyledCountWrapper = styled.span`
  font-size: 12px;
  padding: 1px 10px;
  border-radius: 15px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  display: inline-block;
  font-weight: 600;
`;

const StyledSecondaryText = styled(Typography.Text)`
  font-size: 12px;
`;

const StyledInputWrapper = styled.div`
  max-width: 500px;

  .ant-input,
  .ant-input-number {
    margin: 0;
    width: 250px;
  }

  .ant-form-item {
    margin: 0;
  }

  .ant-form-item-control {
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
`;

export default PartyCustomFieldsTable;
