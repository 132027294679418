import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type { AttachmentInput } from '@graphql/types/graphql';
import { useCallback } from 'react';

const SEND_CONVERSATION_MESSAGE = graphql(`
  mutation SendConversationMessage($data: SendConversationMessageInput!) {
    sendConversationMessage(data: $data) {
      _id
    }
  }
`);

export const useSendMessageMutation = (conversationId: string) => {
  const [sendMessage, { loading }] = useMutation(SEND_CONVERSATION_MESSAGE);

  const sendConversationMessage = useCallback(
    ({
      variables,
      onCompleted,
      onError,
    }: {
      variables: {
        text: string;
        htmlString: string;
        attachments: AttachmentInput[];
      };
      onCompleted?: () => void;
      onError?: () => void;
    }) => {
      const { text, htmlString, attachments } = variables;

      sendMessage({
        variables: {
          data: {
            outputHtml: htmlString,
            conversationId,
            message: text,
            attachments,
          },
        },
        onCompleted,
        onError,
      });
    },
    [sendMessage, conversationId],
  );

  return {
    sendConversationMessage,
    loading,
  };
};
