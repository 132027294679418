import { Modal, message } from 'antd';
import { useDeleteContextRecordAttachmentMutation } from '../../hooks/useDeleteContextRecordAttachmentMutation';

interface DeleteAttachmentModalProps {
  open: boolean;
  attachment: {
    s3Key: string;
    friendlyName: string;
  } | null;
  recordId: string;
  onCompleted?: () => void;
  onCancel?: () => void;
}

export function DeleteAttachmentModal({
  open,
  attachment,
  recordId,
  onCompleted,
  onCancel,
}: DeleteAttachmentModalProps) {
  const { deleteContextRecordAttachment, deletingContextRecordAttachment } =
    useDeleteContextRecordAttachmentMutation(recordId);

  const handleOnOk = async () => {
    if (!attachment) return;
    try {
      await deleteContextRecordAttachment(attachment.s3Key);
      message.success('Attachment deleted successfully.');
    } catch (error) {
      message.error('Failed to delete attachment');
    } finally {
      onCompleted?.();
    }
  };

  return (
    <Modal
      open={open}
      confirmLoading={deletingContextRecordAttachment}
      title={`Are you sure you want to delete ${attachment?.friendlyName}?`}
      okText="Delete attachment"
      okType="danger"
      onOk={handleOnOk}
      onCancel={onCancel}
    />
  );
}
