import { graphql } from '@graphql/types';

export const REQUEST_RECORD_COMPLIANCE_QUERY = graphql(`
  query RequestRecordCompliance($requestRecordInput: RequestRecordInput!) {
    requestRecord(input: $requestRecordInput) {
      _id
      status
      contextRecord {
        _id
        name
      }
      complianceModules {
        code
        label
        subjects {
          code
          label
          status
          effectiveDate
          latestExpirationDate
          requirements {
            attributeCode
            attributeType
            attributeLabel
            attributeDescription
            documentAttributeCode
            matchingCriteria
            operator
            targetValue
            value
            status
            invalidatedBy
            notes
            publicNotes
            resetOn
          }
          notes
          resetOn
          expiring
          expired
        }
      }
      complianceProfile {
        _id
        name
      }
      fillableForms(input: { filler: "requester" }) {
        _id
        status
        name
        additionalInfo
      }
      complianceStats {
        compliantSubjects
        totalSubjects
      }
    }
  }
`);
