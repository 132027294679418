import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { forwardRef } from 'react';
import styled from 'styled-components';

type ResizeHandlerProps = {
  onToggle: () => void;
  toggleDirection: 'left' | 'right';
  toggleButtonTopOffset?: number;
};

export const ResizeHandler = forwardRef<HTMLDivElement, ResizeHandlerProps>(
  ({ onToggle, toggleDirection, toggleButtonTopOffset = 50 }, ref) => {
    return (
      <StyledResizeHandler ref={ref}>
        <StyledCollapseButton
          type="text"
          className="collapse-button"
          onClick={onToggle}
          style={{ top: toggleButtonTopOffset }}
        >
          {toggleDirection === 'right' ? (
            <RightOutlined className="rightIcon" />
          ) : (
            <LeftOutlined className="leftIcon" />
          )}
        </StyledCollapseButton>
      </StyledResizeHandler>
    );
  },
);

const StyledCollapseButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0;
  transform: translateX(-50%);
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  width: 22px;
  height: 22px;
  font-size: 13px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.white} !important;
    box-shadow: ${({ theme }) => theme.shadows.altOuterShadowHover};
  }

  .leftIcon {
    padding-right: 1px;
  }

  .rightIcon {
    padding-left: 1px;
  }
`;

const StyledResizeHandler = styled.div`
  --handler-width: 6px;
  --track-width: 2px;

  grid-column: 2;
  width: var(--handler-width);
  margin-left: calc(var(--handler-width) * -1);
  z-index: 10;
  position: relative;
  overflow: visible;
  cursor: col-resize;

  .collapse-button {
    transform-origin: center;
    position: absolute;
    left: -6px;
    transform: scale(0);
    transition: transform 275ms cubic-bezier( 0.175, 0.885, 0.32, 1.275 );
  }

  &.active {
    .collapse-button {
      display: none;
    }

    &:not(.blocked)::after {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }

  &.blocked::after {
    background-color: ${({ theme }) => theme.colors.darkGray};
  }

  &.disabled {
    cursor: pointer;
  }

  &::after {
    z-index: -1;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: var(--track-width);
    height: 100%;
  }

  &:hover {
    .collapse-button {
      transform: scale(1);
    }

    &::after {
      background-color: ${({ theme }) => theme.colors.lightGray};
      animation: gutter-hover 0.5s forwards;
    }
  }
`;
