import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type { CustomTemplateInput } from '@graphql/types/graphql';

const RENDER_EMAIL_TEMPLATE_MUTATION = graphql(`
  query SendPrimaryRecordRequestDrawer_RenderEmailTemplate(
    $partyId: ObjectId!
    $template: CustomTemplateInput!
  ) {
    renderEmailTemplate(partyId: $partyId, template: $template) {
      body
      subject
    }
  }
`);

export const useRenderEmailTemplateQuery = ({
  primaryRecordId,
  template,
  skip = false,
}: {
  primaryRecordId: string;
  template: CustomTemplateInput;
  skip?: boolean;
}) => {
  const { data, loading } = useQuery(RENDER_EMAIL_TEMPLATE_MUTATION, {
    variables: { partyId: primaryRecordId, template },
    skip,
  });

  return {
    renderedEmailTemplate: data?.renderEmailTemplate,
    isRenderingEmailTemplate: loading,
  };
};
