import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const EMAIL_TEMPLATE_QUERY = graphql(`
  query SendPrimaryRecordRequestDrawer_EmailTemplate($id: ID!) {
    getEmailTemplate(_id: $id) {
      _id
      subject
      regions
      type
      layout {
        _id
        mjml
      }
    }
  }
`);

export const useEmailTemplateQuery = ({
  templateId,
}: {
  templateId?: string;
}) => {
  const { loading, data } = useQuery(EMAIL_TEMPLATE_QUERY, {
    includeTypename: true,
    skip: !templateId,
    variables: {
      id: templateId!,
    },
  });

  return {
    emailTemplate: data?.getEmailTemplate,
    isEmailTemplateLoading: loading,
  };
};
