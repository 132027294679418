import { FILTER_TYPES } from '@common/constants/filters';

export const COLUMN_FIELDS = {
  name: 'name',
  status: 'status',
  related: 'related',
  description: 'description',
  compliance: 'compliance',
  actions: 'actions',
} as const;

export const REQUESTS_QUERY_FILTER_NAMES = {
  primaryRecordId: 'primaryRecordId',
  contextRecordId: 'contextRecordId',
  name: 'name',
  id: 'id',
} as const;

export const DYNAMIC_FIELDS_BY_RECORD_TYPE = {
  context: {
    headerName: 'Party',
    dataField: 'primaryRecord',
    // is done like this on purpose ⬇️
    idField: 'contextRecordId',
  },
  primary: {
    headerName: 'Project',
    dataField: 'contextRecord',
    // is done like this on purpose ⬇️
    idField: 'primaryRecordId',
  },
} as const;

export const FILTERABLE_COLUMN_FIELDS = {
  name: 'name',
  status: 'status',
} as const;

export const SERVER_FILTERS_CONFIG = {
  [COLUMN_FIELDS.name]: {
    name: 'name',
    filterType: FILTER_TYPES.string,
  },
  [COLUMN_FIELDS.status]: {
    name: 'status',
    filterType: FILTER_TYPES.options,
  },
} as const;
