import * as R from 'ramda';

export const namespace = 'CUSTOM_FIELD' as const;
export const STATE_KEY = 'custom-field' as const;

export const CustomFieldType = {
  Text: 'text',
  Number: 'number',
  Dropdown: 'dropdown',
} as const;

export type CustomFieldTypes =
  (typeof CustomFieldType)[keyof typeof CustomFieldType];

export const customFieldUrlRegexp = new RegExp(
  `customField:[0-9a-fA-F]{24}:(${R.values(CustomFieldType).join('|')})`,
);

export const CustomFieldFormTab = {
  General: 'general',
  AssociatedRequirements: 'associatedRequirements',
} as const;

export const FormNameTitle = {
  name: 'Custom field name',
  type: 'Type',
} as const;

export const CUSTOM_FIELD_TITLE_BY_TYPE_MAP = {
  [CustomFieldType.Dropdown]: 'Dropdown',
  [CustomFieldType.Text]: 'Text',
  [CustomFieldType.Number]: 'Number',
};

export const CustomFieldsEntityTabKeys = {
  project: 'projectCustomFields',
  party: 'partyCustomFields',
};

export enum CustomFieldsEntities {
  project = 'project',
  party = 'party',
}

export const getProjectCustomFieldsTableConfiguration = ({
  isComplianceRequirementsAddOnEnabled,
}: {
  isComplianceRequirementsAddOnEnabled: boolean;
}) =>
  [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
    },
    {
      title: 'Requirements',
      dataIndex: 'requirements',
      key: 'requirements',
      isVisible: isComplianceRequirementsAddOnEnabled,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      ellipsis: true,
    },
  ].filter(({ isVisible }) => isVisible !== false);

export const PARTY_CUSTOM_FIELDS_TABLE_CONFIG = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Color',
    dataIndex: 'color',
    key: 'color',
  },
  {
    dataIndex: 'actions',
    key: 'actions',
    align: 'center',
    ellipsis: true,
  },
];

export type CustomField = {
  _id: string;
  name: string;
  color?: string;
  value: string | number | null;
  description?: string;
  type: (typeof CustomFieldType)[keyof typeof CustomFieldType];
  options?: {
    _id: string;
    key: string;
    value: string;
  }[];
};
