import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { useCallback } from 'react';

const MARK_CONVERSATION_MESSAGES_AS_READ_MUTATION = graphql(`
  mutation MarkConversationMessagesAsRead($data: MarkConversationMessagesAsReadInput!) {
    markConversationMessagesAsRead(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useMarkConversationMessagesAsReadMutation = () => {
  const [markConversationMessagesAsReadMutation, res] = useMutation(
    MARK_CONVERSATION_MESSAGES_AS_READ_MUTATION,
  );

  const markConversationMessagesAsRead = useCallback(
    (filter: ServerFilterInput) =>
      markConversationMessagesAsReadMutation({
        variables: {
          data: {
            filter,
          },
        },
      }),
    [markConversationMessagesAsReadMutation],
  );

  return {
    markConversationMessagesAsRead,
    ...res,
  };
};
