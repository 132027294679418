import { useIsBaseAddOnEnabled } from './useIsBaseAddOnEnabled';

export const useIsContextsAddOnEnabled = () => {
  const { isBaseAddOnEnabled } = useIsBaseAddOnEnabled();

  //? We will have a add-on for contexts/projects in the future, for now we will just return the opposite of the base add-on
  const isContextsAddOnEnabled = !isBaseAddOnEnabled;

  return { isContextsAddOnEnabled };
};
