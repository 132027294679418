//@ts-ignore - Legacy code
export const partyHasDocuments = (party) =>
  Number(party?.totalApplicableDocumentsCount || party?.totalDocumentsCount) >
  0;

export const saveAs = (file: Blob, fileName: string): void => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(file);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(link.href);
};

export const downloadFile = async (
  url: string,
  fileName: string,
): Promise<void> => {
  const response = await fetch(url);
  const blob = await response.blob();
  const file = new File([blob], fileName, { type: blob.type });
  saveAs(file, fileName);
};
