import { TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Form, Input, Modal, Radio } from 'antd';
import qs from 'query-string';
import * as R from 'ramda';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { parseAutomationsFilterToReport } from '@modules/party/components/AutomationsFilter';
import { parseTagsFilter } from '@modules/party/components/TagsFilter';

import { parseComplianceProfileFilter } from '@modules/party/PartyComplianceProfileFilter';
import { createReport, updateReport } from '../actions';

const SaveReportModal = ({ report, reportsType, isVisible, onClose }) => {
  const [form] = Form.useForm();
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const searchParams = qs.parse(search);

  const dispatch = useDispatch();

  const getReportFilters = (keysToOmit) => {
    return {
      ...R.omit(keysToOmit, searchParams),
      ...parseTagsFilter(searchParams),
      ...parseAutomationsFilterToReport(searchParams),
      ...parseComplianceProfileFilter(searchParams),
    };
  };

  useEffect(() => {
    if (isVisible) {
      const values = {
        name: report?.name || '',
        isPrivate: Boolean(report?.isPrivate),
      };
      form.setFieldsValue(values);
    }
  }, [form, isVisible, report]);

  const onCreateReport = async () => {
    if (form) {
      try {
        const values = await form.validateFields();

        const res = await dispatch(
          createReport({
            type: reportsType,
            filters: getReportFilters(['size', 'page']),
            ...values,
          }),
        );

        const report = R.path(['payload', 'data', 'createReport'], res);

        navigate(
          {
            pathname,
            search: qs.stringify({
              ...searchParams,
              report: report._id,
            }),
          },
          { replace: true },
        );

        onClose();
      } catch (error) {
        console.error('[SaveReportModal] onCreateReport : ', error);
      }
    }
  };

  const onEditReport = async () => {
    if (form) {
      try {
        const values = await form.validateFields();

        await dispatch(
          updateReport({
            _id: R.prop('_id', report),
            filters: getReportFilters(['size', 'page', 'report']),
            ...values,
          }),
        );
        onClose();
      } catch (error) {
        console.error('[SaveReportModal] onEditReport : ', error);
      }
    }
  };

  return (
    <Modal
      title={report ? 'Edit report' : 'Create report'}
      open={isVisible}
      okText={report ? 'Update' : 'Create'}
      onOk={() => (report ? onEditReport() : onCreateReport())}
      onCancel={onClose}
    >
      <Form form={form} name="save_report" layout="vertical">
        <Form.Item
          label="Report name"
          name="name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'This field is required',
            },
          ]}
        >
          <Input
            data-cy="enterReportNameInput"
            placeholder="Enter report name..."
          />
        </Form.Item>
        <Form.Item label="Visibility" name="isPrivate">
          <Radio.Group buttonStyle="solid">
            <Radio.Button value={false}>
              <TeamOutlined style={{ marginRight: 8 }} />
              All team members
            </Radio.Button>
            <Radio.Button value={true}>
              <UserOutlined style={{ marginRight: 8 }} />
              Only you
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SaveReportModal;
