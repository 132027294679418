import type { SortDirection } from '@common/components/Table';
import type { CustomFieldTypes } from '@modules/custom-field/constants';
import type { ProjectComplianceStatus } from '@modules/project/constants';

export enum LEGACY_PARTIES_STATUS {
  Active = 'active',
  Inactive = 'inactive',
}

/*
Are definied only the legacy report params currently supported in the v2
 */
export type LegacyPartiesReportParams = {
  name?: string;
  tags?: { operator: 'in' | 'notIn'; value: string[] };
  status?: LEGACY_PARTIES_STATUS;
  documentsCount?: string; // 'lessThan-{number} | moreThan-{number} | exactly-{number}' - new activeDocuments
  reviewStatus?: 'reviewed' | 'toReview' | ('reviewed' | 'toReview')[];
  flag?: 'high' | 'medium' | 'low' | ('high' | 'medium' | 'low')[]; // new flagsSeverity
  type?: string | string[]; // new types
  assignedToProjects?: string | string[]; // new contextRecords
  waivers?: 'withWaived' | 'withoutWaived' | ('withWaived' | 'withoutWaived')[]; // new waiversStatus
  overrides?:
    | 'withOverride'
    | 'withoutOverride'
    | ('withOverride' | 'withoutOverride')[]; // new overridesStatus

  nextExpirationDate?: string;
  nextExpirationDateFilterType?: 'next' | 'last' | 'between' | 'on'; // new expiration
  nextExpirationDateWithinDateCount?: string;
  nextExpirationDateWithinDateType?: 'week' | 'day' | 'month';
  nextExpirationDateFrom?: string;
  nextExpirationDateTo?: string;
  nextExpirationDatePickerValue?: string;

  lastActivityDate?: string;
  lastActivityDateFilterType?: 'next' | 'last' | 'between' | 'on' | 'older'; // new lastActivity
  lastActivityDateWithinDateCount?: string;
  lastActivityDateWithinDateType?: 'week' | 'day' | 'month';
  lastActivityDateFrom?: string;
  lastActivityDateTo?: string;
  lastActivityDatePickerValue?: string;

  lastRequestSent?: string;
  lastRequestSentFilterType?: 'next' | 'last' | 'between' | 'on' | 'older'; // new lastRequest
  lastRequestSentWithinDateCount?: string;
  lastRequestSentWithinDateType?: 'week' | 'day' | 'month';
  lastRequestSentFrom?: string;
  lastRequestSentTo?: string;
  lastRequestSentPickerValue?: string;

  failedMessages?: string;
  failedMessagesFilterType?: 'last' | 'between' | 'on'; // new messageFailures
  failedMessagesWithinDateCount?: string;
  failedMessagesWithinDateType?: 'week' | 'day' | 'month';
  failedMessagesFrom?: string;
  failedMessagesTo?: string;
  failedMessagesPickerValue?: string;

  contactEmail?:
    | 'withContactEmail'
    | 'withoutContactEmail'
    | ('withContactEmail' | 'withoutContactEmail')[]; // new contactEmailStatus

  sortField?: string;
  sortDirection?: SortDirection;
};

export type LegacyDocumentsReportParams = {
  flag?: string[] | string;
  reviewStatus?: string[] | string;
  requirementId?: string;
  name?: string;
  status?: string | string[];
  verification?: string | string[];
  type?: string[] | string;
  subject?: string[] | string;
  associatedProjects?: string[] | string;
  expirationDateFilterType?: string;
  expirationDateWithinDateCount?: string;
  expirationDateWithinDateType?: string;
  uploadDateFilterType?: string;
  uploadDateWithinDateCount?: string;
  uploadDateWithinDateType?: string;
  uploadDateFrom?: string;
  uploadDateTo?: string;
  expirationDateFrom?: string;
  expirationDateTo?: string;
  expirationDatePickerValue?: string;
  uploadDatePickerValue?: string;
  columnId?: string;
  direction?: SortDirection;
  appliesToAllProjects?: string[] | string;
  sortField?: string;
  sortDirection?: SortDirection;
};

export type CustomFieldKey = `customField:${string}:${CustomFieldTypes}`;

type ProjectComplianceStatuses =
  (typeof ProjectComplianceStatus)[keyof typeof ProjectComplianceStatus];
export type LegacyProjectReportsParams = {
  [key in CustomFieldKey]?: string;
} & {
  name?: string;
  status?: string | string[];
  complianceStatus?: ProjectComplianceStatuses | ProjectComplianceStatuses[];
  endDate?: string;
  endDateFilterType?: string;
  endDateWithinDateCount?: string;
  endDateWithinDateType?: string;
  startDate?: string;
  startDateFilterType?: string;
  startDateWithinDateCount?: string;
  startDateWithinDateType?: string;
  startDateTo?: string;
  startDateFrom?: string;
  endDateTo?: string;
  endDateFrom?: string;
  startDatePickerValue?: string;
  endDatePickerValue?: string;
  sortField?: string;
  sortDirection?: SortDirection;
};
