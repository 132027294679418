import {
  ExclamationCircleOutlined,
  FileSearchOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Form, type MenuProps, Modal, message } from 'antd';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { CustomDropdown } from '@common/components/CustomDropdown';
import type {
  ReportEntity as ViewEntity,
  ReportType as ViewType,
  ViewsSelectorQuery,
} from '@graphql/types/graphql';
import useViews from './useViews';

import { ViewForm } from '@modules/view/components/ViewForm';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { getViewById, groupViewsByPrivacy } from './ViewsSelector.utils';

type HandlerParams = {
  viewId?: string;
  viewParams: Record<string, string>;
};

type ViewsSelectorProps = {
  type: ViewType;
  viewId?: string;
  defaultView: ViewEntity;
  // the name "filters" is not correct since it includes also sorting, the name "viewParams" is more accurate
  viewParams: ViewEntity['filters'];
  onCreated?: (params: HandlerParams) => void;
  onEdited?: (viewId: string) => void;
  onDeleted?: () => void;
  onReset?: () => void;
  onSelectChanged?: (params: HandlerParams) => void;
};

const getViewParamsById = (viewId: string, views: ViewEntity[]) => {
  return getViewById(viewId, views)?.filters;
};

export function ViewsSelector({
  type,
  viewId,
  defaultView,
  viewParams,
  onCreated,
  onDeleted,
  onEdited,
  onReset,
  onSelectChanged,
}: ViewsSelectorProps) {
  const [isVisibleSaveViewModal, setIsVisibleSaveViewModal] = useState(false);
  const [isVisibleEditViewModal, setIsVisibleEditViewModal] = useState(false);
  const [isVisibleDeleteViewModal, setIsVisibleDeleteViewModal] =
    useState(false);
  const [activeViewId, setActiveViewId] = useState(defaultView._id);

  const [editViewForm] = Form.useForm();
  const [createViewForm] = Form.useForm();

  const handleOnViewCompleted = (views: ViewsSelectorQuery['listReports']) => {
    if (viewId) {
      onSelectChanged?.({
        viewId,
        viewParams: getViewById(viewId, views)?.filters,
      });
      setActiveViewId(viewId);
    }
  };

  const {
    data: viewsRes,
    loading,
    createViewLoading,
    updateViewLoading,
    deleteViewLoading,
    createView,
    updateView,
    deleteView,
    refetchViews,
  } = useViews({
    viewsType: type,
    onFetchCompleted: handleOnViewCompleted,
  });

  const views = useMemo(
    () => (!!viewsRes ? [defaultView, ...viewsRes] : [defaultView]),
    [defaultView, viewsRes],
  );

  const isDefaultViewActive = activeViewId === defaultView._id;
  /**
   * check the difference between active view params, it could be also the default one,
   * and the view params arrived from the parent component.
   *
   * if there is a difference, the save and reset buttons should be visible.
   */

  const baseParams = isEmpty(viewParams) ? defaultView.filters : viewParams;
  const isExternalViewDifferentFromActiveView = !isEqual(
    baseParams,
    getViewParamsById(activeViewId, views as ViewEntity[]),
  );

  const groupedViewsByPrivacyType = useMemo<MenuProps['items']>(() => {
    const [publicViews, privateViews] = groupViewsByPrivacy(views);
    return [
      {
        type: 'group',
        label: 'Private Views',
        hidden: privateViews.length === 0,
        children: privateViews.map((view) => ({
          label: view.name,
          key: view._id,
          icon: <FileSearchOutlined />,
        })),
      },
      {
        type: 'group',
        label: 'Workspace Views',
        children: publicViews.map((view) => ({
          label: view.name,
          key: view._id,
          icon: <FileSearchOutlined />,
        })),
      },
    ];
  }, [views]);

  async function handleCreateView() {
    try {
      const data = createViewForm.getFieldsValue();
      const view = await createView({
        variables: {
          payload: {
            name: data.name,
            type: type,
            filters: viewParams,
            isPrivate: data.isPrivate,
          },
        },
      });

      const createdView = view?.data?.createReport;

      if (!createdView) {
        throw new Error();
      }

      setActiveViewId(createdView._id);

      onCreated?.({
        viewId: createdView._id,
        viewParams: createdView.filters,
      });

      createViewForm.resetFields();

      message.success({
        content: 'View created successfully',
      });
    } catch {
      message.error({
        content: 'An error occurred while creating the view',
      });
    } finally {
      setIsVisibleSaveViewModal(false);
    }
  }

  async function handleEditView() {
    if (!activeViewId) return;

    const data = editViewForm.getFieldsValue();

    try {
      const view = await updateView({
        variables: {
          payload: {
            _id: activeViewId,
            name: data.name,
            filters: viewParams,
            isPrivate: data.isPrivate,
          },
        },
      });
      const editedView = view?.data?.updateReport;

      if (!editedView) {
        throw new Error();
      }

      editViewForm.resetFields();

      onEdited?.(editedView._id);

      message.success({
        content: 'View updated successfully',
      });
    } catch {
      message.error({
        content: 'An error occurred while updating the view',
      });
    } finally {
      setIsVisibleEditViewModal(false);
    }
  }

  async function handleDeleteView() {
    if (!activeViewId) return;

    try {
      await deleteView({
        variables: {
          id: activeViewId,
        },
      });

      message.success({
        content: 'View deleted successfully',
      });

      setActiveViewId(defaultView._id);
      setIsVisibleDeleteViewModal(false);

      onDeleted?.();
    } catch {
      message.error({
        content: 'An error occurred while deleting the view',
      });
    }
  }

  const handleOnChangeView = (viewId: string) => {
    setActiveViewId(viewId);

    onSelectChanged?.({
      viewId,
      viewParams: getViewById(viewId, views)?.filters,
    });
  };

  const handleOnRestClick = () => {
    setActiveViewId(defaultView._id);

    onReset?.();
  };

  return (
    <StyledViewSelector>
      <Modal
        title={'Create View'}
        open={isVisibleSaveViewModal}
        okText={'Create'}
        onOk={handleCreateView}
        onCancel={() => {
          setIsVisibleSaveViewModal(false);
          createViewForm.resetFields();
        }}
        destroyOnClose
        confirmLoading={createViewLoading}
      >
        <ViewForm form={createViewForm} />
      </Modal>

      <Modal
        title={'Edit View'}
        open={isVisibleEditViewModal}
        okText={'Edit'}
        onOk={handleEditView}
        onCancel={() => {
          setIsVisibleEditViewModal(false);
          editViewForm.resetFields();
        }}
        destroyOnClose
        confirmLoading={updateViewLoading}
      >
        <ViewForm
          form={editViewForm}
          initialValues={getViewById(activeViewId, views)}
        />
      </Modal>
      <Modal
        open={isVisibleDeleteViewModal}
        okType="danger"
        title={
          <>
            <StyledDangerIcon /> Are you sure you want to delete this view?
          </>
        }
        okText={'Yes, delete'}
        cancelText={'Cancel'}
        confirmLoading={deleteViewLoading}
        onOk={handleDeleteView}
        onCancel={() => setIsVisibleDeleteViewModal(false)}
        closable={false}
      />
      <CustomDropdown
        value={activeViewId}
        loading={loading}
        mainMenu={groupedViewsByPrivacyType}
        onChange={handleOnChangeView}
        onOpenChange={(isOpen) => {
          if (isOpen) {
            refetchViews();
          }
        }}
        actionsMenu={[
          {
            key: '1',
            type: 'group',
            label: 'Current View',
            children: [
              {
                label: 'Edit',
                disabled: isDefaultViewActive,
                onClick: () => setIsVisibleEditViewModal(true),
                key: '1',
              },
              // {
              //   label: 'Export',
              //   key: '2',
              // },
              // {
              //   label: 'Scheduled Views',
              //   key: '2',
              // },
              {
                label: 'Delete',
                disabled: isDefaultViewActive,
                onClick: () => setIsVisibleDeleteViewModal(true),
                key: '3',
              },
            ],
          },
        ]}
        mainMenuButton={
          <Button
            type="dashed"
            onClick={() => setIsVisibleSaveViewModal(true)}
            style={{ width: '100%' }}
            icon={<PlusOutlined />}
          >
            New View
          </Button>
        }
      />

      {isExternalViewDifferentFromActiveView && (
        <StyledActions>
          <Button
            className="actionButton"
            type="link"
            onClick={() => {
              setIsVisibleSaveViewModal(true);
            }}
          >
            Save
          </Button>
          <Button
            className="actionButton"
            type="link"
            onClick={handleOnRestClick}
          >
            Reset
          </Button>
        </StyledActions>
      )}
    </StyledViewSelector>
  );
}

const StyledViewSelector = styled.section`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledActions = styled.section`
  display: flex;
  align-items: center;

  .actionButton {
    padding: 0;
    padding-right: 8px;
  }
`;

const StyledDangerIcon = styled(ExclamationCircleOutlined)`
  margin-right: 8px;
  font-size: 20px;
  color: #faad14;
`;
