import { LoadingOutlined } from '@ant-design/icons';
import { Select } from 'antd';

import InfiniteScrollSelect from '@common/components/InfiniteScrollSelect';
import {
  type CustomFilterProps,
  CustomFilterWrapper,
  type IdFilter,
} from '@common/components/Table';

import { useContextRecords } from './hooks';

const normalizeFilterValue = (value: string | string[] | undefined) => {
  if (!value || (Array.isArray(value) && value.length === 0)) {
    return null;
  }

  return {
    filter: Array.isArray(value) ? value : [value],
  };
};

export const ContextRecordsFilter = ({
  model,
  onModelChange,
}: CustomFilterProps<any, any, IdFilter>) => {
  const {
    contextRecords,
    contextRecordsTotalCount,
    loading,
    fetchMoreContextRecords,
    filterActiveContextRecordsByName,
  } = useContextRecords();

  return (
    <CustomFilterWrapper>
      <InfiniteScrollSelect
        value={model?.filter}
        isDataLoading={loading}
        allowClear
        showSearch
        onSearch={filterActiveContextRecordsByName}
        loadMore={fetchMoreContextRecords}
        hasMore={contextRecordsTotalCount > contextRecords.length}
        loader={<LoadingOutlined />}
        onChange={(value) => {
          onModelChange(normalizeFilterValue(value));
        }}
        placeholder="Search context record..."
      >
        {contextRecords?.map((contextRecord) => (
          <Select.Option key={contextRecord._id} value={contextRecord._id}>
            {contextRecord.name}
          </Select.Option>
        ))}
      </InfiniteScrollSelect>
    </CustomFilterWrapper>
  );
};
