import styled from 'styled-components';

import ExpirationBadgePopover from '@common/components/Badges/components/ExpirationBadgePopover';
import type { Document } from '@graphql/types/graphql';

import { humanizeDate } from '@trustlayer/common';
import { getDocumentFirstExpirationDate } from '../../../../utils/getDocumentFirstExpirationDate';

type DocumentExpirationDateProps = {
  documentMetadata: Document['metadata'];
  // @ts-expect-error - requirements is not inferred from Maybe<PartyData>
  recordRequirements: Document['party']['requirements'];
};

export const DocumentExpirationDate = ({
  documentMetadata,
  recordRequirements,
}: DocumentExpirationDateProps) => {
  const expirationDate = getDocumentFirstExpirationDate({
    metadata: documentMetadata,
    recordRequirements: recordRequirements,
  });

  return (
    <StyledExpirationDate>
      {/* @ts-expect-error - ExpirationBadgePopover is not typed so it requires optional props to be added */}
      <ExpirationBadgePopover
        expirationDate={expirationDate}
        sizeIcon="Medium"
      />
      <span>{expirationDate ? humanizeDate(expirationDate) : '—'}</span>
    </StyledExpirationDate>
  );
};

const StyledExpirationDate = styled.section`
  height: 100%;
  display: flex;
  align-items: center;
`;
