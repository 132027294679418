import type { QueryHookOptions } from '@apollo/client';

import { useQuery } from '@graphql/hooks';

import {
  PARTY_AUTOMATIONS_QUERY,
  type PartyAutomationsData,
  type PartyAutomationsVariables,
} from '../queries';
import { type PartyAutomation, getPartyAutomationsMap } from '../utils';

type UsePartyAutomationsProps = {
  partyId: string;
  automationCode?: string;
  queryOptions?: QueryHookOptions<
    PartyAutomationsData,
    PartyAutomationsVariables
  >;
  onCompleted: (
    partyAutomations: Record<string, PartyAutomation> | undefined,
  ) => void;
};

/**
 * @todo add filter by automation code to query only an automation of party - once BE adds support for it
 */
export const usePartyAutomations = ({
  partyId,
  queryOptions,
  onCompleted,
}: UsePartyAutomationsProps) => {
  const {
    loading,
    data: partyAutomationData,
    refetch,
  } = useQuery<PartyAutomationsData, PartyAutomationsVariables>(
    PARTY_AUTOMATIONS_QUERY,
    {
      variables: { partyId },
      ...queryOptions,
      onCompleted: (res) => {
        const partyAutomationsMap = getPartyAutomationsMap({
          partyAutomations: res?.getPartyDataV2?.automations,
        });

        onCompleted?.(partyAutomationsMap);
      },
    },
  ) || {};

  const partyAutomationsMap = getPartyAutomationsMap({
    partyAutomations: partyAutomationData?.getPartyDataV2?.automations,
  });

  return {
    isLoading: loading,
    data: partyAutomationsMap,
    refetch,
  };
};
