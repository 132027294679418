import type { RenderEmailTemplate } from '@graphql/types/graphql';
import { Input, Modal } from 'antd';
import DOMPurify from 'dompurify';
import { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';

export const RenderPreviewEmailTemplate = ({
  preview,
}: {
  preview: Pick<RenderEmailTemplate, 'subject' | 'body'>;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const sanitizedBody = useMemo(() => {
    if (!preview?.body) return '';

    /**
     * @note "sanitize" excludes by default the "head" tag and so part of the template's style generated by MJML is removed.
     * To avoid this behavior the "saniteize" method needs the following options: { WHOLE_DOCUMENT: true, ADD_TAGS: ['head'] }
     *
     * see also an ISSUE in DOMPurify GitHub @link https://github.com/cure53/DOMPurify/issues/501
     */
    return DOMPurify.sanitize(preview?.body, {
      WHOLE_DOCUMENT: true,
      ADD_TAGS: ['head'],
    });
  }, [preview?.body]);

  useEffect(() => {
    const handleClick = (ev: MouseEvent) => {
      ev.preventDefault();
      const target = ev.target as HTMLAnchorElement;
      if (!target) return;

      const url = target?.getAttribute('href');
      if (!url) return;

      const isPlaceholderLink = /http.*\/email-preview-placeholder/.test(url);

      if (!isPlaceholderLink) {
        window.open(url, '_blank');
      } else {
        Modal.info({
          title: 'Link not available in preview mode',
          content:
            'This link will be generated on the fly when the email has been sent.',
        });
      }
    };

    if (ref.current) {
      ref.current.addEventListener('click', handleClick);
    }
  }, []);

  return (
    <StyledWrapper>
      <label>Subject</label>
      <Input data-cy="previewSubject" readOnly value={preview?.subject || ''} />
      <div
        ref={ref}
        dangerouslySetInnerHTML={{
          __html: sanitizedBody || '',
        }}
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  label {
    display: block;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.darkGrayText};
    padding: 0 0 12px;
  }

  > div {
    margin-top: 16px;
  }
`;
