import { useCallback } from 'react';

import type { GetRowData } from '@common/components/Table';
import { FILTER_TYPES } from '@common/constants/filters';
import {
  type ServerFiltersConfig,
  mapTableFiltersToServerFilters,
} from '@common/utils/filters';
import { useCustomLazyQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { CONTACTS_COLUMN_FIELDS } from '../constants';

export type Contact = {
  _id?: string;
  email?: string;
  contactPersonName?: string;
  companyName?: string;
  title?: string;
  phone?: string;
  fax?: string;
  avatar?: string;
  defaultRequestRecipient?: boolean;
  primary?: boolean;
};

const SERVER_FILTERS_CONFIG: ServerFiltersConfig = {
  [CONTACTS_COLUMN_FIELDS.name]: {
    name: 'contactPersonName',
    filterType: FILTER_TYPES.string,
  },
};

export const CONTACTS_QUERY = graphql(`
  query PrimaryRecordContacts($primaryRecordInput: PrimaryRecordInput!, $input: PrimaryRecordContactsInput) {
    primaryRecord(input: $primaryRecordInput) {
      _id
      contacts(input: $input) {
        totalCount
        nodes {
          _id
          contactPersonName
          companyName
          avatar
          email
          primary
          title
          phone
          fax
          defaultRequestRecipient
        }
      }
    }
  }
`);

export const useContacts = ({
  primaryRecordId,
}: {
  primaryRecordId: string;
}) => {
  const getContactsQuery = useCustomLazyQuery(CONTACTS_QUERY);

  const getContacts: GetRowData = useCallback(
    async ({ startRow, endRow, filters }) => {
      const activeFilters = mapTableFiltersToServerFilters({
        tableFilters: filters,
        serverFiltersConfig: SERVER_FILTERS_CONFIG,
      });

      const { data } = await getContactsQuery({
        variables: {
          primaryRecordInput: { id: primaryRecordId },
          input: {
            offset: startRow,
            first: endRow - startRow,
            filter: activeFilters,
          },
        },
      });

      return {
        rowData: data?.primaryRecord?.contacts.nodes || [],
        rowCount: data?.primaryRecord?.contacts.totalCount || 0,
      };
    },
    [primaryRecordId, getContactsQuery],
  );

  return {
    getContacts,
  };
};
