import { type SelectProps, Skeleton } from 'antd';

import InfiniteScrollSelect from '@common/components/InfiniteScrollSelect';
import Spinner from '@common/components/Spinner';
import BaseComplianceProfileIcon from '@modules/compliance-profile/components/BaseComplianceProfileIcon';
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import { useComplianceProfiles } from './hooks/useComplianceProfiles';

export type ProfileValue = {
  _id?: string;
  name?: string;
  baseComplianceProfile?: string | null;
};
type ComplianceProfilesSelectProps = SelectProps & {
  // pass defaultValue if you want to make the component uncontrolled (don't pass also value)
  // - defaultValue as string if you want to automatically fetch other data,
  // - defaultValue as ProfileValue if you already have all the informations required to display the value
  defaultValue?: string | ProfileValue;
  // pass value if you want to make the component controlled (don't pass also defaultValue)
  // - value as string if you want to automatically fetch other data,
  // - value as ProfileValue if you already have all the informations required to display the value
  value?: string | ProfileValue;
  className?: string;
  size?: SizeType;
  placeholder?: string;
  onChange?: (profile?: ProfileValue) => void;
};

export const getComplianceProfileSelectLabel = ({
  _id,
  name,
  baseComplianceProfile,
}: {
  _id?: string;
  name?: string | null;
  baseComplianceProfile?: string | null;
}) => {
  return {
    value: _id,
    label: (
      <>
        {Boolean(baseComplianceProfile) && <BaseComplianceProfileIcon />}{' '}
        {name || ''}
      </>
    ),
  };
};

export const ComplianceProfilesSelect = ({
  value,
  defaultValue,
  className,
  size = 'large',
  placeholder = 'Select a Compliance Profile',
  onChange,
  ...props
}: ComplianceProfilesSelectProps) => {
  const {
    selectedComplianceProfile,
    defaultComplianceProfile,
    loadingSelectedComplianceProfile,
    loadingDefaultComplianceProfile,
    complianceProfiles,
    complianceProfilesTotalCount,
    loading,
    loadingMore,
    fetchMoreComplianceProfiles,
    searchedValue,
    setSearchedValue,
  } = useComplianceProfiles({
    defaultValue,
    value,
  });

  if (loadingDefaultComplianceProfile || loadingSelectedComplianceProfile) {
    return <Skeleton.Input block active />;
  }

  return (
    <InfiniteScrollSelect
      data-cy="complianceProfilesSelect"
      size={size}
      style={{ minWidth: 200 }}
      showSearch
      placeholder={placeholder}
      className={className}
      value={
        selectedComplianceProfile?.name
          ? getComplianceProfileSelectLabel(selectedComplianceProfile)
          : undefined
      }
      defaultValue={
        defaultComplianceProfile?._id
          ? getComplianceProfileSelectLabel(defaultComplianceProfile)
          : undefined
      }
      loader={<Spinner />}
      isDataLoading={loading || loadingMore}
      searchValue={searchedValue}
      onSearch={setSearchedValue}
      hasMore={Boolean(
        complianceProfiles?.length &&
          complianceProfiles?.length < complianceProfilesTotalCount,
      )}
      onChange={(id) => {
        if (!id) {
          onChange?.();
        }
        const selectedProfile = complianceProfiles?.find(
          (profile) => profile._id === id,
        );

        if (!selectedProfile) return;

        const { _id, name, baseComplianceProfile } = selectedProfile;

        onChange?.({
          _id,
          name,
          baseComplianceProfile,
        });
      }}
      loadMore={() => {
        fetchMoreComplianceProfiles();
      }}
      options={complianceProfiles?.map(
        (profile: { _id: string; name: string }) => ({
          // @temp using id to make sure id is unique because of an issue in the server which returns the same profile in two pages
          value: profile._id,
          label: profile.name || '__NO NAME__', // this fallback is because we have profiles with empty name on the DB but it shouldn't be happened
        }),
      )}
      {...props}
    />
  );
};
