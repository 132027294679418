import { FILTER_OPERATORS, FILTER_TYPES } from '@common/constants/filters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const DELETE_CONTEXT_RECORD_MUTATION = graphql(`
  mutation DeleteContextRecordModal($data: DeleteContextRecordsInput!) {
    deleteContextRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useDeleteContextRecordMutation = () => {
  const [deleteContextRecordMutation, { loading: isDeletingContextRecord }] =
    useMutation(DELETE_CONTEXT_RECORD_MUTATION);

  const deleteContextRecord = (id: string) => {
    return deleteContextRecordMutation({
      variables: {
        data: {
          filter: {
            and: [
              {
                name: FILTER_TYPES.id,
                operator: FILTER_OPERATORS.in,
                value: [id],
              },
            ],
          },
        },
      },
    });
  };

  return {
    deleteContextRecord,
    isDeletingContextRecord,
  };
};
