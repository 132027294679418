import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ModalConfirm } from '@common/components/ModalConfirm';
import { useRemoveConversationContactsMutation } from '@modules/contacts/hooks/useRemoveConversationContactsMutation';
import { useResendConversationMessageIssueMutation } from '@modules/conversation/hooks/useResendConversartionMessageIssueMutation';
import { useUploadDocumentMutation } from '@modules/document/hooks/useUploadDocumentMutation';
import { downloadDocumentFile } from '@modules/document/utils/document-helpers';
import { ConversationEvent } from '@modules/party-conversation';
import { message } from 'antd';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useIgnoreConversationMessageIssueMutation } from '../../hooks/useIgnoreConversationMessageIssueMutation';
import { useMessages } from './hooks';

const MessageWrapper = ({ inView, children }: any) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (inView) {
      requestAnimationFrame(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      });
    }
  }, [inView]);

  return <div ref={ref}>{children}</div>;
};

export const Messages = ({
  primaryRecordId,
  conversationId,
  selectedMessageId,
}: {
  primaryRecordId: string;
  conversationId: string;
  selectedMessageId?: string;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const scrolledConversationId = useRef<string | undefined>();
  const markAsReadCountdownRef = useRef<NodeJS.Timeout | undefined>();
  const [
    removePrimaryRecordContactModalData,
    setRemovePrimaryRecordContactModalData,
  ] = useState<{
    open: boolean;
    contactId?: string;
    issueId?: string;
  }>({ open: false });

  const [
    resendConversationMessageIssueModalData,
    setResendConversationMessageIssueModalData,
  ] = useState<{
    open: boolean;
    issueId?: string;
  }>({ open: false });

  const { messages, messagesByDateMap, markConversationMessagesAsRead } =
    useMessages({
      primaryRecordId,
      conversationId,
    });

  const { uploadDocument } = useUploadDocumentMutation();
  const { ignoreConversationMessageIssue } =
    useIgnoreConversationMessageIssueMutation();

  const {
    removeConversationContacts,
    loading: removeConversationContactLoading,
  } = useRemoveConversationContactsMutation({
    conversationId,
  });

  const {
    resendConversationMessageIssue,
    loading: resendConversationMessageIssueLoading,
  } = useResendConversationMessageIssueMutation();

  useEffect(() => {
    if (conversationId) {
      clearTimeout(markAsReadCountdownRef.current);
      /* 
      We mark all the messages int he conversation as read once it is being opened even if it is highly questonable because this is the behaviouir of the v1.
      The setTimeout is to avoid marking messages as read if the user leave the conversation messages page within 2 seconds
      */
      markAsReadCountdownRef.current = setTimeout(() => {
        markConversationMessagesAsRead(conversationId);
        markAsReadCountdownRef.current = undefined;
      }, 2000);
    }
  }, [conversationId, markConversationMessagesAsRead]);

  const handleSaveInDocuments = async ({
    url,
    name,
  }: {
    url: string;
    name: string;
  }) => {
    message.loading({
      content: 'Saving document...',
      key: 'uploadDocument',
      duration: 0,
    });

    try {
      await uploadDocument({
        variables: {
          payload: { url, name, party: primaryRecordId },
        },
      });
      message.destroy('uploadDocument');
      message.success({
        content: 'Document was saved in documents library',
        key: 'uploadDocument',
      });
    } catch (err) {
      message.destroy('uploadDocument');
    }
  };

  const handleIgnoreIssue = async (issueId: string) => {
    message.loading({
      content: 'Ignoring issue...',
      key: 'ignoreIssue',
      duration: 0,
    });

    try {
      await ignoreConversationMessageIssue(issueId);
      message.destroy('ignoreIssue');
      message.success({
        content: 'Issue ignored successfully',
        key: 'ignoreIssue',
      });
    } catch (err) {
      message.destroy('ignoreIssue');
    }
  };

  const handleRemovePrimaryRecordContact = async (contactId: string) => {
    try {
      await removeConversationContacts([contactId]);
      setRemovePrimaryRecordContactModalData({ open: false });
      message.success('Contact removed successfully');
    } catch (err) {}
  };

  const handleResendConversationMessageIssue = async (issueId: string) => {
    try {
      await resendConversationMessageIssue({
        issueId,
      });
      setResendConversationMessageIssueModalData({ open: false });
      message.success('Message resent successfully');
    } catch (err) {}
  };

  useEffect(() => {
    // scroll to message if conversation is messages are loaded scrolledConversationId is undefined or changed fromt he previous one
    if (messages.length && scrolledConversationId.current !== conversationId) {
      scrolledConversationId.current = conversationId;
      // scroll to the bottom of the messages list to show the latest message
      requestAnimationFrame(() => {
        ref.current?.scrollTo({ top: ref.current.scrollHeight });
      });
    }
  }, [messages.length, conversationId]);

  return (
    <StyledMessagesListSection ref={ref}>
      <ModalConfirm
        open={removePrimaryRecordContactModalData.open}
        icon={<ExclamationCircleOutlined />}
        okButtonProps={{
          danger: true,
          loading: removeConversationContactLoading,
        }}
        closable={!removeConversationContactLoading}
        cancelButtonProps={{
          disabled: removeConversationContactLoading,
        }}
        okText="Remove"
        title={`Are you sure you want to remove the contact from the conversation?`}
        onCancel={() => setRemovePrimaryRecordContactModalData({ open: false })}
        onOk={() =>
          handleRemovePrimaryRecordContact(
            removePrimaryRecordContactModalData?.contactId!,
          )
        }
      />
      <ModalConfirm
        open={resendConversationMessageIssueModalData.open}
        icon={<ExclamationCircleOutlined />}
        okButtonProps={{
          loading: resendConversationMessageIssueLoading,
        }}
        closable={!resendConversationMessageIssueLoading}
        cancelButtonProps={{
          disabled: resendConversationMessageIssueLoading,
        }}
        okText="Resend"
        title={`Are you sure you want to resend this message?`}
        onCancel={() =>
          setResendConversationMessageIssueModalData({ open: false })
        }
        onOk={() =>
          handleResendConversationMessageIssue(
            resendConversationMessageIssueModalData?.issueId!,
          )
        }
      />

      {Object.keys(messagesByDateMap).map((date) => {
        const messagesList = messagesByDateMap[date];

        return (
          <StyledDateSection key={date}>
            <StyledTitle>{date}</StyledTitle>
            {messagesList.map((message) => {
              const legacyMessagePayload = {
                ...message,
                issues: message.issues.map((issue) => ({
                  ...issue,
                  contactId: issue.contact?._id,
                })),
              };

              return (
                <MessageWrapper
                  key={message._id}
                  inView={message._id === selectedMessageId}
                >
                  <ConversationEvent
                    conversationEvent={legacyMessagePayload}
                    senderData={message.senderData}
                    conversationRequest={message.request}
                    onSaveInDocuments={handleSaveInDocuments}
                    onDownloadAttachment={downloadDocumentFile}
                    onResolveIssue={(_contactId: string, issueId: string) =>
                      setResendConversationMessageIssueModalData({
                        open: true,
                        issueId,
                      })
                    }
                    onIgnoreIssue={handleIgnoreIssue}
                    onRemoveContact={(contactId: string, issueId: string) => {
                      setRemovePrimaryRecordContactModalData({
                        open: true,
                        contactId,
                        issueId,
                      });
                    }}
                  />
                </MessageWrapper>
              );
            })}
          </StyledDateSection>
        );
      })}
    </StyledMessagesListSection>
  );
};

const StyledMessagesListSection = styled.section`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  height: 100%;
  overflow-y: scroll;
  inset: 0;

  & > section:first-child {
    margin-top: auto;
  }
`;

const StyledDateSection = styled.section`
  margin-bottom: 15px;
`;

const StyledTitle = styled.h3`
  position: sticky;
  top: 0;
  z-index: 3;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e6eaef;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  background-color: white;
  font-size: 12px;
`;
