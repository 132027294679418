import { useCallback } from 'react';

import type { GetRowData } from '@common/components/Table';
import { mapTableFiltersToServerFilters } from '@common/utils/filters';
import { useCustomLazyQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { mapTableSortToServerSort } from '../../../../../common/utils/filters/mapTableSortToServerSort';
import { SERVER_FILTERS_CONFIG } from '../constants';

export const CONTEXT_RECORDS_QUERY = graphql(`
  query ContextRecordsList(
    $contextObjectInput: ContextObjectInput!
    $recordsInput: ContextRecordsInput!
  ) {
    contextObject(input: $contextObjectInput) {
      _id
      records(input: $recordsInput) {
        totalCount
        nodes {
          _id
          name
          status
          startDate
          endDate
          stats {
            requestRecords {
              totalCount
              compliantCount
            }
          }
        }
      }
    }
  }
`);

export const useContextRecords = ({
  contextObjectSlug,
}: {
  contextObjectSlug: string;
}) => {
  const getContextRecordsQuery = useCustomLazyQuery(CONTEXT_RECORDS_QUERY);

  const getContextRecords: GetRowData = useCallback(
    async ({ startRow, endRow, filters, sorts }) => {
      const activeFilters = mapTableFiltersToServerFilters({
        tableFilters: filters,
        serverFiltersConfig: SERVER_FILTERS_CONFIG,
      });

      const sort = mapTableSortToServerSort(sorts);

      const { data } = await getContextRecordsQuery({
        variables: {
          contextObjectInput: { slug: contextObjectSlug },
          recordsInput: {
            offset: startRow,
            first: endRow - startRow,
            filter: activeFilters,
            sort,
          },
        },
      });

      const records = data?.contextObject.records || {};

      return {
        rowData: records.nodes || [],
        rowCount: records.totalCount || 0,
      };
    },
    [contextObjectSlug, getContextRecordsQuery],
  );

  return {
    getContextRecords,
  };
};
