import { Drawer } from 'antd';
import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from '@store/hooks';

import { closeNotificationDrawer } from '../../actions';
import { getIsDrawerOpen } from '../../selectors';
import NotificationList from '../NotificationList';

const Notifications = () => {
  const dispatch = useAppDispatch();
  const isDrawerOpen: boolean = useAppSelector(getIsDrawerOpen);
  const handleCloseDrawer = () => dispatch(closeNotificationDrawer());

  return (
    <StyledDrawer
      width="432px"
      title="Notifications"
      placement="left"
      maskClosable={true}
      open={isDrawerOpen}
      onClose={handleCloseDrawer}
      destroyOnClose
    >
      <NotificationList />
    </StyledDrawer>
  );
};

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    height: 100%;
    padding: 0;
  }

  .ant-drawer-header-title {
    flex-direction: row-reverse;
  }

  .ant-drawer-close {
    margin: 0;
  }
`;

export default Notifications;
