import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type { CreateContextRecordsMutationVariables } from '@graphql/types/graphql';

const CREATE_CONTEXT_RECORDS_MUTATION = graphql(`
  mutation CreateContextRecords($data: CreateContextRecordsInput!) {
    createContextRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

type CreateContextRecordsArgs = {
  variables: CreateContextRecordsMutationVariables['data']['contextRecords'];
};

export const useCreateContextRecordsMutation = () => {
  const [createContextRecordsMutation, { loading: isCreatingContextRecords }] =
    useMutation(CREATE_CONTEXT_RECORDS_MUTATION);

  const createContextRecords = ({ variables }: CreateContextRecordsArgs) => {
    return createContextRecordsMutation({
      variables: {
        data: {
          contextRecords: variables,
        },
      },
    });
  };

  return {
    createContextRecords,
    isCreatingContextRecords,
  };
};
