import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const DELETE_CONTEXT_RECORDS_MUTATION = graphql(`
  mutation DeleteContextRecordsModal($data: DeleteContextRecordsInput!) {
    deleteContextRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useDeleteContextRecordsMutation = () => {
  const [deleteContextRecordsMutation, { loading: isDeletingContextRecords }] =
    useMutation(DELETE_CONTEXT_RECORDS_MUTATION);

  const deleteContextRecords = (filters?: ServerFilterInput) => {
    return deleteContextRecordsMutation({
      variables: {
        data: {
          filter: filters,
        },
      },
    });
  };

  return {
    deleteContextRecords,
    isDeletingContextRecords,
  };
};
