import { Button } from 'antd';
import styled from 'styled-components';

type LoadingErrorProps = {
  loadingActionLabel: string;
  onActionClick: () => void;
};

export const LoadingError = ({
  loadingActionLabel,
  onActionClick,
}: LoadingErrorProps) => {
  return (
    <StyledWrapper>
      <p className="label">
        An error occurred while loading {loadingActionLabel}
      </p>
      <Button onClick={onActionClick}>Try again</Button>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  text-align: center;

  .label {
    color: ${({ theme }) => theme.colors.grayText};
  }
`;
