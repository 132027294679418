import {
  Button,
  Dropdown,
  Menu,
  Modal,
  Tooltip,
  message as notify,
} from 'antd';
import DOMPurify from 'dompurify';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import RichTextEditor from '@common/components/RichTextEditor';
import {
  getEditorHTMLContent,
  getEmptyEditorState,
  getUpdatedEditorState,
  isEditorStateBlank,
} from '@common/components/RichTextEditor/helpers';
import { useTaggableMembers } from '@common/components/RichTextEditor/hooks/useTaggableMembers';
import DotsIcon from '@common/icons/Dots';
import { trackEvent } from '@common/utils/track-helpers';
import { getUser } from '@modules/user/selectors';
import { useAppDispatch } from '@store/hooks';
import type { EditorState } from 'draft-js';
import { deleteCommentEvent, updateCommentEvent } from '../actions';

interface CommentEventLogProps {
  log: {
    _id: string;
    actorId: string;
    createdAt: string;
    deletedAt?: string;
    data: {
      message: string;
      userName?: string;
      userEmail?: string;
      previousVersions?: any[];
      actorId: string;
    };
  };
}

const CommentEventLogContainer = ({ log }: CommentEventLogProps) => {
  const logData = log?.data || {};
  const { taggableMembers } = useTaggableMembers();

  const [commentEditorState, setCommentContent] = useState(
    getEmptyEditorState(),
  );
  const [usersToSend, setUsersToSend] = useState<string[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(getUser);

  const dispatch = useAppDispatch();

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    if (isEdit) {
      setCommentContent(
        getUpdatedEditorState(logData.message, commentEditorState),
      );
    }
  }, [isEdit, log]);

  const updateComment = async () => {
    if (!isEditorStateBlank(commentEditorState)) {
      setIsLoading(true);
      await dispatch(
        updateCommentEvent({
          id: log._id,
          data: {
            'data.message': getEditorHTMLContent(commentEditorState),
            usersToSend,
          },
        }),
      );
      setIsEdit(false);
      setIsLoading(false);
    }
  };

  const deleteComment = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this comment?',
      okText: 'Delete',
      okType: 'danger',
      onOk: async () => {
        const response = await dispatch(deleteCommentEvent({ id: log._id }));

        trackEvent('User left a comment on a party');

        if (response.error) {
          notify.error(response.error[0].message);
        }
      },
      onCancel: () => {},
    });
  };

  const isAuthorComment = user._id === log.actorId;
  const hasBeenDeleted = Boolean(log.deletedAt);
  const userDisplayName = logData.userName
    ? `${logData.userName} (${logData.userEmail})`
    : logData.userEmail;

  return (
    <StyledWrapper>
      <StyledHeader>
        <div>
          {userDisplayName} commented on{' '}
          <Tooltip title={moment(log.createdAt).fromNow()}>
            {moment(log.createdAt).format('lll')}
          </Tooltip>
          <StyledCommentStatus>
            {(logData.previousVersions?.length || 0) > 0 &&
              !hasBeenDeleted &&
              'edited'}
            {hasBeenDeleted && 'deleted'}
          </StyledCommentStatus>
        </div>
        {isAuthorComment && !isEdit && !hasBeenDeleted && (
          <Dropdown
            data-cy="dropdownMenu"
            dropdownRender={() => (
              <Menu>
                <Menu.Item
                  key="comment-event-log-edit"
                  onClick={() => setIsEdit(true)}
                >
                  Edit
                </Menu.Item>
                <Menu.Item
                  key="comment-event-log-delete"
                  onClick={deleteComment}
                >
                  Delete
                </Menu.Item>
              </Menu>
            )}
            trigger={['click']}
          >
            <StyledKebabMenuContainer data-cy="commentEventLogMenuButton">
              <DotsIcon />
            </StyledKebabMenuContainer>
          </Dropdown>
        )}
      </StyledHeader>
      <StyledContent>
        {!isEdit && (
          <StyledMessage>
            {hasBeenDeleted ? (
              <span className="deleted">This comment has been deleted.</span>
            ) : (
              <div
                data-cy="commentMessage"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(logData.message || ''),
                }}
              />
            )}
          </StyledMessage>
        )}
        <RichTextEditor
          visible={isEdit}
          isValid
          minHeight={50}
          placeholder="Enter your comment..."
          editorState={commentEditorState}
          onChange={(newState: EditorState, membersIds: string[]) => {
            setCommentContent(newState);
            setUsersToSend(membersIds);
          }}
          members={taggableMembers}
        />
        {isEdit && (
          <StyledActions>
            <Button loading={isLoading} type="primary" onClick={updateComment}>
              Update comment
            </Button>
            <Button onClick={() => setIsEdit(false)}>Cancel</Button>
          </StyledActions>
        )}
      </StyledContent>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  position: relative;
  margin-left: -45px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;

  &::after,
  &::before {
    position: absolute;
    right: 100%;
    top: 18px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    pointer-events: none;
  }

  &::after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #f3f6f8;
    border-width: 7px;
    margin-top: -7px;
  }

  &::before {
    border-color: rgba(194, 225, 245, 0);
    border-right-color: #e2e9ee;
    border-width: 8px;
    margin-top: -8px;
  }
`;

const StyledHeader = styled.div`
  padding-left: 18px;
  padding-right: 6px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px 4px 0 0;
  background-color: #f3f6f8;
`;

const StyledCommentStatus = styled.span`
  margin-left: 8px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.lightBlack};
`;

const StyledKebabMenuContainer = styled.div`
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const StyledActions = styled.div`
  display: flex;
  padding: 10px 0;

  button {
    margin-right: 10px;
  }
`;

const StyledContent = styled.section`
  padding: 14px 15px 20px 19px;
  border-radius: 0 0 4px 4px;
  background-color: ${(props) => props.theme.colors.white};
`;

const StyledMessage = styled.div`
  color: ${(props) => props.theme.colors.grayishBlue};
  font-size: 13px;

  .deleted {
    opacity: 0.6;
  }

  br {
    margin-bottom: 1em;
    display: block;
    content: '.';
  }

  p {
    margin: 0;
  }

  ul,
  ol,
  dl {
    padding-left: 20px;
    margin: 10px 0;
  }

  h1,
  h2,
  h3 {
    margin: 10px 0;
  }

  h1 {
    font-size: 18px;
  }

  h2 {
    font-size: 16px;
  }

  h3 {
    font-size: 14px;
  }
`;

export default CommentEventLogContainer;
