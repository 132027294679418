import { useCallback } from 'react';

import type { GetRowData } from '@common/components/Table';
import { useLazyQuery, useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

import { FILTER_TYPES } from '@common/constants/filters';
import {
  type ServerFiltersConfig,
  mapTableFiltersToServerFilters,
} from '@common/utils/filters';
import type { DeleteAttachmentsMutation } from '@graphql/types/graphql';
import { COLUMN_FIELDS } from '../constants';

type DeleteAttachmentsParams = {
  attachments: any[];
  onCompleted: (data: DeleteAttachmentsMutation) => void;
};

export const RECORD_ATTACHMENTS_QUERY = graphql(`
  query ContextRecordAttachmentsTable(
    $contextRecordInput: ContextRecordInput!
    $input: ContextRecordAttachmentsInput
  ) {
    contextRecord(input: $contextRecordInput) {
      _id
      attachments(input: $input) {
        nodes {
          friendlyName
          s3Key
          uploadedAt
        }
        totalCount
      }
    }
  }
`);

const DELETE_ATTACHMENTS_MUTATION = graphql(`
  mutation DeleteAttachments($data: DeleteContextRecordAttachmentsInput!) {
    deleteContextRecordAttachments(data: $data)
  }
`);

const SERVER_FILTERS_CONFIG: ServerFiltersConfig = {
  [COLUMN_FIELDS.name]: {
    name: 'friendlyName',
    filterType: FILTER_TYPES.string,
  },
};

export const useContextRecordAttachments = ({
  contextRecordId,
}: {
  contextRecordId: string;
}) => {
  const [getAttachmentsQuery, { refetch }] = useLazyQuery(
    RECORD_ATTACHMENTS_QUERY,
  );
  const [deleteAttachmentsMutation] = useMutation(DELETE_ATTACHMENTS_MUTATION);

  const getAttachments: GetRowData = useCallback(
    async ({ startRow, endRow, filters }) => {
      const activeFilters = mapTableFiltersToServerFilters({
        tableFilters: filters,
        serverFiltersConfig: SERVER_FILTERS_CONFIG,
      });

      const { data } = await getAttachmentsQuery({
        variables: {
          contextRecordInput: { id: contextRecordId },
          input: {
            offset: startRow,
            first: endRow - startRow,
            filter: activeFilters,
          },
        },
      });

      return {
        rowData: data?.contextRecord?.attachments.nodes || [],
        rowCount: Math.min(
          data?.contextRecord?.attachments.totalCount || 0,
          60,
        ),
      };
    },
    [contextRecordId, getAttachmentsQuery],
  );

  const deleteAttachments = useCallback(
    ({ attachments, onCompleted }: DeleteAttachmentsParams) => {
      return deleteAttachmentsMutation({
        variables: {
          data: {
            contextRecordId: contextRecordId,
            filter: {
              s3Keys: attachments.map((attachment) => attachment.s3Key),
            },
          },
        },
        onCompleted,
      });
    },
    [contextRecordId, deleteAttachmentsMutation],
  );

  return {
    refetchAttachments: refetch,
    deleteAttachments,
    getAttachments,
  };
};
