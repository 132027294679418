import type { SetFilter, TextFilter } from '../../components/Table';
import {
  FILTER_TYPES,
  type Filter,
  type FilterOperators,
} from '../../constants/filters';

import { getDateFilter } from './dateFilter';
import { getIdFilter } from './idFilter';
import { getNumberFilter } from './numberFilter';
import { getOptionsFilter } from './optionsFilter';
import { getTextFilter } from './textFilter';

interface MatchFilterParams {
  _id: string;
  value: string;
  operator: string;
  type: 'string' | 'number';
}

export type ServerFiltersConfig = {
  [columnFieldName: string]:
    | {
        name: string; // filter name expected by the server
        filterType: Exclude<FILTER_TYPES, 'match'>;
      }
    | {
        name: string;
        filterType: FILTER_TYPES.match;
        // we should define a better return definition or import types from @trustlayer/filters
        customMapper: (params: MatchFilterParams) => {
          name?: string;
          operator: FilterOperators;
          value: any;
        };
      };
};

const MAPPERS_BY_TYPE = {
  [FILTER_TYPES.date]: getDateFilter,
  [FILTER_TYPES.string]: getTextFilter,
  [FILTER_TYPES.options]: getOptionsFilter,
  [FILTER_TYPES.id]: getIdFilter,
  [FILTER_TYPES.number]: getNumberFilter,
} as const;

type TableFilter = SetFilter | TextFilter | undefined;

/**
 * Translation layer from the table filters model to server filters model.
 */
export const mapTableFiltersToServerFilters = <
  TTableFilters = Record<string, TableFilter>,
>({
  tableFilters,
  serverFiltersConfig,
}: {
  tableFilters: TTableFilters;
  serverFiltersConfig: ServerFiltersConfig;
}) => {
  if (
    !tableFilters ||
    !Object.keys(tableFilters || {}).length ||
    !Object.keys(serverFiltersConfig || {}).length
  ) {
    return;
  }

  const queryFilters = Object.keys(tableFilters as object).reduce<Filter[]>(
    (acc, filterKey) => {
      const currentFilterConfig = serverFiltersConfig[filterKey];

      if (!currentFilterConfig) {
        return acc;
      }

      const getFilterByType =
        currentFilterConfig.filterType === FILTER_TYPES.match
          ? currentFilterConfig.customMapper
          : MAPPERS_BY_TYPE[currentFilterConfig.filterType];

      // @ts-expect-error - TS is considering tableFilters could be {} but in that case the process won't call this expression
      const currentTableFilter = tableFilters[filterKey];

      const filter = getFilterByType(currentTableFilter);

      if (!filter) {
        return acc;
      }

      return [
        ...acc,
        {
          name: ('name' in filter && filter.name) || currentFilterConfig.name,
          operator: filter.operator,
          value: filter.value,
        },
      ];
    },
    [],
  );

  return {
    and: queryFilters,
  };
};
