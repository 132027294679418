import Spinner from '@common/components/Spinner';
import EmailTemplateEditForm from '@modules/email-template/forms/EmailTemplateEditForm';
import { Form } from 'antd';
import { useEmailTemplateQuery } from './hooks';

export const CustomizeEmailTemplate = () => {
  const form = Form.useFormInstance();
  const templateId = Form.useWatch('emailTemplateId', form);

  const { emailTemplate, isEmailTemplateLoading } = useEmailTemplateQuery({
    templateId,
  });

  if (!templateId) {
    return <>Please select a template first.</>;
  }

  if (isEmailTemplateLoading) {
    return <Spinner />;
  }

  if (!emailTemplate) {
    return <>No template found.</>;
  }

  return (
    <EmailTemplateEditForm
      template={emailTemplate}
      layout={emailTemplate.layout}
      namePrefix={['customTemplate']}
    />
  );
};
