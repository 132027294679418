import { Select } from 'antd';
import { useState } from 'react';

import InfiniteScrollSelect from '@common/components/InfiniteScrollSelect';
import Spinner from '@common/components/Spinner';

type Option = {
  _id: string;
  name: string;
};

type RichSelectProps = {
  optionsList: Option[];
  optionsTotalCount: number;
  onSearch: (value: string) => void;
  onLoadMore: () => void;
  loading: boolean;
  children: (item: Option) => React.ReactNode;
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
};

/**
 * Infinite scroll select with typeahead search component
 */
export const RichSelect = ({
  optionsList,
  optionsTotalCount,
  loading,
  value,
  placeholder,
  onSearch,
  onChange,
  onLoadMore,
  children,
}: RichSelectProps) => {
  const [searchValue, setSearchValue] = useState('');

  const handleOnSearch = (value: string) => {
    setSearchValue(value);
    onSearch(value);
  };
  return (
    <InfiniteScrollSelect
      showSearch
      value={value}
      loader={<Spinner />}
      isDataLoading={loading}
      searchValue={searchValue}
      placeholder={placeholder}
      onSearch={handleOnSearch}
      hasMore={optionsList.length < optionsTotalCount}
      onChange={onChange}
      loadMore={() => {
        onLoadMore();
      }}
    >
      {optionsList?.map((option: any) => (
        <Select.Option key={option._id} value={option._id} label={option.name}>
          {children?.(option)}
        </Select.Option>
      ))}
    </InfiniteScrollSelect>
  );
};
