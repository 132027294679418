import { OBJECT_TYPES_BY_KEY_MAP } from '../constants';

export const PARTY_DETAILS_MESSAGES_ROUTE =
  '/:organizationSlugifyName/parties/:partyId/messages/:conversationId';

export const PARTY_DETAILS_ROUTE = `/:organizationSlugifyName/parties/:partyId/:tab`;

export const getObjectTypeFromPath = (path: string) => {
  const pathSegments = path.split('/');
  const key = pathSegments[2] as keyof typeof OBJECT_TYPES_BY_KEY_MAP;
  return OBJECT_TYPES_BY_KEY_MAP[key];
};
