import { Button, Modal } from 'antd';
import { type ReactNode, useState } from 'react';
import { useBlocker } from 'react-router-dom';

interface RouteLeavingGuardModalProps {
  when: boolean;
  shouldCancelLeavesPage?: boolean;
  title?: string;
  message?: string | ReactNode;
  okText?: string;
  cancelText?: string;
  onConfirm?: () => Promise<void> | void;
  onCancel?: () => Promise<void> | void;
  onClose?: () => void | Promise<void>;
}

const RouteLeavingGuardModal: React.FC<RouteLeavingGuardModalProps> = ({
  when,
  shouldCancelLeavesPage = false,
  title = 'Close without saving?',
  message = 'You have unsaved changes. Are you sure you want to leave this page without saving?',
  okText = 'Close without saving',
  cancelText = 'Cancel',
  onConfirm,
  onCancel,
  onClose,
}) => {
  const blocker = useBlocker(when);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [loadingClose, setLoadingClose] = useState(false);

  async function handleCancelButton() {
    if (shouldCancelLeavesPage) {
      setLoadingCancel(true);
      try {
        await onCancel?.();
        blocker.proceed?.();
      } finally {
        setLoadingCancel(false);
      }
    } else {
      handleCloseModal();
    }
  }

  async function handleOkButton() {
    setLoadingConfirm(true);
    try {
      await onConfirm?.();
      blocker.proceed?.();
    } finally {
      setLoadingConfirm(false);
    }
  }

  async function handleCloseModal() {
    setLoadingClose(true);
    try {
      await onClose?.();
    } finally {
      setLoadingClose(false);
      blocker.reset?.();
    }
  }

  return (
    <Modal
      title={title}
      open={blocker.state === 'blocked'}
      onCancel={handleCloseModal}
      maskClosable={!loadingClose && !loadingCancel && !loadingConfirm}
      zIndex={1050}
      footer={[
        <Button
          key="cancelBtn"
          onClick={handleCancelButton}
          disabled={loadingConfirm || loadingClose}
          loading={loadingCancel}
        >
          {cancelText}
        </Button>,
        <Button
          key="okBtn"
          onClick={handleOkButton}
          type="primary"
          loading={loadingConfirm}
          disabled={loadingCancel || loadingClose}
        >
          {okText}
        </Button>,
      ]}
    >
      <p>{message}</p>
    </Modal>
  );
};

export default RouteLeavingGuardModal;
