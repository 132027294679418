import { FILTER_OPERATORS, FILTER_TYPES } from '@common/constants/filters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const MAKE_ACTIVE_REQUEST_RECORD_MUTATION = graphql(`
  mutation MakeActiveRequestRecord($data: ActivateRequestRecordsInput!) {
    activateRequestRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useMakeActiveRequestRecordMutation = () => {
  const [
    makeActiveRequestRecordMutation,
    { loading: isMakingActiveRequestRecord },
  ] = useMutation(MAKE_ACTIVE_REQUEST_RECORD_MUTATION);

  const makeActiveRequestRecord = (id: string) => {
    return makeActiveRequestRecordMutation({
      variables: {
        data: {
          filter: {
            and: [
              {
                name: FILTER_TYPES.id,
                operator: FILTER_OPERATORS.in,
                value: [id],
              },
            ],
          },
        },
      },
    });
  };

  return {
    makeActiveRequestRecord,
    isMakingActiveRequestRecord,
  };
};
