export const COLUMN_FIELDS = {
  name: 'friendlyName',
  s3Key: 's3Key',
  actions: 'actions',
} as const;

export const ATTACHMENTS_ACTION_TYPES = {
  download: 'download',
  delete: 'delete',
} as const;

export type ActionType =
  (typeof ATTACHMENTS_ACTION_TYPES)[keyof typeof ATTACHMENTS_ACTION_TYPES];
