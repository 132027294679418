import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ModalConfirm } from '@common/components/ModalConfirm';
import { BulkOperationStatus } from '@graphql/types/graphql';
import { message } from 'antd';
import { useDeleteContextRecordMutation } from '../../hooks/useDeleteContextRecordMutation';

type DeleteContextRecordModalProps = {
  open: boolean;
  contextRecord: {
    _id: string;
    name: string;
  };
  onCancel?: () => void;
  onCompleted?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
};

export const DeleteContextRecordModal = ({
  open,
  contextRecord,
  onCancel,
  onCompleted,
  onScheduled,
  onFailed,
}: DeleteContextRecordModalProps) => {
  const { deleteContextRecord, isDeletingContextRecord } =
    useDeleteContextRecordMutation();

  const handleDelete = async () => {
    const res = await deleteContextRecord(contextRecord._id);

    const status = res.data?.deleteContextRecords.operation.status;

    if (status === BulkOperationStatus.Completed) {
      message.success(`${contextRecord.name} deleted successfully.`);
      onCompleted?.();
    }

    if (status === BulkOperationStatus.Scheduled) {
      message.success(`${contextRecord.name} has been scheduled for deletion.`);
      onScheduled?.();
    }

    if (status === BulkOperationStatus.Failed) {
      message.error(
        `The deletion of your ${contextRecord.name} has failed. Please try again or contact support.`,
      );
      onFailed?.();
    }
  };

  return (
    <ModalConfirm
      data-cy="delete-context-record-modal"
      open={open}
      icon={<ExclamationCircleOutlined />}
      okButtonProps={{
        danger: true,
        loading: isDeletingContextRecord,
      }}
      okText="Delete"
      title={`Are you sure you want to delete ${contextRecord.name}?`}
      onCancel={onCancel}
      onOk={handleDelete}
    >
      <p>This action cannot be undone</p>
    </ModalConfirm>
  );
};
