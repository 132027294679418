import {
  ATTRIBUTE_TYPES,
  type Attribute,
  DEFAULT_EMPTY_SELECT_VALUE,
} from '../constants';

export const isAttributeValueEmpty = (attribute: Attribute) => {
  if (attribute.type === ATTRIBUTE_TYPES.MultiSelect) {
    return attribute.selectedOptions?.length === 0;
  }

  if (attribute.type === ATTRIBUTE_TYPES.Dropdown) {
    return (
      attribute.selectedOptions === null ||
      (attribute.selectedOptions &&
        attribute.selectedOptions[0]!._id === DEFAULT_EMPTY_SELECT_VALUE._id)
    );
  }

  if (attribute.type === ATTRIBUTE_TYPES.Number) {
    return attribute.value === null || attribute.value === undefined;
  }

  return !attribute?.value;
};
