import { InfoCircleOutlined } from '@ant-design/icons';
import { FullWidthSpace } from '@trustlayer/ui';
import { Checkbox, Input, Modal, Popover, Typography } from 'antd';
import dayjs, { type Dayjs } from 'dayjs';
import capitalize from 'lodash/capitalize';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import FullWidthDatePicker from '@common/components/FullWidthDatePicker';
import { utc } from '@common/utils/date';
import { defaultDateFormats } from '@common/utils/filter-helpers';
import { SUBJECT_STATUS } from '@modules/party/constants';

const { TextArea } = Input;

type onOkInputData = {
  notes?: string;
  resetRequirementOn?: Dayjs | null;
  selectedSubject?: { subjectId: string; subjectLabel: string } | null;
  applyToAllProjects: boolean;
};

type SelectedSubject = {
  subjectId: string;
  subjectLabel: string;
  notes: string;
  requirementComplianceStatusValue?: 'overridden' | 'waived';
  resetRequirementOn?: string;
};

type ChangeSubjectStatusModalProps = {
  visible: boolean;
  selectedSubject: SelectedSubject | null;
  type?: (typeof SUBJECT_STATUS)[keyof typeof SUBJECT_STATUS];
  loading: boolean;
  onSubmit: (data: onOkInputData) => void;
  onClose?: () => void;
};

export const ChangeSubjectStatusModal = ({
  visible,
  type = SUBJECT_STATUS.WAIVED,
  selectedSubject,
  onSubmit = () => {},
  onClose = () => {},
}: ChangeSubjectStatusModalProps) => {
  const [notes, setNotes] = useState(selectedSubject?.notes ?? '');
  const [expiringDate, setExpiringDate] = useState<Dayjs | null>(
    selectedSubject?.resetRequirementOn
      ? dayjs(selectedSubject?.resetRequirementOn)
      : null,
  );
  const [applyToAllProjects, setToApplyAllProjects] = useState(false);

  const labelByType = type === SUBJECT_STATUS.WAIVED ? 'waive' : 'override';
  const labelByTypeSubject =
    type === SUBJECT_STATUS.WAIVED ? 'waiver' : 'override';

  const resetRequirementOn = expiringDate ? utc(expiringDate) : null;

  const handleOnOk = () => {
    onSubmit({
      notes,
      resetRequirementOn,
      selectedSubject,
      applyToAllProjects,
    });
  };

  const handleOnCancel = () => {
    setNotes('');
    setExpiringDate(null);
    onClose();
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    if (visible) {
      setExpiringDate(
        selectedSubject?.resetRequirementOn
          ? // The date is stored as UTC but we need to display it in the user's timezone
            dayjs(selectedSubject.resetRequirementOn)
          : null,
      );
      setNotes(selectedSubject?.notes ?? '');
    }
  }, [visible]);

  const disabledDate = (current: Dayjs) => {
    return current < dayjs().endOf('day');
  };
  return (
    <Modal
      title={`${capitalize(labelByType)} requirement`}
      open={visible}
      centered={true}
      width={685}
      okText={capitalize(labelByType)}
      onOk={handleOnOk}
      okButtonProps={{ 'data-cy': 'waiveBtn' } as any}
      onCancel={handleOnCancel}
    >
      <FullWidthSpace direction="vertical" size="middle">
        <Typography.Text>
          Please confirm that you want to {labelByType} the requirement below:
        </Typography.Text>
        <StyledSubjectInfoWrapper>
          <StyledSubjectLabel>
            {selectedSubject?.subjectLabel}
          </StyledSubjectLabel>
          <StyledInfo>
            All existing and added requirements from this compliance subject
            will be {type.toLowerCase()} while is active.
          </StyledInfo>
        </StyledSubjectInfoWrapper>
        <label>Notes:</label>
        <TextArea
          data-cy="changeSubjectStatusModalNotesInput"
          rows={3}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
        <label>Remove {labelByTypeSubject} on:</label>
        <FullWidthDatePicker
          data-cy="datePicker"
          onChange={setExpiringDate}
          value={expiringDate}
          disabledDate={disabledDate}
          format={defaultDateFormats}
        />
        <FullWidthSpace align="center" size="small">
          <Checkbox
            checked={applyToAllProjects}
            onChange={() =>
              setToApplyAllProjects((applyToAllProjects) => !applyToAllProjects)
            }
            id="apply-across-all-projects"
          />
          <label htmlFor="apply-across-all-projects">
            Apply {labelByType} across all projects
          </label>
          {
            // TODO: Replace the text with: "This waiver will be applied to all existing and future projects", once the waiver can be applied to future projects
          }
          <Popover
            content={`This ${labelByType} will apply to all existing and future projects`}
          >
            <InfoCircleOutlined />
          </Popover>
        </FullWidthSpace>
      </FullWidthSpace>
    </Modal>
  );
};

const StyledSubjectLabel = styled.p`
  font-weight: 600;
  margin-bottom: 5px;
`;

const StyledInfo = styled.p`
  margin: 0;
`;

const StyledSubjectInfoWrapper = styled.div`
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  background-color: ${({ theme }) => theme.colors.blueGray};
  border-radius: 4px;
`;
