import { Avatar } from 'antd';
import styled from 'styled-components';

import type { Nullable } from '@common/types';
import { getColorFromText } from '@common/utils/color-hash-generator';
import { getAbbreviature } from '@common/utils/text-helpers';

const StyledAvatar = styled(Avatar)<{ $color?: string }>`
  flex-shrink: 0;
  background-color: ${(props) => (props.$color ? props.$color : 'none')};
`;

type OrganizationAvatarProps = {
  name: string;
  iconUrl?: Nullable<string>;
  size?: number;
};

const OrganizationAvatar = ({
  name,
  iconUrl,
  size = 40,
}: OrganizationAvatarProps) => (
  <StyledAvatar
    shape="square"
    $color={iconUrl ? undefined : getColorFromText(name)}
    size={size}
    src={iconUrl || undefined}
  >
    {getAbbreviature(name)}
  </StyledAvatar>
);

export default OrganizationAvatar;
