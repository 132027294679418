import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const FETCH_DOCUMENT_REVIEW_THUMBNAILS = `${namespace}/FETCH_DOCUMENT_REVIEW_THUMBNAILS`;

const FETCH_DOCUMENT_REVIEW_THUMBNAILS_QUERY = gql`
  query listDocumentReviewThumbnails(
    $filter: Any
    $sort: Any
    $skip: NonNegativeInt
    $limit: NonNegativeInt
  ) {
    listDocuments(filter: $filter, sort: $sort, skip: $skip, limit: $limit) {
      _id
      friendlyName
      notes
      request
      thumbnailUrl
      createdAt
      reviewedAt
      archivedAt
      organization
      appliesToAllProjects
      projects {
        _id
      }
    }
    countDocuments(filter: $filter, skip: $skip, limit: $limit)
  }
`;

export const fetchDocumentReviewThumbnails = (payload) => ({
  type: FETCH_DOCUMENT_REVIEW_THUMBNAILS,
  payload: {
    key: 'listDocuments',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_DOCUMENT_REVIEW_THUMBNAILS_QUERY,
      variables: payload,
    },
  },
});
