import { FILTER_OPERATORS } from '@common/constants/filters';
import { ATTRIBUTE_TYPES, type AttributeType } from '../../constants';

export const CUSTOM_FILTER_OPERATORS = {
  isEmpty: 'isEmpty',
};

export const OperatorsValues = {
  contains: 'contains',
  gt: 'is greater than',
  lt: 'is less than',
  eq: 'is equal to',
  empty: 'is empty',
};

type OperatorsType = Record<
  string,
  {
    key: string;
    label: string;
    types: AttributeType[];
  }
>;

export const Operators: OperatorsType = {
  CONTAINS: {
    key: FILTER_OPERATORS.contains,
    label: 'contains',
    types: [ATTRIBUTE_TYPES.Text],
  },
  IS_GREATER_THAN: {
    key: FILTER_OPERATORS.greaterThan,
    label: 'is greater than',
    types: [ATTRIBUTE_TYPES.Number],
  },
  IS_EQUAL_TO: {
    key: FILTER_OPERATORS.equal,
    label: 'is equal to',
    types: [
      ATTRIBUTE_TYPES.Number,
      ATTRIBUTE_TYPES.Text,
      ATTRIBUTE_TYPES.Dropdown,
    ],
  },
  IS_LESS_THAN: {
    key: FILTER_OPERATORS.lessThan,
    label: 'is less than',
    types: [ATTRIBUTE_TYPES.Number],
  },
  IS_EMPTY: {
    key: CUSTOM_FILTER_OPERATORS.isEmpty,
    label: 'is empty',
    types: [
      ATTRIBUTE_TYPES.Number,
      ATTRIBUTE_TYPES.Text,
      ATTRIBUTE_TYPES.Dropdown,
    ],
  },
};
