import { UploadOutlined } from '@ant-design/icons';
import { ModalConfirm } from '@common/components/ModalConfirm';
import { withControlledMount } from '@common/hoc/withControlledMount';
import { acceptExtensions } from '@modules/document/constants';
import { MAX_DOCUMENTS_UPLOAD_COUNT } from '@trustlayer/common';
import { Space, Typography, Upload, message, theme } from 'antd';
import type { UploadFile } from 'antd/es/upload';
import { useState } from 'react';
import { useContextRecordQuery } from '../../hooks/useContextRecordQuery';
import { useUploadContextRecordAttachments } from '../../hooks/useUploadContextRecordAttachments';
type UploadAttachmentModalProps = {
  open: boolean;
  contextRecordId?: string;
  onCancel?: () => void;
  onCompleted?: () => void;
  onFailed?: () => void;
};

const UploadAttachmentModalComponent = ({
  open,
  contextRecordId,
  onCancel,
  onCompleted,
  onFailed,
}: UploadAttachmentModalProps) => {
  const { contextRecord, loading } = useContextRecordQuery({
    id: contextRecordId!,
    skip: !open || !contextRecordId,
  });
  const { token } = theme.useToken();
  const [files, setFiles] = useState<UploadFile[]>([]);
  const { uploadContextRecordAttachments, uploading } =
    useUploadContextRecordAttachments();
  const handleUpload = async () => {
    const res = await uploadContextRecordAttachments({
      variables: {
        data: {
          contextRecordId,
          files,
        },
      },
    });

    if (res.data?.uploadContextRecordAttachments) {
      message.success('Attachments uploaded successfully.');
      setFiles([]);
      onCompleted?.();
    } else {
      message.error('Failed to upload attachments.');
      onFailed?.();
    }
  };

  return (
    <ModalConfirm
      data-cy="upload-attachment-context-record-modal"
      open={open}
      okText="Upload"
      title="Upload attachments"
      onCancel={onCancel}
      onOk={handleUpload}
      bodyStyle={{ padding: 0 }}
      okButtonProps={{ loading: uploading }}
      loading={loading}
    >
      <Typography.Paragraph>
        Attach up to {MAX_DOCUMENTS_UPLOAD_COUNT} files to {contextRecord?.name}{' '}
        at a time.
      </Typography.Paragraph>
      <Upload.Dragger
        multiple
        maxCount={MAX_DOCUMENTS_UPLOAD_COUNT}
        accept={acceptExtensions}
        beforeUpload={(file) => {
          setFiles((files) => {
            if (files.length >= MAX_DOCUMENTS_UPLOAD_COUNT) {
              return files;
            }
            return files.concat(file);
          });
          // prevent automatic upload from Upload.Dragger since its demanded to the parent component
          return false;
        }}
        onRemove={(file) => {
          setFiles((files) => files.filter((f) => f.uid !== file.uid));
        }}
        fileList={files}
      >
        <Space direction="vertical" size="middle">
          <UploadOutlined
            style={{
              fontSize: token.sizeXXL,
              color: token.colorPrimaryHover,
            }}
          />
          <div>
            <Typography.Text>Upload documents</Typography.Text>
            <div>
              <Typography.Text
                type="secondary"
                style={{ fontSize: token.fontSizeSM }}
              >
                Drag and drop documents or{' '}
              </Typography.Text>
              <Typography.Text
                underline
                type="secondary"
                style={{ fontSize: token.fontSizeSM }}
              >
                click to browse and select
              </Typography.Text>
            </div>
          </div>
        </Space>
      </Upload.Dragger>
    </ModalConfirm>
  );
};

export const UploadAttachmentModal = withControlledMount(
  UploadAttachmentModalComponent,
);
