import { useEffect, useRef, useState } from 'react';

type ResizeOptions = {
  maxWidth: number;
  minWidth: number;
  defaultWidth: number;
  storageKey: string;
  onClick?: () => void;
  minThresholdWatch?: [number, () => void];
  enabled?: boolean;
  placement: 'left' | 'right';
};

export const useResize = ({
  maxWidth,
  minWidth,
  defaultWidth,
  storageKey,
  onClick,
  minThresholdWatch,
  enabled = true,
  placement,
}: ResizeOptions) => {
  const handlerRef = useRef<HTMLDivElement>(null);
  const resizableRef = useRef<HTMLDivElement>(null);

  const [initialWidth, setInitialWidth] = useState(() => {
    const storedWidth = localStorage.getItem(storageKey);
    return storedWidth ? parseInt(storedWidth, 10) : defaultWidth;
  });

  useEffect(() => {
    if (!handlerRef.current || !resizableRef.current) {
      return;
    }

    const gutter = handlerRef.current;
    const resizable = resizableRef.current;

    if (enabled) {
      gutter.classList.remove('disabled');
      if (initialWidth) {
        resizable.style.width = `${initialWidth}px`;
      }
    } else {
      gutter.classList.add('disabled');
      resizable.style.width = 'unset';
    }

    const onMouseDown = (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      const mouseDownAt = Date.now();
      const startX = e.clientX;
      const startWidth = resizable.getBoundingClientRect().width;
      let animationFrameId: number;
      let lastClientX = startX;
      let currentWidth: number;

      gutter.classList.add('active');

      const updateWidth = () => {
        resizable.style.width = `${currentWidth}px`;
        animationFrameId = requestAnimationFrame(updateWidth);
      };

      const onMouseMove = (e: MouseEvent) => {
        const clientX = e.clientX;
        const velocity = +(lastClientX - clientX);
        const deltaX = +(clientX - startX);
        const width =
          placement === 'right'
            ? startWidth + deltaX + velocity
            : startWidth - deltaX - velocity;

        const nextWidth = Math.max(minWidth, Math.min(width, maxWidth));

        if (minThresholdWatch) {
          const isShrink = currentWidth > nextWidth;

          if (isShrink && nextWidth <= minThresholdWatch[0]) {
            minThresholdWatch[1]?.();
            resizable.style.width = `unset`;
            cleanUp();
            return;
          }
        }

        if (nextWidth === maxWidth || nextWidth === minWidth) {
          gutter.classList.add('blocked');
        } else {
          gutter.classList.remove('blocked');
        }

        currentWidth = nextWidth;
        lastClientX = e.clientX;
      };

      const cleanUp = () => {
        cancelAnimationFrame(animationFrameId);

        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);

        gutter.classList.remove('active');
        gutter.classList.remove('blocked');
      };

      const onMouseUp = () => {
        cleanUp();

        const eventsDuration = Date.now() - mouseDownAt;
        if (eventsDuration < 100 || !currentWidth) {
          onClick?.();
          return;
        }

        const finalWidth = currentWidth || initialWidth || startWidth;
        setInitialWidth(finalWidth);
        localStorage.setItem(storageKey, String(finalWidth));
      };

      document.addEventListener('mouseup', onMouseUp);

      if (enabled) {
        document.addEventListener('mousemove', onMouseMove);
        animationFrameId = requestAnimationFrame(updateWidth);
      } else {
        resizable.style.width = `${initialWidth}px`;
      }
    };

    gutter.addEventListener('mousedown', onMouseDown);

    return () => {
      gutter.removeEventListener('mousedown', onMouseDown);
    };
  }, [
    initialWidth,
    maxWidth,
    minWidth,
    onClick,
    enabled,
    minThresholdWatch,
    storageKey,
    placement,
  ]);

  return { handlerRef, resizableRef };
};
