import { FILTER_OPERATORS } from '@common/constants/filters';

import { COLUMN_FIELDS } from './constants';

export const getDeleteFilters = ({
  s3Keys,
  textValue,
}: {
  s3Keys?: string[];
  textValue?: string;
}) => {
  if (s3Keys?.length) {
    return {
      and: [
        {
          name: COLUMN_FIELDS.s3Key,
          operator: FILTER_OPERATORS.in,
          value: s3Keys,
        },
      ],
    };
  }

  return {
    and: [
      ...(textValue
        ? [
            {
              name: COLUMN_FIELDS.name,
              operator: FILTER_OPERATORS.contains,
              value: textValue,
            },
          ]
        : []),
    ],
  };
};
