import { PartyFilterFields } from '../constants';

export const parseComplianceProfileFilter = (
  searchParams: Record<string, any>,
) => {
  const rawValue = searchParams[PartyFilterFields.AssignedComplianceProfile];

  if (!rawValue) return {};

  if (Array.isArray(rawValue)) {
    return {
      assignedComplianceProfile: rawValue,
    };
  }

  return {
    assignedComplianceProfile: [rawValue],
  };
};
