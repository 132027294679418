import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const CONTEXT_RECORD_QUERY = graphql(`
  query ContextRecordGenrality($input: ContextRecordInput!) {
    contextRecord(input: $input) {
      _id
      name
      status
      stats {
        requestRecords {
          totalCount
          compliantCount
        }
      }
    }
  }
`);

export const useContextRecordGenerality = ({
  contextRecordId,
}: {
  contextRecordId: string;
}) => {
  const { data, loading, refetch } = useQuery(CONTEXT_RECORD_QUERY, {
    variables: {
      input: { id: contextRecordId },
    },
  });

  return {
    contextRecord: data?.contextRecord,
    refetchContextRecord: refetch,
    loading,
  };
};
