import omit from 'lodash/omit';

import {
  ATTRIBUTE_TYPES,
  type Address,
  type Attribute,
  type AttributeToUpdate,
} from '../constants';
import { isAttributeValueEmpty } from './isAttributeValueEmpty';

const mapAddressToUpdate = (address: Address) => {
  const updatedValue = {
    ...address,
    line1: address.addressLine1,
    line2: address.addressLine2,
  };

  return omit(updatedValue, ['addressLine1', 'addressLine2']);
};

export const formatAttributeToUpdate = (
  attribute: Attribute,
): AttributeToUpdate => {
  if (attribute.type === ATTRIBUTE_TYPES.Dropdown) {
    return {
      id: attribute._id,
      optionIds: isAttributeValueEmpty(attribute)
        ? null
        : [attribute.selectedOptions![0]?._id],
    };
  }

  if (attribute.type === ATTRIBUTE_TYPES.Address) {
    return {
      id: attribute._id,
      value: mapAddressToUpdate(attribute.value),
    };
  }

  return {
    id: attribute._id,
    value: (attribute.value || null) as string | number | null,
  };
};
