import { STATE_KEY } from '../reducers';

export const getDocuments = (state) => {
  const documents = state?.[STATE_KEY]?.data || {};
  return Object.values(documents).filter(
    (document) => !document.deletedAt && Boolean(document.organization),
  );
};

export const getDocumentsByIds = (state, ids = []) => {
  return getDocuments(state).filter((document) => ids.includes(document._id));
};

export const getActiveDocuments = (state, projectId) => {
  const currentDocuments = getDocuments(state);

  return currentDocuments
    .filter((document) =>
      projectId
        ? document.appliesToAllProjects ||
          (document.projects || []).some(
            (project) => project?._id === projectId,
          )
        : true,
    )
    .filter((document) => !Boolean(document.archivedAt))
    .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
};

export const getArchivedDocuments = (state, projectId) => {
  const currentDocuments = getDocuments(state);

  return currentDocuments
    .filter((document) =>
      projectId
        ? document.appliesToAllProjects ||
          (document.projects || []).some((project) => project._id === projectId)
        : true,
    )
    .filter((document) => Boolean(document.archivedAt))
    .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
};

export const getDocument = (state, id) =>
  state?.[STATE_KEY]?.data?.[id] || null;

export const getDocumentFillableFormState = (state, id) => {
  const document = getDocument(state, id);
  return document?.fillableForm?.status;
};

export const getDocumentsByParty = (state, partyId) => {
  return getDocuments(state).filter(
    (document) => document?.party?._id === partyId,
  );
};

export const getIsUpdatingDocument = (state) =>
  state?.[STATE_KEY]?.isUpdatingDocument || false;

export const getIsExistSubscription = (state, partyId) => {
  const ids = state?.[STATE_KEY]?.subscriptionsPartyIds || [];
  return ids.includes(partyId);
};

export const getDocumentProjects = (state, documentId) => {
  const document = getDocument(state, documentId);
  return document?.projects || [];
};

export const getSubscriptions = (state) =>
  state?.[STATE_KEY]?.subscriptionsPartyIds || [];

export const getTotalDocumentsCount = (state) =>
  state?.[STATE_KEY]?.totalCount || 0;

export const getIsUploadingDocuments = (state) =>
  state?.[STATE_KEY]?.isUploadingDocuments || false;
