const OBJECT_KEY_TO_LEGACY_ENTITY_SLUG = {
  p: 'parties',
  c: 'projects',
};

const isObjectKey = (
  value: string,
): value is keyof typeof OBJECT_KEY_TO_LEGACY_ENTITY_SLUG => {
  return Object.keys(OBJECT_KEY_TO_LEGACY_ENTITY_SLUG).includes(value);
};

export const getPageKeyFromURL = (pathname: string) => {
  const URLparams = pathname.split('/').filter(Boolean);
  if (!URLparams.length) {
    return;
  }

  const objectReference = URLparams[1];

  return isObjectKey(objectReference)
    ? OBJECT_KEY_TO_LEGACY_ENTITY_SLUG[objectReference]
    : objectReference;
};
