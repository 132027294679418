import { FILTER_OPERATORS, FILTER_TYPES } from '@common/constants/filters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const MAKE_ACTIVE_PRIMARY_RECORD_MUTATION = graphql(`
  mutation MakeActivePrimaryRecord($data: ActivatePrimaryRecordsInput!) {
    activatePrimaryRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useMakeActivePrimaryRecordMutation = () => {
  const [
    makeActivePrimaryRecordMutation,
    { loading: isMakingActivePrimaryRecord },
  ] = useMutation(MAKE_ACTIVE_PRIMARY_RECORD_MUTATION);

  const makeActivePrimaryRecord = (id: string) => {
    return makeActivePrimaryRecordMutation({
      variables: {
        data: {
          filter: {
            and: [
              {
                name: FILTER_TYPES.id,
                operator: FILTER_OPERATORS.in,
                value: [id],
              },
            ],
          },
        },
      },
    });
  };

  return {
    makeActivePrimaryRecord,
    isMakingActivePrimaryRecord,
  };
};
