import { FILTER_OPERATORS, FILTER_TYPES } from '@common/constants/filters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const ARCHIVE_REQUEST_RECORD_MUTATION = graphql(`
  mutation ArchiveRequestRecord($data: ArchiveRequestRecordsInput!) {
    archiveRequestRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useArchiveRequestRecordMutation = () => {
  const [archiveRequestRecordMutation, { loading: isArchivingRequestRecord }] =
    useMutation(ARCHIVE_REQUEST_RECORD_MUTATION);

  const archiveRequestRecord = async (id: string) => {
    return archiveRequestRecordMutation({
      variables: {
        data: {
          filter: {
            and: [
              {
                name: FILTER_TYPES.id,
                operator: FILTER_OPERATORS.in,
                value: [id],
              },
            ],
          },
        },
      },
    });
  };

  return {
    archiveRequestRecord,
    isArchivingRequestRecord,
  };
};
