import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

export const CONTEXT_OBJECT_QUERY = graphql(`
  query ContextObjectGenerality($input: ContextObjectInput!) {
    contextObject(input: $input) {
      _id
      icon
      name
      pluralName
    }
  }
`);

export const useContextObjectGenerality = ({
  objectSlug,
}: {
  objectSlug?: string;
}) => {
  const { data, loading } = useQuery(CONTEXT_OBJECT_QUERY, {
    variables: { input: { slug: objectSlug } },
    skip: !objectSlug,
  });

  return {
    contextObject: data?.contextObject,
    loading,
  };
};
