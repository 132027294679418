import {
  type AttributeFilter,
  AttributeFilterForm,
} from '@modules/attributes/containers/AttributesFilterForm';
import type { RecordTypes } from '@trustlayer/common';
import type { CustomFilterProps } from '../types';
import { CustomFilterWrapper } from './CustomFilterWrapper';

export type { AttributeFilter };

import { useCallback } from 'react';

type AttributeCustomFilterProps = CustomFilterProps & {
  recordType: RecordTypes;
};

export const AttributeCustomFilter = ({
  model,
  onModelChange,
  recordType,
}: AttributeCustomFilterProps) => {
  const handleOnChange = useCallback(
    (selectedFilter: AttributeFilter | null) => {
      onModelChange(selectedFilter);
    },
    [onModelChange],
  );

  return (
    <CustomFilterWrapper>
      <AttributeFilterForm
        recordType={recordType}
        onChange={handleOnChange}
        filter={model}
      />
    </CustomFilterWrapper>
  );
};
