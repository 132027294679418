import type { BaseMutationOptions } from '@apollo/client';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type {
  Exact,
  IgnoreConversationMessageIssueInput,
  IgnoreConversationMessageIssueMutation,
} from '@graphql/types/graphql';

const IGNORE_CONVERSATION_MESSAGE_ISSUE = graphql(`
  mutation IgnoreConversationMessageIssue($data: IgnoreConversationMessageIssueInput!) {
    ignoreConversationMessageIssue(data: $data) {
      _id
      resendable
      ignorable
      resolved
    }
  }
`);

export const useIgnoreConversationMessageIssueMutation = () => {
  const [ignoreConversationMessageIssueMutation, { loading }] = useMutation(
    IGNORE_CONVERSATION_MESSAGE_ISSUE,
  );

  const ignoreConversationMessageIssue = (
    id: string,
    options?: BaseMutationOptions<
      IgnoreConversationMessageIssueMutation,
      Exact<{ data: IgnoreConversationMessageIssueInput }>
    >,
  ) =>
    ignoreConversationMessageIssueMutation({
      variables: {
        data: {
          id,
        },
      },
      ...options,
    });

  return {
    ignoreConversationMessageIssue,
    loading,
  };
};
