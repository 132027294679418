export const DEFAULT_CONTEXT = {
  _id: 'default',
  name: 'Global',
};

export const DEFAULT_CONTEXT_LENGTH = 1;

export const MAX_NEW_REQUESTS_COUNT = 10;

export const INITIAL_PRIMARY_REQUESTS = {
  requests: [
    {
      name: 'Request 1',
      related: DEFAULT_CONTEXT._id,
    },
  ],
};

export const INITIAL_CONTEXT_REQUESTS = {
  requests: [
    {
      name: 'Request 1',
    },
  ],
};
