import * as R from 'ramda';
import { useEffect, useState } from 'react';

import Spinner from '@common/components/Spinner';
import { getCurrentComplianceProfile } from '@modules/compliance-profile/selectors';
import {
  fetchDocumentForm,
  updateDocumentForm,
} from '@modules/document/actions';
import { getRequestsByParty } from '@modules/party/selectors';
import { getProjectById } from '@modules/project/selectors';
import { RequirementStatus } from '@modules/requirement/constants';
import { getGraphqlPayload, getNewGraphqlPayload } from '@store/helpers';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import type { Document, FillableForm } from '@graphql/types/graphql';
import {
  type FetchFillableFormRequirementsSucceessAction,
  type FillableFormRequirement,
  fetchFillableFormRequirements,
} from '../actions';
import { FillableFormRequirementsList } from '../components/FillableFormRequirementsList';
import {
  type DocumentFormToSubmit,
  FillableFormSignDrawer,
} from '../components/FillableFormSignDrawer';
import { FILLABLE_FORM_STATUS } from '../constants';

type FillableFormRequirementsListContainerProps = {
  requestId: string;
  token: string;
  filler: string;
  partyId: string;
  selectedProjectId?: string | null;
  onFillableFormRequirementsLoaded: (
    fillableFormRequirements: FillableFormRequirement[],
  ) => any;
  onDocumentFormUpdated?: () => any;
  documentFormSigner?: { name: string; email: string };
  organizationId: string;
  isReadOnly: boolean;
  canSign: boolean;
};

const FillableFormRequirementsListContainer = ({
  requestId,
  token,
  filler,
  partyId,
  selectedProjectId,
  onFillableFormRequirementsLoaded,
  onDocumentFormUpdated,
  documentFormSigner,
  organizationId,
  isReadOnly,
  canSign,
}: FillableFormRequirementsListContainerProps) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [fillableFormSignModalState, setFillableFormSignModalState] = useState<{
    visible: boolean;
    fillableForm?: Document;
    loading?: boolean;
  }>({ visible: false });

  const [fillableForms, setFillableForms] = useState<FillableForm[]>([]);

  const project = useAppSelector<any>((state) =>
    getProjectById(state, selectedProjectId),
  );
  const complianceProfile = useAppSelector<any>(getCurrentComplianceProfile);

  const partyRequests = useAppSelector((state) =>
    getRequestsByParty(state, partyId),
  );
  const latestRequest = R.last(partyRequests);

  const getFillableFormRequirements = async () => {
    setIsLoading(true);
    setFillableFormSignModalState({
      visible: false,
      loading: true,
      fillableForm: undefined,
    });

    const res = await dispatch(
      fetchFillableFormRequirements({
        partyId,
        projectId: selectedProjectId,
        filler,
      }),
    );

    const payload =
      getGraphqlPayload<FetchFillableFormRequirementsSucceessAction>(res);

    setIsLoading(false);

    if (!payload) return;

    setFillableForms(
      payload.map<FillableForm>((item) => {
        const isSigning = item?.documents?.some(
          (doc) => doc?.fillableForm?.status === FILLABLE_FORM_STATUS.SIGNING,
        );

        const isNonCompliant =
          item.requirementComplianceStatusValue ===
          RequirementStatus.NonCompliant;

        return {
          _id: item.fillableForm._id,
          name: item.fillableForm.name,
          additionalInfo: item.fillableForm.additionalInfo,
          status: isSigning
            ? 'processing'
            : isNonCompliant
              ? 'fillable'
              : 'completed',
        };
      }),
    );

    onFillableFormRequirementsLoaded &&
      onFillableFormRequirementsLoaded(payload);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    getFillableFormRequirements();
  }, [R.prop('_id', complianceProfile), selectedProjectId]);

  const getDocumentForm = async (formId: string) => {
    setFillableFormSignModalState({
      visible: true,
      loading: true,
      fillableForm: undefined,
    });

    const res = await dispatch(
      fetchDocumentForm({
        requestId,
        token,
        partyId,
        projectId: project?._id,
        fillableFormId: formId,
        organization: organizationId,
      }),
    );
    const payload = getGraphqlPayload<any>(res);
    setFillableFormSignModalState({
      visible: true,
      loading: false,
      fillableForm: payload,
    });
  };

  const handleOnUpdateDocumentForm = async (
    documentForm: DocumentFormToSubmit,
  ) => {
    // update document
    // if last signer, documentFormToSubmit contains the flattened documentFile
    const res = await dispatch(
      updateDocumentForm({
        ...documentForm,
        requestId,
        token,
        projectId: project?._id,
        filler,
      }),
    );

    const { data: updatedDocumentForm, error } = getNewGraphqlPayload<any>(res);

    if (error?.code) {
      throw new Error(`${error.code}: ${error.message}`);
    }

    if (!updatedDocumentForm) {
      throw new Error('Error when updating the document');
    }

    if (
      updatedDocumentForm.fillableForm?.status ===
      FILLABLE_FORM_STATUS.COMPLETED
    ) {
      throw new Error('Error when signing the document');
    }
  };

  const handleOnSuccess = () => {
    getFillableFormRequirements();
    onDocumentFormUpdated?.();
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <FillableFormRequirementsList
        fillableForms={fillableForms}
        canSign={canSign}
        readOnly={isReadOnly}
        onSignClick={(form) => getDocumentForm(form._id)}
        disableSign={Boolean(requestId && latestRequest?._id !== requestId)}
      />
      <FillableFormSignDrawer
        open={fillableFormSignModalState.visible}
        documentForm={fillableFormSignModalState.fillableForm}
        onClose={() => setFillableFormSignModalState({ visible: false })}
        filler={filler}
        loading={fillableFormSignModalState.loading!}
        signer={documentFormSigner}
        onUpdateDocumentForm={handleOnUpdateDocumentForm}
        onSuccess={handleOnSuccess}
      />
    </>
  );
};

export default FillableFormRequirementsListContainer;
