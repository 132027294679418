import { gql } from '@apollo/client';
import { Modal, message } from 'antd';
import pluralize from 'pluralize';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useQuery } from '@graphql/hooks';
import { getCurrentProject } from '@modules/project/selectors';
import { bulkMatchDocumentsRequirements } from '@modules/requirement/actions';

import Spinner from '@common/components/Spinner';
import BulkMatchRequirements from '../components/BulkMatchRequirements';
import { getTargetValueByRequirement } from '../utils/document-helpers';

const DOCUMENTS_REQUIREMENTS_QUERY = gql`
  query DocumentsRequirements(
    $filter: Any
    $sort: Any
    $skip: NonNegativeInt
    $limit: NonNegativeInt
  ) {
    listDocuments(filter: $filter, sort: $sort, skip: $skip, limit: $limit) {
      _id
      party {
        _id
        name
        partyComplianceProfile {
          name
          complianceProfile
        }
        requirements {
          moduleId
          moduleLabel
          subjectId
          subjectLabel
          attributeId
          attributeLabel
          attributeType
          operator
          targetValue
          masterDocumentAttributeId
        }
      }
    }
  }
`;

const BulkMatchRequirementsModal = ({
  visible,
  onCancel,
  onSuccess,
  documentsIds,
  selectedDocumentsCount,
  skipDocumentsFetch = true,
}) => {
  const [checkedRequirements, setCheckedRequirements] = useState({});
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (!visible) {
      setCheckedRequirements({});
      setUpdating(false);
    }
  }, [visible]);

  const { data: fetchedData, loading } = useQuery(
    DOCUMENTS_REQUIREMENTS_QUERY,
    {
      variables: {
        filter: {
          ids: documentsIds,
        },
      },
      skip: !visible,
    },
  );

  const documentsWithParty = R.filter(
    (document) =>
      R.path(
        ['party', 'partyComplianceProfile', 'complianceProfile'],
        document,
      ),
    fetchedData?.listDocuments ?? [],
  );
  const project = useSelector(getCurrentProject);
  const customFields = project?.customFields?.nodes ?? [];

  const dispatch = useDispatch();

  const complianceProfiles = R.compose(
    R.map((complianceProfile) => {
      const associatedRequirementsWithCustomFields = R.compose(
        R.map((requirement) => {
          const associatedCustomFields = R.filter((x) => {
            const rules = R.pathOr(
              [],
              ['customField', 'complianceProfile', 'rules'],
              x,
            );

            return R.find(
              R.propEq('attributeId', requirement.attributeId),
              rules,
            );
          }, customFields);

          return R.isEmpty(associatedCustomFields)
            ? requirement
            : R.assoc('customFields', associatedCustomFields, requirement);
        }),
        R.propOr([], 'requirements'),
      )(complianceProfile);

      return R.assoc(
        'requirements',
        associatedRequirementsWithCustomFields,
        complianceProfile,
      );
    }),
    R.uniq,
    R.filter((x) => x.name && x.requirements),
    R.map((x) => ({
      _id: R.path(['party', 'partyComplianceProfile', 'complianceProfile'], x),
      name: R.path(['party', 'partyComplianceProfile', 'name'], x),
      requirements: R.map(
        R.pick([
          'moduleId',
          'moduleLabel',
          'subjectId',
          'subjectLabel',
          'attributeId',
          'attributeLabel',
          'attributeType',
          'operator',
          'targetValue',
        ]),
        R.path(['party', 'requirements'], x),
      ),
    })),
  )(documentsWithParty);

  //TODO move this logic into the backend
  const getMatchRequirementsData = () => {
    const complianceProfilesIds = R.keys(checkedRequirements);

    const matchRequirementsData = R.reduce(
      (res, complianceProfileId) => {
        const treeKeys = R.propOr([], complianceProfileId, checkedRequirements);

        const currentComplianceProfileData = R.compose(
          R.map((document) => ({
            documentId: document._id,
            data: R.compose(
              R.reduce(
                (res, requirement) =>
                  R.assoc(
                    requirement.masterDocumentAttributeId,
                    getTargetValueByRequirement(
                      requirement,
                      requirement.targetValue,
                    ),
                    res,
                  ),
                {},
              ),
              R.filter((requirement) =>
                R.any(
                  (treeKey) => treeKey === requirement.attributeId,
                  treeKeys,
                ),
              ),
              R.pathOr([], ['party', 'requirements']),
            )(document),
          })),
          R.filter((document) =>
            R.equals(
              complianceProfileId,
              R.path(
                ['party', 'partyComplianceProfile', 'complianceProfile'],
                document,
              ),
            ),
          ),
        )(documentsWithParty);

        return R.concat(res, currentComplianceProfileData);
      },
      [],
      complianceProfilesIds,
    );

    return matchRequirementsData;
  };

  const handleOk = async () => {
    setUpdating(true);

    await dispatch(
      bulkMatchDocumentsRequirements({
        matchRequirementsData: getMatchRequirementsData(),
      }),
    );

    message.success(
      `${pluralize(
        'document',
        selectedDocumentsCount,
        true,
      )} scheduled to match requirements`,
    );
    setUpdating(false);
    onSuccess();
  };

  const title = `Bulk match requirements on ${pluralize(
    'document',
    selectedDocumentsCount,
    true,
  )}`;

  return (
    <Modal
      title={title}
      open={visible}
      onOk={handleOk}
      okText="Bulk match requirements"
      okButtonProps={{
        loading: updating,
        disabled: R.isEmpty(checkedRequirements),
        'data-cy': 'bulkMatchRequirementsModalButton',
      }}
      onCancel={onCancel}
      width={750}
    >
      {loading ? (
        <Spinner />
      ) : (
        <BulkMatchRequirements
          complianceProfiles={complianceProfiles}
          checkedRequirements={checkedRequirements}
          setCheckedRequirements={setCheckedRequirements}
        />
      )}
    </Modal>
  );
};

export default BulkMatchRequirementsModal;
