import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

import { FILTER_OPERATORS } from '@common/constants/filters';
import { useMarkConversationMessagesAsReadMutation } from '@modules/conversation/hooks/useMarkConversationMessagesAsReadMutation';
import { useConversationUpdatesSubscription } from '@modules/primary-records/hooks/useConversationUpdatesSubscription';
import { useCallback, useMemo } from 'react';
import {
  getMessagesGroupByDateMap,
  normalizeMessagesList,
} from '../Messages.utils';

export const MESSAGES_QUERY = graphql(`
  query ConversationMessages(
    $conversationInput: ConversationInput!
    $input: ConversationMessagesInput
  ) {
    conversation(input: $conversationInput) {
      _id
      messages(input: $input) {
        totalCount
        nodes {
          _id
          request
          sendgridMessageId
          type
          outputHtml
          status
          message
          createdAt
          senderType
          mostRecentUniqueSendgridEvents {
            _id
            status
            date
            email
          }
          updatedAt
          attachments {
            totalCount
            nodes {
              _id
              s3Key
              url
              friendlyName
            }
          }
          recipients
          senderContact {
            _id
            contactPersonName
            email
            avatar
          }
          senderUser {
            _id
            email
            profile {
              name
              avatar
            }
          }
          issues {
            nodes {
              _id
              email
              resendable
              ignorable
              resolved
              contact {
                _id
              }
            }
          }
          data {
            ... on MessageResent {
              partyContact {
                _id
                email
              }
            }
            ... on ContactDeleted {
              partyContact {
                _id
                email
              }
            }
            ... on ContactRemovedFromConversation {
              partyContact {
                _id
                email
              }
            }
            ... on ContactEmailUpdatedResent {
              partyContactAfterUpdate {
                _id
                email
              }
            }
          }
        }
      }
    }
  }
`);

/**
 * @note keeping the current legacy behavior until the InfiniteScroll implementation
 */
const DEFAULT_PAGE_SIZE = 500;

const DEFAULT_INPUT = {
  first: DEFAULT_PAGE_SIZE,
  sort: {
    createdAt: -1,
  },
};

export const useMessages = ({
  primaryRecordId,
  conversationId,
}: { primaryRecordId: string; conversationId: string }) => {
  const { data, loading, refetch } = useQuery(MESSAGES_QUERY, {
    variables: {
      conversationInput: { id: conversationId },
      input: DEFAULT_INPUT,
    },
  });

  const { markConversationMessagesAsRead } =
    useMarkConversationMessagesAsReadMutation();

  const nodes = data?.conversation?.messages?.nodes || [];
  const messages = useMemo(
    () =>
      normalizeMessagesList(
        // @ts-expect-error message.data is not typed properly
        nodes,
      ),
    [nodes],
  );

  const messagesByDateMap = useMemo(
    () => getMessagesGroupByDateMap(messages),
    [messages],
  );

  useConversationUpdatesSubscription({
    partyId: primaryRecordId,
    onData: () => refetch(),
  });

  const handleMarkConversationMessagesAsRead = useCallback(
    async (conversationId: string) => {
      try {
        await markConversationMessagesAsRead({
          and: [
            {
              name: 'conversationId',
              operator: FILTER_OPERATORS.equal,
              value: conversationId,
            },
          ],
        });
        refetch();
      } catch (err) {}
    },
    [markConversationMessagesAsRead, refetch],
  );

  return {
    messages,
    messagesByDateMap,
    loading,
    refetch,
    markConversationMessagesAsRead: handleMarkConversationMessagesAsRead,
  };
};
