import { message as notify } from 'antd';
import * as R from 'ramda';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import usePrevious from '@common/hooks/usePrevious';
import useSearchParams from '@common/hooks/useSearchParams';
import { downloadDocumentFile } from '@modules/document/utils/document-helpers';
import PartyEditContactModal from '@modules/party-contact/components/ResolveMessageIssueModal';
import { getParty } from '@modules/party/selectors';

import { processDocumentUrl } from '../../document/actions';
import { ignorePartyMessageIssue, updateMessage } from '../actions';
import { ConversationEvent } from '../components/ConversationEvent';
import type { ConversationEvent as ConversationEventType } from '../components/MessagesList/MessagesList';

type PartyMessageProp = ConversationEventType;

type MessageContainerProps = {
  conversationRequest: string;
  partyMessage: PartyMessageProp;
  selectedConversationData: any;
};

const MessageContainer = ({
  partyMessage,
  conversationRequest,
  selectedConversationData,
}: MessageContainerProps) => {
  const [contactToEdit, setContactToEdit] = useState<{
    contactId?: string;
    issueId?: string;
  }>({});
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const { partyId } = useParams<Record<string, string | undefined>>();
  const { searchParams, replaceParams } = useSearchParams();

  const partyData: any = useSelector((state) => getParty(state, partyId));
  const [inViewRef, inView] = useInView();
  const prevInView = usePrevious(inView);

  const isUpdated = useRef(false);
  const ref = useRef<HTMLDivElement>();

  const dispatch = useDispatch();

  const partyContacts = partyData?.partyContacts || [];
  const partyContactsById = R.indexBy(
    R.prop<'_id', string>('_id'),
    partyContacts,
  );
  const senderContactData = partyContacts.find(
    (contact: any) => contact._id === partyMessage.senderContactId,
  );

  const senderData = {
    name: Boolean(partyMessage.senderContactId)
      ? senderContactData?.contactPersonName
      : partyMessage.senderUser?.profile?.name,
    email: Boolean(partyMessage.senderContactId)
      ? senderContactData?.email
      : partyMessage.senderUser?.email,
    avatar: Boolean(partyMessage.senderContactId)
      ? senderContactData?.avatar
      : partyMessage.senderUser?.profile?.avatar,
  };

  const isSelectedMessage = searchParams.messageId === partyMessage._id;

  useEffect(() => {
    if (inView && !Boolean(partyMessage.readAt) && !isUpdated.current) {
      dispatch(
        updateMessage({
          messageId: partyMessage._id,
          readAt: new Date(),
        }),
      );
      isUpdated.current = true;
    }
  }, [dispatch, inView, partyMessage]);

  useEffect(() => {
    if (!isSelectedMessage) return;

    ref.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }, [isSelectedMessage]);

  useEffect(() => {
    if (!isSelectedMessage) return;

    if (!inView && prevInView) {
      replaceParams({
        messageId: null,
      });
    }
  }, [inView, isSelectedMessage, replaceParams, prevInView]);

  function handleIgnoreIssue(issueId: string) {
    dispatch(ignorePartyMessageIssue(issueId));
  }

  function handleResolveIssue(contactId: string, issueId: string) {
    setContactToEdit({ contactId, issueId });
    setIsEditModalVisible(true);
  }

  const onSaveInDocuments = async (attachmentData: {
    url: string;
    name: string;
  }) => {
    const res = await dispatch(
      processDocumentUrl({ ...attachmentData, party: partyId }),
    );
    // @ts-expect-error - res doesn't infer the correct type
    if (res.error) {
      // @ts-expect-error - res doesn't infer the correct type
      notify.error(res.error[0]?.message);
      return;
    }
    notify.success('Document was saved in documents library');
  };

  /**
   * This function initializes both "inViewRef" and an internal "ref" properly
   * since "react-intersection-observer" exposes "inViewRef" as function
   * and scroll is a method of an HTMLElement, we need both of them.
   *
   * see "react-intersection-observer" FAQ @link https://www.npmjs.com/package/react-intersection-observer
   */
  const setRefs = useCallback(
    (node: HTMLDivElement) => {
      ref.current = node;
      inViewRef(node);
    },
    [inViewRef],
  );

  return (
    <div ref={setRefs}>
      <PartyEditContactModal
        visible={isEditModalVisible}
        onClose={() => {
          setIsEditModalVisible(false);
          setContactToEdit({});
        }}
        partyName={partyData?.name}
        contact={partyContactsById[contactToEdit.contactId ?? '']}
        issueId={contactToEdit.issueId}
        selectedConversation={selectedConversationData}
      />
      <ConversationEvent
        conversationRequest={conversationRequest}
        conversationEvent={partyMessage}
        senderData={senderData}
        onSaveInDocuments={onSaveInDocuments}
        onDownloadAttachment={downloadDocumentFile}
        onResolveIssue={handleResolveIssue}
        onIgnoreIssue={handleIgnoreIssue}
        onRemoveContact={undefined}
      />
    </div>
  );
};

export default MessageContainer;
