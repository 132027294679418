import {
  ExportOutlined,
  FormOutlined,
  MailOutlined,
  MoreOutlined,
  SendOutlined,
  ThunderboltOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Breadcrumb, Button, Collapse, Divider, Dropdown } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ArchivedBanner } from '@common/components/ArchivedBanner';
import { CollapseHeader } from '@common/components/CollapseHeader';
import { ComplianceStats } from '@common/components/ComplianceStats';
import { PageTitle } from '@common/components/PageTitleWrapper';
import { TabButtons } from '@common/components/TabButtons';
import { getAntdIconOutlinedByName } from '@common/utils/get-antd-icon-by-name';
import {
  PRIMARY_RECORDS_TABS,
  SidebarAutomations,
  SidebarEmailActivities,
  SidebarNotes,
} from '@modules/primary-records';
import { ArchivePrimaryRecordModal } from '@modules/primary-records/containers/ArchivePrimaryRecordModal';
import { AttributesSidebar } from '@modules/primary-records/containers/AttributesSidebar';
import { DeletePrimaryRecordModal } from '@modules/primary-records/containers/DeletePrimaryRecordModal';
import { MakeActivePrimaryRecordModal } from '@modules/primary-records/containers/MakeActivePrimaryRecordModal';
import { SendRequestDrawerContainer } from '@modules/primary-records/containers/SendRequestDrawerContainer';
import { getCurrentProject } from '@modules/project/selectors';
import type { PrimaryRecordsRoutesParams } from '@modules/router/types';
import { PrimaryRecordStatus } from '@trustlayer/common';
import { DetailsPageLayout } from 'layouts';
import {
  usePrimaryObjectGenerality,
  usePrimaryRecordGenerality,
} from './hooks';

enum ACTIONS_ITEMS_KEYS {
  EDIT = 'edit',
  ARCHIVE = 'archive',
  DELETE = 'delete',
  MAKE_ACTIVE = 'make_active',
}

enum SIDEBAR_PANELS_KEYS {
  ATTRIBUTES = 'attributes',
  AUTOMATIONS = 'automations',
  EMAIL_ACTIVITY = 'emailActivity',
  NOTES = 'additionalNotes',
}

export const PrimaryRecordDetail = () => {
  const {
    organizationSlugifyName,
    objectSlug,
    recordId = '',
  } = useParams<PrimaryRecordsRoutesParams>();
  const { pathname } = useLocation();
  const tab = pathname.split('/')[5];

  const navigate = useNavigate();

  const { primaryRecord, refetchPrimaryRecord } = usePrimaryRecordGenerality({
    recordId,
  });
  const { primaryObject } = usePrimaryObjectGenerality({
    objectSlug,
  });

  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [makeActiveModalOpen, setMakeActiveModalOpen] = useState(false);
  const [sendRequestDrawerOpen, setSendRequestDrawerOpen] = useState(false);
  const activeTab =
    Object.values(PRIMARY_RECORDS_TABS).find((t) => t.key === tab)?.key ?? '';

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    activeTab === PRIMARY_RECORDS_TABS.Messages.key,
  );
  const [activeSidebarPanelKeys, setActiveSidebarPanelKeys] = useState<
    string | string[]
  >([SIDEBAR_PANELS_KEYS.NOTES, SIDEBAR_PANELS_KEYS.ATTRIBUTES]);

  // @ts-expect-error - useSelecter should update types
  const currentProjectId = useSelector(getCurrentProject)._id;

  const handleClose = () => {
    navigate(`/${organizationSlugifyName}/p/${objectSlug}`);
  };

  const handleEdit = () => {
    navigate(
      `/${organizationSlugifyName}/p/${objectSlug}/${primaryRecord?._id}/attributes`,
    );
  };

  const handleDeleted = () => {
    setDeleteModalOpen(false);
    navigate(`/${organizationSlugifyName}/p/${objectSlug}`);
  };

  const handleArchive = () => {
    setArchiveModalOpen(false);
    refetchPrimaryRecord();
  };

  const handleMakeActiveCompleted = () => {
    setMakeActiveModalOpen(false);
    refetchPrimaryRecord();
  };

  const handleSidebarCollapse = (collapsed: boolean) => {
    if (collapsed) setActiveSidebarPanelKeys([]);
    setIsSidebarCollapsed(collapsed);
  };

  return (
    <DetailsPageLayout>
      <DeletePrimaryRecordModal
        open={deleteModalOpen}
        primaryRecord={{
          _id: primaryRecord?._id,
          name: primaryRecord?.name,
        }}
        onCancel={() => setDeleteModalOpen(false)}
        onCompleted={handleDeleted}
        onScheduled={handleDeleted}
      />
      <ArchivePrimaryRecordModal
        open={archiveModalOpen}
        primaryRecordId={primaryRecord?._id}
        onCancel={() => setArchiveModalOpen(false)}
        onCompleted={handleArchive}
      />
      <MakeActivePrimaryRecordModal
        open={makeActiveModalOpen}
        primaryRecordId={primaryRecord?._id}
        onCancel={() => setMakeActiveModalOpen(false)}
        onCompleted={handleMakeActiveCompleted}
        onScheduled={handleMakeActiveCompleted}
        onFailed={() => setMakeActiveModalOpen(false)}
      />
      <SendRequestDrawerContainer
        open={sendRequestDrawerOpen}
        primaryRecordId={recordId}
        onCancel={() => setSendRequestDrawerOpen(false)}
      />

      {/* TOP CONTENT */}
      <DetailsPageLayout.TopBar>
        <DetailsPageLayout.PageActions onClose={handleClose} />
        <Breadcrumb>
          <Breadcrumb.Item>
            <StyledObjectName>{objectSlug}</StyledObjectName>
          </Breadcrumb.Item>
        </Breadcrumb>
      </DetailsPageLayout.TopBar>

      {/* MAIN CONTENT */}
      <DetailsPageLayout.MainContent>
        {primaryRecord?.status === PrimaryRecordStatus.Archived && (
          <ArchivedBanner>
            {`This ${primaryObject?.name} is archived`}
          </ArchivedBanner>
        )}
        <DetailsPageLayout.Header>
          <StyledTopLeftActions>
            {/*//! make the icon dynamic */}
            <PageTitle
              title={primaryRecord?.name}
              icon={getAntdIconOutlinedByName(primaryObject?.icon)}
            />
            <Divider type="vertical" />
            <ComplianceStats
              compliantCount={
                primaryRecord?.stats.requestRecords.compliantCount || 0
              }
              totalCount={primaryRecord?.stats.requestRecords.totalCount || 0}
            />
          </StyledTopLeftActions>

          <StyledBottomLeftActions>
            <TabButtons
              tabs={PRIMARY_RECORDS_TABS}
              activeTab={activeTab}
              onClick={(tabKey) => {
                if (tabKey === PRIMARY_RECORDS_TABS.Messages.key) {
                  setIsSidebarCollapsed(true);
                  // force sidebar open panels to close
                  setActiveSidebarPanelKeys([]);
                }

                navigate(`./${tabKey}`, {
                  relative: 'path',
                });
              }}
            />
          </StyledBottomLeftActions>

          <StyledBottomRightActions>
            <Button
              type="primary"
              icon={<SendOutlined />}
              onClick={() => setSendRequestDrawerOpen(true)}
            >
              Send Email
            </Button>
            <Button
              icon={<ExportOutlined />}
              href={`${import.meta.env.VITE_PORTAL_URL}/p/${recordId}`}
              target="_blank"
            />
            <Dropdown
              menu={{
                items: [
                  {
                    key: ACTIONS_ITEMS_KEYS.EDIT,
                    label: 'Edit',
                    onClick: handleEdit,
                  },
                  {
                    key: ACTIONS_ITEMS_KEYS.ARCHIVE,
                    label: 'Archive',
                    style: {
                      display:
                        primaryRecord?.status === PrimaryRecordStatus.Archived
                          ? 'none'
                          : 'block',
                    },
                    onClick: () => setArchiveModalOpen(true),
                  },
                  {
                    key: ACTIONS_ITEMS_KEYS.MAKE_ACTIVE,
                    label: 'Make Active',
                    style: {
                      display:
                        primaryRecord?.status === PrimaryRecordStatus.Active
                          ? 'none'
                          : 'block',
                    },
                    onClick: () => setMakeActiveModalOpen(true),
                  },
                  {
                    key: ACTIONS_ITEMS_KEYS.DELETE,
                    label: 'Delete',
                    danger: true,
                    onClick: () => setDeleteModalOpen(true),
                  },
                ],
              }}
            >
              <Button icon={<MoreOutlined />} />
            </Dropdown>
          </StyledBottomRightActions>
        </DetailsPageLayout.Header>
        <DetailsPageLayout.Content>
          <DetailsPageLayout.Body>
            <Outlet context={{ primaryRecord }} />
          </DetailsPageLayout.Body>
          <DetailsPageLayout.Sidebar
            collapsed={isSidebarCollapsed}
            onCollapse={handleSidebarCollapse}
            resizeStorageKey="primaryRecordSidebarWidth"
          >
            <>
              <Collapse
                bordered={false}
                expandIconPosition="end"
                activeKey={activeSidebarPanelKeys}
                expandIcon={isSidebarCollapsed ? () => null : undefined}
                onChange={(data) => {
                  setIsSidebarCollapsed(false);
                  setActiveSidebarPanelKeys(data);
                }}
              >
                <Collapse.Panel
                  header={
                    <CollapseHeader
                      icon={<FormOutlined />}
                      label={isSidebarCollapsed ? '' : 'Additional Notes'}
                    />
                  }
                  key={SIDEBAR_PANELS_KEYS.NOTES}
                >
                  <SidebarNotes recordId={recordId} />
                </Collapse.Panel>
                <Collapse.Panel
                  header={
                    <CollapseHeader
                      icon={<UnorderedListOutlined />}
                      label={isSidebarCollapsed ? '' : 'Attributes'}
                    />
                  }
                  key={SIDEBAR_PANELS_KEYS.ATTRIBUTES}
                >
                  <AttributesSidebar recordId={recordId} />
                </Collapse.Panel>
                <Collapse.Panel
                  header={
                    <CollapseHeader
                      icon={<ThunderboltOutlined />}
                      label={isSidebarCollapsed ? '' : 'Automations'}
                    />
                  }
                  key={SIDEBAR_PANELS_KEYS.AUTOMATIONS}
                >
                  <SidebarAutomations
                    recordId={recordId}
                    disabled={
                      primaryRecord?.status === PrimaryRecordStatus.Archived
                    }
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  header={
                    <CollapseHeader
                      icon={<MailOutlined />}
                      label={isSidebarCollapsed ? '' : 'Email Activity'}
                    />
                  }
                  key={SIDEBAR_PANELS_KEYS.EMAIL_ACTIVITY}
                >
                  <SidebarEmailActivities recordId={recordId} />
                </Collapse.Panel>
              </Collapse>
            </>
          </DetailsPageLayout.Sidebar>
        </DetailsPageLayout.Content>
      </DetailsPageLayout.MainContent>
    </DetailsPageLayout>
  );
};

const StyledObjectName = styled.span`
  text-transform: capitalize;
  display: flex;
  gap: 5px;
  flex-direction: row;
  align-items: center;
`;

const StyledTopLeftActions = styled.div.attrs({
  className: 'top-left-actions',
})`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledBottomLeftActions = styled.div.attrs({
  className: 'bottom-left-actions',
})`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledBottomRightActions = styled.div.attrs({
  className: 'bottom-right-actions',
})`
  display: flex;
  align-items: center;
  gap: 10px;
`;
