import { Tag, Typography } from 'antd';

import type { AttributeOption, RecordAttribute } from '@graphql/types/graphql';
import { ATTRIBUTE_TYPES } from '../constants';
import { formatValue } from '../utils/formatValue';
import { isAttributeValueEmpty } from '../utils/isAttributeValueEmpty';

export const AttributeValueRenderer = ({
  attribute,
}: {
  attribute: RecordAttribute;
}) => {
  if (isAttributeValueEmpty(attribute)) {
    return <Typography.Text type="secondary">Not Set</Typography.Text>;
  }

  const formattedValue = formatValue(attribute);

  if (attribute.type === ATTRIBUTE_TYPES.MultiSelect) {
    return formattedValue.map((item: AttributeOption) => (
      <Tag key={item?._id}>{item?.value}</Tag>
    ));
  }

  return <span>{formattedValue}</span>;
};
