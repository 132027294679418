import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const REMOVE_PRIMARY_RECORD_CONTACTS = graphql(`
  mutation RemovePrimaryRecordContacts($data: [RemoveContactInput!]!) {
    removeContacts(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useRemovePrimaryRecordContactsMutation = ({
  primaryRecordId,
}: {
  primaryRecordId: string;
}) => {
  const [removeContacts, { loading }] = useMutation(
    REMOVE_PRIMARY_RECORD_CONTACTS,
  );

  const handleRemovePrimaryRecordContacts = (contactIds: string[]) => {
    return removeContacts({
      variables: {
        data: contactIds.map((contactId) => ({
          id: contactId,
          primaryRecords: [primaryRecordId],
        })),
      },
    });
  };

  return {
    removePrimaryRecordContacts: handleRemovePrimaryRecordContacts,
    loading,
  };
};
