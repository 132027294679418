import { EditOutlined } from '@ant-design/icons';
import RequirementCard from '@modules/requirement/components/RequirementCard';
import { Modal } from 'antd';

import type { FillableForm } from '@graphql/types/graphql';
import { FillableFormRequirementAction } from './FillableFormRequirementAction';

type FillableFormRequirementsListProps = {
  readOnly?: boolean;
  canSign: boolean;
  fillableForms: FillableForm[];
  onSignClick: (fillableForm: FillableForm) => void;
  disableSign?: boolean;
};

export const FillableFormRequirementsList = ({
  readOnly,
  canSign,
  fillableForms,
  onSignClick,
  disableSign,
}: FillableFormRequirementsListProps) => {
  const handleOnFillClick = (fillableForm: FillableForm) => {
    if (readOnly) {
      Modal.error({
        title: 'Access restricted',
        content: (
          <div>
            <p>
              To protect your privacy, this document can be viewed and completed
              only by accessing from the authorized link in the email we sent
              you.
            </p>
            <p>
              Please click the link in the email to enable your ability to view
              and complete this document.
            </p>
          </div>
        ),
      });
      return;
    }

    if (!canSign) {
      Modal.error({
        title: 'Not allowed',
        content: (
          <div>
            <p>
              You are not able to sign a forwarded request. Please contact the
              requester if you need to sign.
            </p>
          </div>
        ),
      });
      return;
    }

    onSignClick(fillableForm);
  };

  return (
    <div>
      {fillableForms.map((fillableForm) => (
        <RequirementCard
          key={fillableForm._id}
          icon={EditOutlined}
          title={fillableForm?.name}
          description={fillableForm?.additionalInfo}
          data-cy="fillableFormRequirementCard"
          action={
            <FillableFormRequirementAction
              fillableForm={fillableForm}
              onFillClick={() => handleOnFillClick(fillableForm)}
              disableSign={disableSign}
            />
          }
        />
      ))}
    </div>
  );
};
