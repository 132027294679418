import { useDispatch } from 'react-redux';

import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { FETCH_ORGANIZATION_BY_ID } from '@modules/organization/actions';

const REQUEST_PAGE_QUERY = graphql(`
  query RequestPage($id: ObjectId!) {
    requestPage(id: $id) {
      id
      partyId
      organization {
        id
        name
        logo
        website
        features
        primaryContactEmail
        primaryContactName
        primaryContactPhone
      }
    }
  }
`);

export const useRequestPage = (requestId?: string) => {
  const dispatch = useDispatch();
  const { data, loading } = useQuery(REQUEST_PAGE_QUERY, {
    variables: { id: requestId },
    skip: !requestId,
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      if (!data.requestPage?.organization) return;
      dispatch({
        type: `${FETCH_ORGANIZATION_BY_ID}_SUCCESS`,
        payload: data.requestPage.organization,
      });
    },
  });

  return {
    id: data?.requestPage?.id,
    partyId: data?.requestPage?.partyId,
    organization: data?.requestPage?.organization,
    loading,
  };
};
