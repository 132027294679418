export const CONTEXT_RECORDS_TABS = {
  Requests: {
    name: 'Requests',
    key: 'requests',
  },
  Attributes: {
    name: 'Attributes',
    key: 'attributes',
  },
  Attachments: {
    name: 'Attachments',
    key: 'attachments',
  },
} as const;

export const CONTEXT_RECORDS_ACTION_TYPES = {
  archive: 'archive',
  delete: 'delete',
  makeActive: 'makeActive',
} as const;

export const CONTEXT_RECORDS_STATUS = {
  active: 'active',
  archived: 'archived',
} as const;

export type ActionType =
  (typeof CONTEXT_RECORDS_ACTION_TYPES)[keyof typeof CONTEXT_RECORDS_ACTION_TYPES];

export {
  ContextRecordStatus as CONTEXT_RECORD_STATUS,
  ContextRecordCompliance as CONTEXT_RECORD_COMPLIANCE,
} from '@trustlayer/common';

export const CONTEXT_RECORDS_ASYNC_OPERATIONS_NAMES = {
  createContextRecords: 'mutation createContextRecords',
  deleteContextRecords: 'mutation deleteContextRecords',
  archiveContextRecords: 'mutation archiveContextRecords',
  makeActiveContextRecords: 'mutation activateContextRecords',
} as const;

export type CONTEXT_RECORDS_ASYNC_OPERATIONS_NAMES =
  (typeof CONTEXT_RECORDS_ASYNC_OPERATIONS_NAMES)[keyof typeof CONTEXT_RECORDS_ASYNC_OPERATIONS_NAMES];
