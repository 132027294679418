import { ClockCircleOutlined } from '@ant-design/icons';
import { Skeleton, Tag, Tooltip } from 'antd';
import { SidebarLoadingError } from 'layouts';
import moment from 'moment';
import styled from 'styled-components';

import { AttributesGrid } from '@modules/attributes/components/AttributesGrid';
import { PRIMARY_RECORDS_TABS } from '@modules/primary-records/constants';
import { Link } from 'react-router-dom';
import { useAttributesSidebar } from './hooks/useAttributesSidebar';

export const AttributesSidebar = ({ recordId }: { recordId: string }) => {
  const {
    attributes,
    tags,
    loading,
    error,
    refetchAttributes,
    attributesTotalCount,
  } = useAttributesSidebar({
    primaryRecordId: recordId,
  });

  if (error) {
    return (
      <SidebarLoadingError
        loadingActionLabel="Attributes"
        onActionClick={() => refetchAttributes()}
      />
    );
  }

  /**
   * @info - in the future tags should be exposed as a custom attribute so inside the attributes nodes
   */
  return (
    <Skeleton active loading={loading}>
      <StyledTagsWrapper>
        <p className="name">Tags</p>
        <div className="tags-wrapper">
          {tags?.map((tag) => (
            <StyledTag
              key={tag._id}
              icon={
                Boolean(tag.expiresAt) && (
                  <Tooltip
                    title={`Tag expires on ${moment(tag.expiresAt).format(
                      'll',
                    )}`}
                  >
                    <ClockCircleOutlined />
                  </Tooltip>
                )
              }
              color={tag.color || 'default'}
            >
              {tag.name}
            </StyledTag>
          ))}
        </div>
      </StyledTagsWrapper>
      <AttributesGrid attributes={attributes} />
      {attributes.length < attributesTotalCount && (
        <StyledLink to={`./${PRIMARY_RECORDS_TABS.Attributes.key}`}>
          Show more
        </StyledLink>
      )}
    </Skeleton>
  );
};

const StyledTag = styled(Tag)`
  margin: 0;
  border-radius: 99em;
`;

const StyledTagsWrapper = styled.div`
  margin-bottom: 16px;

  .name {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grayText};
  }

  .tags-wrapper {
    display: flex;
    gap: 8px;
    margin-top: 8px;
    flex-wrap: wrap;
  }
`;

const StyledLink = styled(Link)`
  display: block;
  margin-top: 10px;
`;
