import {
  CheckCircleOutlined,
  EditOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Button, Tag } from 'antd';

import type { FillableForm } from '@graphql/types/graphql';

type FillableFormRequirementsListProps = {
  fillableForm: FillableForm;
  onFillClick?: () => void;
  disableSign?: boolean;
};

export const FillableFormRequirementAction = ({
  fillableForm,
  onFillClick,
  disableSign,
}: FillableFormRequirementsListProps) => {
  if (fillableForm.status === 'fillable') {
    return (
      <Button
        type="primary"
        data-cy="fillableFormRequirementCardFillButton"
        onClick={onFillClick}
        disabled={disableSign}
      >
        <EditOutlined />
        Fill & Sign
      </Button>
    );
  }

  if (fillableForm.status === 'processing') {
    return (
      <Tag icon={<SyncOutlined />} color="processing">
        Compiling Document
      </Tag>
    );
  }

  return (
    <Tag
      icon={<CheckCircleOutlined />}
      color="success"
      data-cy="fillableFormDocumentFormCompletedBadge"
    >
      Completed
    </Tag>
  );
};
