import { FILTER_OPERATORS, FILTER_TYPES } from '@common/constants/filters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const DISABLE_PRIMARY_RECORD_AUTOMATION_MUTATION = graphql(`
  mutation DisablePrimaryRecordAutomation($data: PrimaryRecordsAutomationInput!) {
    disablePrimaryRecordsAutomation(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useDisableAutomationMutation = () => {
  const [disablePrimaryRecordAutomation, { loading: isDisablingAutomation }] =
    useMutation(DISABLE_PRIMARY_RECORD_AUTOMATION_MUTATION);

  const disableAutomation = (id: string, automationId: string) => {
    return disablePrimaryRecordAutomation({
      variables: {
        data: {
          automationId,
          filter: {
            and: [
              {
                name: FILTER_TYPES.id,
                operator: FILTER_OPERATORS.in,
                value: [id],
              },
            ],
          },
        },
      },
    });
  };

  return {
    disableAutomation,
    isDisablingAutomation,
  };
};
