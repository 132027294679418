import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const REQUEST_RECORD_DETAILS_QUERY = graphql(`
query RequestRecordDetails($input: RequestRecordInput!) {
  requestRecord(input: $input) {
    _id
    name
    status
    contextRecord {
      _id
      name
      status
      requestRecords {
        totalCount
      }
    }
    primaryRecord {
      _id
      name
      status
      requestRecords {
        totalCount
      }
    }
  }
}
`);

export const useRequestRecordDetails = (requestId?: string) => {
  const { data, loading, error, refetch } = useQuery(
    REQUEST_RECORD_DETAILS_QUERY,
    {
      variables: { input: { id: requestId } },
      skip: !requestId,
    },
  );

  return {
    requestRecord: data?.requestRecord,
    refetchRequestRecord: refetch,
    loading,
    error,
  };
};
