import Preloader from '@common/components/Preloader';
import { useRequestPage } from '@modules/request/hooks/useRequestPage';
import { FeatureToggles } from '@paralleldrive/react-feature-toggles';
import { Navigate, Outlet, useParams } from 'react-router-dom';

const RequestLandingGuardLayout = () => {
  const { requestId } = useParams();
  const { organization, loading } = useRequestPage(requestId);

  if (!requestId) {
    return <Navigate to="/not-found" replace />;
  }

  if (loading) {
    return <Preloader />;
  }

  if (!organization) {
    return <Navigate to="/not-found" replace />;
  }

  return (
    <FeatureToggles features={organization.features || []}>
      <Outlet />
    </FeatureToggles>
  );
};

export default RequestLandingGuardLayout;
