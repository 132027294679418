import type { ContextRecord } from '@graphql/types/graphql';
import { RequestsTable } from '@modules/request-records/containers/RequestsTable';
import type { RequestRecordsRoutesParams } from '@modules/router/types';
import { RECORD_TYPES } from '@trustlayer/common';
import { useOutletContext, useParams } from 'react-router-dom';

export const ContextRecordRequests = () => {
  const { recordId } = useParams<RequestRecordsRoutesParams>();
  const { contextRecord } = useOutletContext<{
    contextRecord?: ContextRecord;
  }>();

  return (
    <RequestsTable
      recordId={recordId!}
      source={RECORD_TYPES.Context}
      relatedRecordStatus={contextRecord?.status}
    />
  );
};
