import { BulkOperationStatus } from '@graphql/types/graphql';
import { Input, Modal, message } from 'antd';
import capitalize from 'lodash/capitalize';

import { type ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { useCreateContextRecordsMutation } from '../../hooks/useCreateContextRecordsMutation';

export type CreateContextRecordModalState = {
  visible: boolean;
  contextObjectId: string;
  contextObjectName: string;
};

type CreateContextRecordModalProps = {
  modalState: CreateContextRecordModalState;
  onCompleted?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
  onCancel?: () => void;
};

export const CreateContextRecordModal = ({
  modalState,
  onCompleted,
  onScheduled,
  onFailed,
  onCancel,
}: CreateContextRecordModalProps) => {
  const [contextName, setContextName] = useState('');

  const { createContextRecords, isCreatingContextRecords } =
    useCreateContextRecordsMutation();

  const handleSubmit = async () => {
    try {
      const res = await createContextRecords({
        variables: [
          {
            name: contextName,
            contextObjectId: modalState.contextObjectId,
          },
        ],
      });

      const status = res.data?.createContextRecords.operation.status;

      if (status === BulkOperationStatus.Completed) {
        message.success(
          `${capitalize(modalState.contextObjectName)} created successfully.`,
        );
        onCompleted?.();
      }

      if (status === BulkOperationStatus.Scheduled) {
        message.success(
          `The creation of a ${modalState.contextObjectName} is being processed.`,
        );
        onScheduled?.();
      }

      if (status === BulkOperationStatus.Failed) {
        message.error(
          `The creation of a ${modalState.contextObjectName} has failed. Please try again or contact support.`,
        );
        onFailed?.();
      }
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const handleOnInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setContextName(target.value);
  };

  return (
    <Modal
      title={`New ${modalState.contextObjectName}`}
      open={modalState.visible}
      destroyOnClose
      okText={`Create ${modalState.contextObjectName}`}
      bodyStyle={{ padding: '24px' }}
      width={800}
      okButtonProps={{
        loading: isCreatingContextRecords,
        onClick: handleSubmit,
      }}
      onCancel={onCancel}
    >
      <StyledForm>
        <span className="inputLabel">{`${modalState.contextObjectName} name: `}</span>
        <Input
          value={contextName}
          placeholder={`Enter ${modalState.contextObjectName} name...`}
          onChange={handleOnInputChange}
        />
      </StyledForm>
    </Modal>
  );
};

const StyledForm = styled.section`
  display: flex;
  gap: 10px;
  align-items: baseline;

  .inputLabel {
    white-space: nowrap;
  }
`;
