import type { SortDirection } from '@common/components/Table';
import type { TableFiltersModel } from '@modules/context-records';
import { CONTEXT_RECORD_COMPLIANCE } from '@modules/context-records/constants';
import { ProjectComplianceStatus as LEGACY_PROJECT_COMPLIANCE_STATUS } from '@modules/project/constants';
import type { LegacyProjectReportsParams } from '@modules/report/types';
import {
  getAttributeFilterFromLegacyProjectCustomField,
  getSetTypeFilter,
  getTableDateFilterFromReportFilter,
  getTextTypeFilter,
} from '@modules/report/utils';

type TableParams = {
  filters: TableFiltersModel;
  sorting: {
    columnId?: string;
    direction?: SortDirection;
  };
};

export const mapLegacyProjectReportsToTableParams = (
  reportParams: LegacyProjectReportsParams,
): TableParams => {
  let tableFilters: TableFiltersModel = {};

  if (reportParams.name) {
    tableFilters.name = getTextTypeFilter(reportParams.name);
  }

  if (reportParams.status) {
    tableFilters.status = getSetTypeFilter(reportParams.status);
  }

  if (reportParams.complianceStatus) {
    const filterValue = Array.isArray(reportParams.complianceStatus)
      ? reportParams.complianceStatus
      : [reportParams.complianceStatus];

    tableFilters.complianceStatus = getSetTypeFilter(
      filterValue.map((status) =>
        status === LEGACY_PROJECT_COMPLIANCE_STATUS.NonCompliant
          ? CONTEXT_RECORD_COMPLIANCE.NonCompliant
          : status,
      ),
    );
  }

  const attributesFilter =
    getAttributeFilterFromLegacyProjectCustomField(reportParams);

  if (attributesFilter) {
    tableFilters.attributes = attributesFilter;
  }

  const startDatefilter = getTableDateFilterFromReportFilter(
    reportParams,
    'startDate',
  );

  if (startDatefilter) {
    tableFilters.startDate = startDatefilter;
  }

  const endDatefilter = getTableDateFilterFromReportFilter(
    reportParams,
    'startDate',
  );

  if (endDatefilter) {
    tableFilters.endDate = endDatefilter;
  }

  return {
    filters: tableFilters,
    sorting: {
      columnId: reportParams.sortField,
      direction: reportParams.sortDirection,
    },
  };
};
