import { init } from 'commandbar';
import * as R from 'ramda';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { getId } from '@modules/auth/selectors';
import {
  INIT_COMMANDBAR,
  SET_COMMANDBAR_READY,
  setCommandBarReady,
} from '@modules/commandbar/actions';
import { formatters } from '@modules/commandbar/components/CommandBar';
import { addContext } from '@modules/commandbar/utils/commandbar-helpers';
import {
  ContextAttributeKeys,
  ContextRecordKeys,
} from '@modules/commandbar/utils/commandbar-keys';
import { getDocumentTypes } from '@modules/document-type/selectors';
import { getActiveOrganizationData } from '@modules/organization/selectors';
import { getPartyTypes } from '@modules/party-type/selectors';

const COMMANDBAR_ORG_ID = '83920db0';

/**
 * Initialize CommandBar with Trustlayer's orgID
 *
 * Documentation: https://www.commandbar.com/docs/getting-started/installation#step-1:-install-commandbar
 */
function* initSaga() {
  yield call(init, COMMANDBAR_ORG_ID);
}

/**
 * Boot up CommandBar with the user identified.
 *
 * Documentation: https://app.commandbar.com/sdk#boot
 */
function* bootSaga() {
  const userId = yield select(getId);
  yield call(window.CommandBar.boot, userId);
}

function* startSaga() {
  yield call(initSaga);
  yield call(bootSaga);
  yield put(setCommandBarReady());
}

/**
 * Add orgSlug to CommandBar context as an attribute
 * Context attributes can be used in:
 *    - Urls (e.g., routerFunction('/{{context.teamId}}/notifications') )
 *    - Availability conditions (e.g., only show command if user is an admin)
 *    - Recommendation conditions (e.g., recommend a command if the user is an admin)
 */
function* addCommandBarContextSaga() {
  const org = yield select(getActiveOrganizationData);
  yield call(addContext, ContextAttributeKeys.orgSlug, org?.slugifyName);
  yield call(addContext, ContextAttributeKeys.orgFeatures, org?.features);
}

function* addPartyTypesSaga() {
  const partyTypes = yield select(getPartyTypes);
  yield call(
    addContext,
    ContextRecordKeys.partyTypes,
    R.map(formatters[ContextRecordKeys.partyTypes], partyTypes),
  );
}

function* addDocumentTypesSaga() {
  const documentTypes = yield select(getDocumentTypes);
  yield call(
    addContext,
    ContextRecordKeys.documentTypes,
    R.map(formatters[ContextRecordKeys.documentTypes], documentTypes),
  );
}

function* updateCommandBarStore() {
  yield all([call(addPartyTypesSaga), call(addDocumentTypesSaga)]);
}

function* addDataSaga() {
  yield all([
    /*
    Org slug is set when an email is entered at login. It will be set before
    CommandBar boots so we don't need to watch for it.
    CommandBar re-boots on org change so the org slug will always be set correctly.
    */
    call(addCommandBarContextSaga),
    takeLatest('*', updateCommandBarStore),
  ]);
}

function* commandBarSagas() {
  yield all([
    takeLatest(INIT_COMMANDBAR, startSaga),
    takeLatest(SET_COMMANDBAR_READY, addDataSaga),
  ]);
}

export default commandBarSagas;
