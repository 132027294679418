import type {
  AttributeFilter,
  DateFilter,
  SetFilter,
  TableRef,
  TextFilter,
} from '@common/components/Table';
import { FILTER_OPERATORS, FILTER_TYPES } from '@common/constants/filters';
import type { ServerFiltersConfig } from '@common/utils/filters';
import { CUSTOM_FILTER_OPERATORS } from '@modules/attributes/containers/AttributesFilterForm/constants';

export const FILTERABLE_COLUMN_FIELDS = {
  name: 'name',
  status: 'status',
  compliance: 'complianceStatus',
  startDate: 'startDate',
  endDate: 'endDate',
  attributes: 'attributes',
} as const;

export const COLUMN_FIELDS = {
  ...FILTERABLE_COLUMN_FIELDS,
  actions: 'actions',
};

export const SERVER_FILTERS_CONFIG: ServerFiltersConfig = {
  [COLUMN_FIELDS.name]: {
    name: 'name',
    filterType: FILTER_TYPES.string,
  },
  [COLUMN_FIELDS.status]: {
    name: 'status',
    filterType: FILTER_TYPES.options,
  },
  [COLUMN_FIELDS.compliance]: {
    name: 'complianceStatus',
    filterType: FILTER_TYPES.options,
  },
  [COLUMN_FIELDS.startDate]: {
    name: 'startDate',
    filterType: FILTER_TYPES.date,
  },
  [COLUMN_FIELDS.endDate]: {
    name: 'endDate',
    filterType: FILTER_TYPES.date,
  },
  [COLUMN_FIELDS.attributes]: {
    name: 'attributes',
    filterType: FILTER_TYPES.match,
    customMapper: ({ _id, value, operator, type }) => {
      if (operator === CUSTOM_FILTER_OPERATORS.isEmpty) {
        return {
          name: 'attributeId',
          operator: 'notIn',
          value: [_id],
        };
      }

      return {
        operator: 'match',
        value: {
          and: [
            {
              name: 'attributeId',
              operator: FILTER_OPERATORS.equal,
              value: _id,
            },
            {
              name: 'attributeValue',
              operator: operator as FILTER_OPERATORS,
              value: type === 'number' ? Number(value) : value,
            },
          ],
        },
      };
    },
  },
};

export type TableFiltersModel = {
  [FILTERABLE_COLUMN_FIELDS.name]?: TextFilter;
  [FILTERABLE_COLUMN_FIELDS.status]?: SetFilter;
  [FILTERABLE_COLUMN_FIELDS.compliance]?: SetFilter;
  [FILTERABLE_COLUMN_FIELDS.startDate]?: DateFilter<number>;
  [FILTERABLE_COLUMN_FIELDS.endDate]?: DateFilter<number>;
  [FILTERABLE_COLUMN_FIELDS.attributes]?: AttributeFilter;
};

export type ContextRecordsTableRef = TableRef;
