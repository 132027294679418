import { graphql } from '@graphql/types';

export const ASSIGN_DOCUMENTS_TO_PARTY_MUTATION = graphql(`
  mutation AssignDocumentsToParty($payload: AssignDocumentsToPartyInput!) {
    assignDocumentsToParty(data: $payload) {
      _id
      reviewedAt
      reviewedBy
      deletedAt
      deletedBy
      archivedAt
      archivedBy
      isProcessing
      friendlyName
      notes
      s3Key
      status
      types {
        _id
        name
        pluralName
        isSystemType
      }
      data
      url
      thumbnailUrl
      webhook
      metadata
      verification {
        verifiedOn
        verifiedVia
        affectedSubjects
        notes
        user {
          _id
          email
          profile {
            name
          }
        }
      }
      flag {
        addedOn
        severityLevel
        affectedSubjects
        notes
        user {
          _id
          email
          profile {
            name
          }
        }
        authorName
      }
      issuedBy {
        name
        address
        contactName
        phone
        email
      }
      organization
      moduleId
      compliantAttributes
      compliantChecklistAttributes
      party {
        _id
        name
        externalIds
        hasWaivedRequirements
        hasOverriddenRequirements
        isTrackingCompliance
        subjects {
          subjectId
          subjectLabel
          notes
          requirementComplianceStatusValue
          resetRequirementOn
        }
        requirements {
          _id
          organization
          party
          partyComplianceProfile
          moduleId
          moduleLabel
          subjectId
          subjectLabel
          attributeId
          attributeLabel
          attributeType
          attributeDescription
          matchingCriteria
          automaticallyDetected
          masterDocumentAttributeId
          notes
          publicNotes
          hidePublicNotesWhenCompliant
          operator
          targetValue
          requirementComplianceStatusValue
          requirementComplianceStatusSource
          matchingDocumentsCount
          resetRequirementOn
          createdAt
          updatedAt
        }
        documentChecklists {
          _id
          type {
            _id
            name
            pluralName
            isSystemType
          }
          attributeId
          attributeLabel
          complianceProfile
          isActive
          createdAt
          updatedAt
        }
        partyComplianceProfile {
          _id
          name
          complianceProfile
          nextExpirationDate
          latestExpirationDate
          metadata
          complianceStats {
            complianceScore
            compliantRequirementsCount
            nonCompliantRequirementsCount
            waivedRequirementsCount
            overriddenRequirementsCount
            totalRequirementsCount
            totalSubjectsCount
            compliantSubjectsCount
          }
        }
      }
      request
      owner {
        _id
      }
      annotations
      projects {
        _id
        name
      }
      appliesToAllProjects
      createdAt
      updatedAt
      fillableForm {
        _id
        annotations
        formFields
        formFieldValues
        attachments
        format
        pdfId
        status
      }
      insurers
      connectedLicense {
        _id
        type
        name
        number
        status
        refreshedAt
        initialData {
          firstName
          lastName
          licenseNumber
          licenseState
          producerNumber
        }
        customData {
          nameBirth {
            dateOfBirth
          }
          licenses {
            stateCode
            licenseStatus
            licenseNumber
            licenseIssueDate
            licenseExpirationDate
            licenseClassCode
            loa {
              loaName
              loaStatus
              loaIssueDate
            }
          }
        }
      }
    }
  }
`);
