import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ModalConfirm } from '@common/components/ModalConfirm';
import { BulkOperationStatus } from '@graphql/types/graphql';
import { useMakeActiveRequestRecordMutation } from '@modules/request-records/hooks/useMakeActiveRequestRecordMutation';
import { Typography, message } from 'antd';

type MakeActiveRequestRecordModalProps = {
  open: boolean;
  requestRecord: {
    _id: string;
    name: string;
  };
  onCancel?: () => void;
  onCompleted?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
};

export const MakeActiveRequestRecordModal = ({
  open,
  requestRecord,
  onCancel,
  onCompleted,
  onScheduled,
  onFailed,
}: MakeActiveRequestRecordModalProps) => {
  const { makeActiveRequestRecord, isMakingActiveRequestRecord } =
    useMakeActiveRequestRecordMutation();

  const handleMakeActive = async () => {
    if (!requestRecord?._id) return;

    const res = await makeActiveRequestRecord(requestRecord._id);

    const status = res.data?.activateRequestRecords.operation.status;

    if (status === BulkOperationStatus.Completed) {
      message.success(`${requestRecord?.name} is now active.`);
      onCompleted?.();
    }

    if (status === BulkOperationStatus.Scheduled) {
      message.success(
        `${requestRecord?.name} has been scheduled for activation.`,
      );
      onScheduled?.();
    }

    if (status === BulkOperationStatus.Failed) {
      message.error(
        `The activation of ${requestRecord?.name} has failed. Please try again or contact support.`,
      );
      onFailed?.();
    }
  };

  return (
    <ModalConfirm
      data-cy="make-active-request-record-modal"
      open={open}
      icon={<ExclamationCircleOutlined />}
      okButtonProps={{
        loading: isMakingActiveRequestRecord,
      }}
      okText="Make Active"
      title={`Are you sure you want to make ${requestRecord.name} active?`}
      onCancel={onCancel}
      onOk={handleMakeActive}
    >
      <Typography.Paragraph>
        Please confirm that you want to make {requestRecord.name} active
      </Typography.Paragraph>
      <Typography.Paragraph>
        It will show up in your reports and dashboard
      </Typography.Paragraph>
    </ModalConfirm>
  );
};
