import { NetworkStatus } from '@apollo/client';
import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

import debounce from 'lodash/debounce';
import { useMemo } from 'react';

import { FILTER_OPERATORS } from '@common/constants/filters';
import type { Attribute } from '@graphql/types/graphql';
import type { RecordTypes } from '@trustlayer/common';

const DEFAULT_ATTRIBUTES_PAGINATION = 10;
const DEFAULT_QUERY_INPUT = {
  first: DEFAULT_ATTRIBUTES_PAGINATION,
  offset: 0,
};

const ATTRIBUTES_QUERY = graphql(`
  query AttributesFilter($input: AttributesInput!) {
    attributes(input: $input) {
      totalCount
      nodes {
        _id
        name
        type
        color
        options {
          _id
          value
          key
        }
      }
    }
  }
`);

export const useAttributesQuery = ({
  recordType,
  attributeId,
}: { recordType: RecordTypes; attributeId?: string }) => {
  const { data, loading, fetchMore, refetch, networkStatus } = useQuery(
    ATTRIBUTES_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        input: {
          ...DEFAULT_QUERY_INPUT,
          filter: {
            and: [
              {
                name: 'associatedEntities',
                operator: FILTER_OPERATORS.in,
                value: [recordType] as unknown,
              },
              ...(attributeId
                ? [
                    {
                      name: 'id',
                      operator: FILTER_OPERATORS.equal,
                      value: attributeId,
                    },
                  ]
                : []),
            ],
          },
        },
      },
    },
  );

  const { nodes: attributes = [], totalCount: attributesTotalCount = 0 } =
    data?.attributes || {};

  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;
  const isLoading = !isLoadingMore && loading;

  const filterAttributesByName = useMemo(
    () =>
      debounce((text: string) => {
        refetch({
          input: {
            ...DEFAULT_QUERY_INPUT,
            filter: {
              and: [
                {
                  name: 'name',
                  operator: FILTER_OPERATORS.contains,
                  value: text,
                },
                {
                  name: 'associatedEntities',
                  operator: FILTER_OPERATORS.in,
                  value: [recordType],
                },
              ],
            },
          },
        });
      }, 500),
    [refetch, recordType],
  );

  return {
    attributes: attributes as Attribute[],
    attributesTotalCount,
    loading: isLoading || isLoadingMore,
    filterAttributesByName,
    fetchMoreAttributes: () => {
      fetchMore({
        variables: {
          input: {
            ...DEFAULT_QUERY_INPUT,
            offset: attributes.length,
            filter: {
              and: [
                {
                  name: 'associatedEntities',
                  operator: FILTER_OPERATORS.in,
                  value: [recordType],
                },
              ],
            },
          },
        },
      });
    },
  };
};
