import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type { GetDocumentFormInput } from '@graphql/types/graphql';

export const GET_DOCUMENT_FORM = graphql(`
  query FillableFormSignContainer_DocumentForm(
    $input: GetDocumentFormInput!
  ) {
    getDocumentForm(data: $input) {
      _id
      url
      friendlyName
      fillableForm {
        _id
        annotations
        formFields
        formFieldValues
        attachments
        format
        pdfId
        status
      }
    }
  }
`);

export const useDocumentFormQuery = (input: GetDocumentFormInput) => {
  const { data, loading } = useQuery(GET_DOCUMENT_FORM, {
    skip: !input.fillableFormId,
    //? disable cache to avoid stale data when a draft is saved and then resumed
    initialFetchPolicy: 'network-only',
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    includeTypename: true,
    variables: { input },
  });

  return {
    documentForm: data?.getDocumentForm,
    isDocumentFormLoading: loading,
  };
};
