import { message as notify } from 'antd';
import * as R from 'ramda';
import {
  all,
  call,
  put,
  putResolve,
  select,
  takeLatest,
} from 'redux-saga/effects';

import {
  createComplianceProfile,
  setCurrentComplianceProfile,
  setCurrentDocumentChecklists,
  setCurrentRequirements,
} from '@modules/compliance-profile/actions';
import { ComplianceProfileContext } from '@modules/compliance-profile/constants';
import {
  getCurrentComplianceProfile,
  getCurrentDocumentChecklists,
  getCurrentRequirements,
} from '@modules/compliance-profile/selectors';
import { setIsChangingComplianceProfile } from '@modules/party/actions';

import { trackEvent } from '@common/utils/track-helpers';
import {
  SET_PARTIES_COMPLIANCE_PROFILE,
  SET_PARTY_COMPLIANCE_PROFILE,
  assignComplianceProfile,
} from '../actions';

export function* setComplianceProfileSaga({
  payload: { partyId, applyToAllProjects, isNotify },
}) {
  yield put(setIsChangingComplianceProfile(true));
  const rules = yield select(getCurrentRequirements);
  const documentChecklists = yield select(getCurrentDocumentChecklists);

  // eslint-disable-next-line fp/no-let
  let complianceProfile = yield select(getCurrentComplianceProfile);

  if (!complianceProfile._id) {
    const rulesData = rules
      .filter((rule) => Boolean(rule.attributeId))
      .map((rule) =>
        R.omit(['_id', 'complianceProfile', 'updatedAt', 'createdAt'], rule),
      );
    const documentChecklistsData = documentChecklists.map((checklist) =>
      R.omit(['_id', 'createdAt', 'updatedAt'], checklist),
    );

    const res = yield putResolve(
      createComplianceProfile({
        ...R.omit(
          [
            '_id',
            'createdAt',
            'updatedAt',
            'context',
            'deletedAt',
            'deletedBy',
            'partiesCount',
            'projectsCount',
          ],
          complianceProfile,
        ),
        context: ComplianceProfileContext.Party,
        contextId: partyId,
        rules: rulesData,
        documentChecklists: documentChecklistsData.map((checklist) => ({
          ...checklist,
          type: checklist.type._id,
        })),
      }),
    );
    complianceProfile = R.path(
      ['payload', 'data', 'createComplianceProfile'],
      res,
    );
    yield call(trackEvent, 'User assigned a compliance profile to a party');
  }

  yield putResolve(
    assignComplianceProfile({
      partyId,
      complianceProfileId: complianceProfile?._id,
      applyToAllProjects,
    }),
  );
  yield call(trackEvent, "User updated a party's compliance profile");
  yield put(setIsChangingComplianceProfile(false));
  yield put(setCurrentComplianceProfile(complianceProfile));
  yield put(setCurrentRequirements(complianceProfile.rules));
  yield put(setCurrentDocumentChecklists(complianceProfile.documentChecklists));

  if (isNotify) {
    yield notify.success(`Compliance profile successfully assigned to party`);
  }
}

function* setPartiesComplianceProfileSaga({
  payload: { parties, applyToAllProjects },
}) {
  yield put(setIsChangingComplianceProfile(true));
  yield all(
    parties.map((partyId) =>
      call(setComplianceProfileSaga, {
        payload: { partyId, applyToAllProjects },
      }),
    ),
  );
  yield notify.success(
    `Compliance profile successfully assigned to ${parties.length} parties`,
  );
  yield put(setIsChangingComplianceProfile(false));
}

function* partyComplianceProfileSagas() {
  yield all([
    yield takeLatest(SET_PARTY_COMPLIANCE_PROFILE, setComplianceProfileSaga),
    yield takeLatest(
      SET_PARTIES_COMPLIANCE_PROFILE,
      setPartiesComplianceProfileSaga,
    ),
  ]);
}

export default partyComplianceProfileSagas;
