import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const UPLOAD_CONTEXT_RECORD_ATTACHMENTS_MUTATION = graphql(`
  mutation UploadContextRecordAttachments($data: UploadContextRecordAttachmentsInput!) {
    uploadContextRecordAttachments(data: $data) 
  }
`);

export function useUploadContextRecordAttachments() {
  const [uploadContextRecordAttachments, { loading }] = useMutation(
    UPLOAD_CONTEXT_RECORD_ATTACHMENTS_MUTATION,
    {
      context: {
        hasUpload: true,
      },
    },
  );

  return { uploadContextRecordAttachments, uploading: loading };
}
