import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const ARCHIVE_CONTEXT_RECORDS_MUTATION = graphql(`
  mutation ArchiveContextRecordsModal($data: ArchiveContextRecordsInput!) {
    archiveContextRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useArchiveContextRecordsMutation = () => {
  const [
    archiveContextRecordsMutation,
    { loading: isArchivingContextRecords },
  ] = useMutation(ARCHIVE_CONTEXT_RECORDS_MUTATION);

  const archiveContextRecords = (filters?: ServerFilterInput) => {
    return archiveContextRecordsMutation({
      variables: {
        data: {
          filter: filters,
        },
      },
    });
  };

  return {
    archiveContextRecords,
    isArchivingContextRecords,
  };
};
