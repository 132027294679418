import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Spinner from '@common/components/Spinner';
import { getGraphqlPayload } from '@store/helpers';

import { renderEmailTemplate } from '../actions';
import { RenderPreviewEmailTemplate } from '../components/RenderPreviewEmailTemplate';

const ON_THE_FLY_PLACEHOLDER_LINK_REGEX = /http.*\/email-preview-placeholder/;

const EmailPreviewContainer = ({ template, partyId }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [preview, setPreview] = useState();
  const templateMemoized = useMemo(() => JSON.stringify(template), [template]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const res = await dispatch(renderEmailTemplate({ template, partyId }));
      const payload = getGraphqlPayload(res);

      payload && setPreview(payload);
      setIsLoading(false);
    };

    load();
  }, [templateMemoized, partyId, dispatch]);

  if (isLoading) {
    return <Spinner />;
  }
  return <RenderPreviewEmailTemplate preview={preview} />;
};

const StyledWrapper = styled.div`
  label {
    display: block;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    padding: 0 0 12px;
  }

  > div {
    margin-top: 16px;
  }
`;

export default EmailPreviewContainer;
