import { FILTER_OPERATORS, FILTER_TYPES } from '@common/constants/filters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const DELETE_REQUEST_RECORD_MUTATION = graphql(`
  mutation DeleteRequestRecord($data: DeleteRequestRecordsInput!) {
    deleteRequestRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useDeleteRequestRecordMutation = () => {
  const [deleteRequestRecordMutation, { loading: isDeletingRequestRecord }] =
    useMutation(DELETE_REQUEST_RECORD_MUTATION);

  const deleteRequestRecord = async (id: string) => {
    return deleteRequestRecordMutation({
      variables: {
        data: {
          filter: {
            and: [
              {
                name: FILTER_TYPES.id,
                operator: FILTER_OPERATORS.in,
                value: [id],
              },
            ],
          },
        },
      },
    });
  };

  return {
    deleteRequestRecord,
    isDeletingRequestRecord,
  };
};
