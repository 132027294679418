import type { ComplianceSubject } from '@graphql/types/graphql';
import { ExpirationDates } from '@modules/requirement/components/ExpirationDates';
import { FullWidthSpace, StatusBadge, Until } from '@trustlayer/ui';
import { Card, Space, Typography } from 'antd';
import type { ReactNode } from 'react';
import styled from 'styled-components';

type SubjectProps = {
  subject: ComplianceSubject;
  children: ReactNode;
  className?: string;
  actionsComponent: ReactNode | null;
};

export function Subject({
  subject,
  children,
  className,
  actionsComponent,
}: Readonly<SubjectProps>) {
  return (
    <StyledCard
      bordered={false}
      className={className}
      title={
        <Space direction="horizontal" size="small" align="center">
          <StyledTitle level={5}>{subject.label}</StyledTitle>
          <Until resetOn={subject.resetOn}>
            <div>
              <StatusBadge status={subject.status} resetOn={subject.resetOn} />
            </div>
          </Until>
        </Space>
      }
      extra={
        <Space direction="horizontal" size="small">
          <ExpirationDates
            expirationDate={subject.latestExpirationDate}
            effectiveDate={subject.effectiveDate}
          />
          {actionsComponent}
        </Space>
      }
    >
      <FullWidthSpace size="small" direction="vertical">
        <StyledAdditionalInfo>
          {/* //? in the future here we will show the additional info box if not waived/ovverridden */}
          {subject.notes ? (
            <Typography.Paragraph type="secondary">
              {subject.notes}
            </Typography.Paragraph>
          ) : undefined}
        </StyledAdditionalInfo>
        <Typography.Text type="secondary">Requirements</Typography.Text>
        {children}
      </FullWidthSpace>
    </StyledCard>
  );
}

const StyledCard = styled(Card)`
  .ant-card-head {
    padding: 0;
    min-height: auto;
    border-bottom: 0;
  }

  .ant-card-body {
    padding: 12px 0;
  }

  box-shadow: none !important;
`;

const StyledAdditionalInfo = styled.div`
  padding-bottom: 12px;
  border-bottom: 1px solid #F0F0F0;
`;

const StyledTitle = styled(Typography.Title)`
  margin-bottom: 0 !important;
`;
