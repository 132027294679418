import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ModalConfirm } from '@common/components/ModalConfirm';
import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { BulkOperationStatus } from '@graphql/types/graphql';
import { message } from 'antd';
import { useDeleteContextRecordsMutation } from '../../hooks/useDeleteContextRecordsMutation';

type DeleteContextRecordsModalProps = {
  open: boolean;
  count?: number;
  filters?: ServerFilterInput;
  contextObject: {
    name: string;
    pluralName: string;
  };
  onCancel?: () => void;
  onCompleted?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
};

export const DeleteContextRecordsModal = ({
  open,
  count = 1,
  filters,
  contextObject,
  onCancel,
  onCompleted,
  onScheduled,
  onFailed,
}: DeleteContextRecordsModalProps) => {
  const { deleteContextRecords, isDeletingContextRecords } =
    useDeleteContextRecordsMutation();

  const handleDelete = async () => {
    const res = await deleteContextRecords(filters);

    const status = res.data?.deleteContextRecords.operation.status;

    if (status === BulkOperationStatus.Completed) {
      message.success(
        `${count} ${count > 1 ? `${contextObject.pluralName} have been` : `${contextObject.name} has been`} deleted successfully.`,
      );
      onCompleted?.();
    }

    if (status === BulkOperationStatus.Scheduled) {
      message.success(
        `${count} ${count > 1 ? `${contextObject.pluralName} have been` : `${contextObject.name} has been`} scheduled for deletion.`,
      );
      onScheduled?.();
    }

    if (status === BulkOperationStatus.Failed) {
      message.error(
        `The deletion of your ${count > 1 ? `${contextObject.pluralName} have been` : `${contextObject.name} has been`} failed. Please try again or contact support.`,
      );
      onFailed?.();
    }
  };

  return (
    <ModalConfirm
      data-cy="delete-context-records-modal"
      open={open}
      icon={<ExclamationCircleOutlined />}
      okButtonProps={{
        danger: true,
        loading: isDeletingContextRecords,
      }}
      okText="Delete"
      title={`Are you sure you want to delete ${count} ${count > 1 ? `${contextObject.pluralName}` : `${contextObject.name}`}?`}
      onCancel={onCancel}
      onOk={handleDelete}
    >
      <p>This action cannot be undone</p>
    </ModalConfirm>
  );
};
