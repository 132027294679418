import {
  ProjectFilter,
  type ProjectFilterType,
  type ProjectFilterTypeOption,
} from '@modules/project/containers/ProjectFilter';
import { useMemo } from 'react';
import { DocumentFilterFields } from '../../constants';

export const documentProjectFilterTypeOptions: ProjectFilterTypeOption[] = [
  {
    key: 'assignedToAnyProject',
    label: 'Associated to any project',
  },
  {
    key: 'notAssignedToAnyProject',
    label: 'Not associated to any project',
  },
  {
    key: 'assignedToProjects',
    label: 'Associated to specific projects',
  },
];

type DocumentProjectFilterProps = {
  //? any here because there are not types for searchParams
  searchParams: Record<string, any>;
  updateFilters: (filters: Record<string, any>) => void;

  initialProjects: { _id: string; name: string }[];
};

const documentProjectFilterTypeToKeyMap: Record<ProjectFilterType, string> = {
  assignedToAnyProject: DocumentFilterFields.AssociatedToAnyProject,
  notAssignedToAnyProject: DocumentFilterFields.NotAssociatedToAnyProject,
  assignedToProjects: DocumentFilterFields.AssociatedProjects,
};

export const DocumentProjectFilter = ({
  searchParams,
  updateFilters,
  initialProjects,
}: DocumentProjectFilterProps) => {
  const filterType = useMemo(() => {
    if (searchParams[DocumentFilterFields.AssociatedProjects]) {
      return 'assignedToProjects';
    }
    if (searchParams[DocumentFilterFields.AssociatedToAnyProject]) {
      return 'assignedToAnyProject';
    }
    if (searchParams[DocumentFilterFields.NotAssociatedToAnyProject]) {
      return 'notAssignedToAnyProject';
    }
  }, [searchParams]);

  const handleOnChange = ({
    type,
    projects,
  }: {
    type: ProjectFilterType;
    projects?: string[];
  }) => {
    if (!type) {
      updateFilters({
        [DocumentFilterFields.AssociatedProjects]: undefined,
        [DocumentFilterFields.AssociatedToAnyProject]: undefined,
        [DocumentFilterFields.NotAssociatedToAnyProject]: undefined,
      });
      return;
    }
    if (type === 'assignedToProjects') {
      updateFilters({
        [DocumentFilterFields.AssociatedProjects]: projects || [],
        [DocumentFilterFields.AssociatedToAnyProject]: undefined,
        [DocumentFilterFields.NotAssociatedToAnyProject]: undefined,
      });
      return;
    }
    const filterKey = documentProjectFilterTypeToKeyMap[type];

    updateFilters({
      [DocumentFilterFields.AssociatedProjects]: undefined,
      [DocumentFilterFields.AssociatedToAnyProject]: undefined,
      [DocumentFilterFields.NotAssociatedToAnyProject]: undefined,
      [filterKey]: true,
    });
  };

  const projectsParam =
    searchParams[DocumentFilterFields.AssociatedProjects] || [];

  return (
    <ProjectFilter
      options={documentProjectFilterTypeOptions}
      filterType={filterType}
      selectedProjects={
        typeof projectsParam === 'string' ? [projectsParam] : projectsParam
      }
      initialProjects={initialProjects}
      onChange={handleOnChange}
      withAppliesToAllProjects
    />
  );
};
