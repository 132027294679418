import {
  ClockCircleOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Popconfirm, Tag, Tooltip } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import { HideForViewerRole } from '@modules/organization-member';
import { TAG_COLORS } from '@modules/party';

type PartyTagProps = {
  tag: {
    _id?: string;
    name: string;
    color?: string | null;
  };
  onClose?: () => void;
  closeConfirmText?: string | React.ReactNode;
  expiresAt?: string;
};

/**
 *
 * @deprecated Use PartyTag from `modules/party` instead
 */
const PartyTag = ({
  tag,
  onClose,
  closeConfirmText,
  expiresAt,
}: PartyTagProps) => {
  return (
    <StyledWrapper
      data-cy="partyTag"
      color={tag.color as keyof typeof TAG_COLORS}
    >
      {expiresAt && (
        <Tooltip title={`Tag expires on ${moment(expiresAt).format('ll')}`}>
          <ClockCircleOutlined
            data-cy="expiresAtClock"
            style={{ marginRight: 5 }}
          />
        </Tooltip>
      )}
      {tag.name}
      {closeConfirmText && onClose ? (
        <Popconfirm
          title={<StyledConfirmText>{closeConfirmText}</StyledConfirmText>}
          icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
          okText="Delete tag"
          okType="danger"
          onConfirm={(e) => {
            e?.stopPropagation();
            onClose();
          }}
          onCancel={(e) => {
            e?.stopPropagation();
          }}
        >
          <CloseOutlined
            onClick={(e) => {
              e?.stopPropagation();
            }}
          />
        </Popconfirm>
      ) : onClose ? (
        <HideForViewerRole>
          <CloseOutlined
            onClick={(e) => {
              e?.stopPropagation();
              onClose();
            }}
          />
        </HideForViewerRole>
      ) : null}
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Tag)<{ color?: keyof typeof TAG_COLORS | null }>`
  border-radius: 99em;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.04);
  margin-bottom: 8px;
  color: ${({ color }) =>
    (color ? TAG_COLORS[color] : { text: '#5077a7' }).text} !important;
  border-color: ${({ color }) =>
    (color ? TAG_COLORS[color] : { border: '#c5d6ec' }).border};
  background-color: ${({ color }) =>
    (color ? TAG_COLORS[color] : { background: '#f4f8fd' }).background};

  .anticon-close,
  .anticon-close:hover {
    color: ${({ color }) =>
      (color ? TAG_COLORS[color] : { text: '#5077a7' }).text} !important;
    margin-left: 8px;
  }
`;

const StyledConfirmText = styled.span`
  font-size: 13px;
`;

export default PartyTag;
