import { UserOutlined } from '@ant-design/icons';
import InfiniteScrollSelect from '@common/components/InfiniteScrollSelect';
import type { Contact } from '@graphql/types/graphql';
import { FullWidthSpace } from '@trustlayer/ui';
import type { DefaultOptionType } from 'antd/es/select';
import { useContactsEmailsForSearch } from '../hooks/useContactsEmailsForSearch';

export function ContactsInput({
  onChange,
  onSelect,
  onClear,
  placeholder,
  email,
}: Readonly<{
  onSelect: (contact: Contact) => void;
  onClear: () => void;
  onChange: (value: string) => void;
  placeholder?: string;
  email?: string;
}>) {
  const {
    contactsList,
    contactsLoading,
    contactsLoadingMore,
    fetchMoreContactsForSearch,
    contactsListTotalCount,
  } = useContactsEmailsForSearch(email);

  const handleOnSelect = (_: string, option: DefaultOptionType) => {
    const contact = contactsList?.find((contact) => contact._id === option.key);

    if (contact) {
      onSelect?.(contact);
    }
  };

  return (
    <InfiniteScrollSelect
      as="AutoComplete"
      allowClear
      showSearch
      isDataLoading={contactsLoading || contactsLoadingMore}
      placeholder={placeholder || 'Enter contact email...'}
      onSelect={handleOnSelect}
      onClear={onClear}
      notFoundContent={null}
      suffixIcon={null}
      onChange={onChange}
      hasMore={contactsListTotalCount > contactsList?.length}
      loadMore={fetchMoreContactsForSearch}
      options={contactsList?.map((option) => ({
        key: option._id,
        value: option.email,
        'data-cy': 'select_option',
        label: (
          <FullWidthSpace align="center" size="small">
            <UserOutlined />
            <span>{`${option.contactPersonName} - ${option.email}`}</span>
          </FullWidthSpace>
        ),
        contactData: option,
      }))}
    />
  );
}
