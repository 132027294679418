import { FILTER_OPERATORS, FILTER_TYPES } from '@common/constants/filters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const DELETE_PRIMARY_RECORD_MUTATION = graphql(`
  mutation DeletePrimaryRecord($data: DeletePrimaryRecordsInput!) {
    deletePrimaryRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useDeletePrimaryRecordMutation = () => {
  const [deletePrimaryRecordMutation, { loading: isDeletingPrimaryRecord }] =
    useMutation(DELETE_PRIMARY_RECORD_MUTATION);

  const deletePrimaryRecord = (id: string) => {
    return deletePrimaryRecordMutation({
      variables: {
        data: {
          filter: {
            and: [
              {
                name: FILTER_TYPES.id,
                operator: FILTER_OPERATORS.in,
                value: [id],
              },
            ],
          },
        },
      },
    });
  };

  return {
    deletePrimaryRecord,
    isDeletingPrimaryRecord,
  };
};
