import { ComplianceStats } from '@common/components/ComplianceStats';
import Flex from '@common/components/Flex';
import { Bleed } from '@trustlayer/ui';
import {
  Checkbox,
  Result,
  Space,
  Table,
  type TableProps,
  Typography,
} from 'antd';
import { useMemo } from 'react';
import styled from 'styled-components';
import { usePrimaryRecordRequestRecordsStats } from '../../hooks/';

interface Record {
  key: string;
  name: string;
  status: [number, number];
}

export function CompliancePopoverContent({
  primaryRecordId,
}: Readonly<{
  primaryRecordId: string;
}>) {
  const {
    data,
    loading,
    error,
    page,
    onPageChange,
    nonCompliantOnly,
    onToggleNonCompliantOnly,
  } = usePrimaryRecordRequestRecordsStats(primaryRecordId);
  const dataSource: Record[] = data?.primaryRecord.requestRecords
    ? data.primaryRecord.requestRecords.nodes.map((n) => ({
        key: n._id,
        name: n.name,
        status: [
          n.complianceStats.compliantSubjects,
          n.complianceStats.totalSubjects,
        ],
      }))
    : [];
  const MemoizedHeader = useMemo(
    () => () => (
      <TableHeader
        title="Records"
        nonCompliantOnly={nonCompliantOnly}
        onToggleNonCompliantOnly={onToggleNonCompliantOnly}
      />
    ),
    [nonCompliantOnly, onToggleNonCompliantOnly],
  );
  const columns: TableProps<Record>['columns'] = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: ([compliants, total]) => (
          <ComplianceStats
            compliantCount={compliants}
            totalCount={total}
            rightLabel="Compliant"
          />
        ),
      },
    ],
    [],
  );
  const showPagination =
    data?.primaryRecord.requestRecords?.totalCount &&
    data?.primaryRecord.requestRecords?.totalCount > 3;

  if (error) {
    return (
      <Result
        status="error"
        title="Oops! Something went wrong"
        subTitle="Please try again later."
      />
    );
  }

  return (
    <StyledBleed margin={12}>
      <StyledTable
        title={MemoizedHeader}
        dataSource={dataSource}
        loading={loading && !data}
        columns={columns}
        pagination={
          showPagination
            ? {
                size: 'small',
                simple: true,
                pageSize: 3,
                current: page + 1,
                total: data?.primaryRecord.requestRecords?.totalCount,
                onChange: (page: number) => {
                  onPageChange(page - 1);
                },
              }
            : false
        }
      />
    </StyledBleed>
  );
}

function TableHeader({
  title,
  onToggleNonCompliantOnly,
  nonCompliantOnly,
}: Readonly<{
  title: string;
  nonCompliantOnly: boolean;
  onToggleNonCompliantOnly: (checked: boolean) => void;
}>) {
  return (
    <Flex size="small" justify="space-between" align="center">
      <div>{title}</div>
      <Space size="small">
        <Checkbox
          onChange={(e) => onToggleNonCompliantOnly(e.target.checked)}
          checked={nonCompliantOnly}
        />
        <Typography.Text type="secondary">Non-compliant only</Typography.Text>
      </Space>
    </Flex>
  );
}

const StyledBleed = styled(Bleed)`
  height: 340px;
  overflow: hidden;
`;

const StyledTable = styled(Table)`
  width: 384px;

  .ant-table-thead > tr > th {
    font-weight: 400;
  }

  overflow: hidden;
  border-radius: 0 0 8px 8px;
`;
