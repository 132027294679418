import { FILTER_TYPES } from '@common/constants/filters';
import type { ServerFiltersConfig } from '@common/utils/filters';

export const FILTERABLE_COLUMN_FIELDS = {
  name: 'name',
  tags: 'tags',
  activeDocuments: 'activeDocuments',
  reviewStatus: 'reviewStatus',
  flagsSeverity: 'flagsSeverity',
  types: 'types',
  contextRecords: 'contextRecords',
  waiversStatus: 'waiversStatus',
  overridesStatus: 'overridesStatus',
  nextComplianceExpiration: 'nextComplianceExpiration',
  lastActivity: 'lastActivity',
  lastRequest: 'lastRequest',
  messageFailures: 'messageFailures',
  contactEmailStatus: 'contactEmailStatus',
  status: 'status',
} as const;

export const COLUMN_FIELDS = {
  ...FILTERABLE_COLUMN_FIELDS,
  complianceStatus: 'complianceStatus',
  totalEmails: 'totalEmails',
  actions: 'actions',
  automations: 'automations',
  createdAt: 'createdAt',
};

export const REVIEW_STATUS = {
  Reviewed: 'reviewed',
  ToReview: 'toReview',
} as const;

export { PrimaryRecordStatus as PRIMARY_RECORD_STATUS } from '@trustlayer/common';

export const WAIVERS_STATUS = {
  WithRequirements: 'withWaivedRequirements',
  WithoutRequirements: 'withoutWaivedRequirements',
} as const;

export const OVERRIDDEN_STATUS = {
  WithOverriddenRequirements: 'withOverriddenRequirements',
  WithoutOverriddenRequirements: 'withoutOverriddenRequirements',
} as const;

export const CONTACT_EMAIL_STATUS = {
  WithContactEmail: 'withContactEmail',
  WithoutContactEmail: 'withoutContactEmail',
} as const;

export const SEVERITY_STATUS = {
  Low: 'low',
  Medium: 'medium',
  High: 'high',
} as const;

export const SERVER_FILTERS_CONFIG: ServerFiltersConfig = {
  [COLUMN_FIELDS.name]: {
    name: 'name',
    filterType: FILTER_TYPES.string,
  },
  [COLUMN_FIELDS.flagsSeverity]: {
    name: 'flagsSeverity', // filter name required by the server
    filterType: FILTER_TYPES.options,
  },
  [COLUMN_FIELDS.reviewStatus]: {
    name: 'reviewStatus',
    filterType: FILTER_TYPES.options,
  },
  [COLUMN_FIELDS.status]: {
    name: 'status',
    filterType: FILTER_TYPES.options,
  },
  [COLUMN_FIELDS.waiversStatus]: {
    name: 'waiversStatus',
    filterType: FILTER_TYPES.options,
  },
  [COLUMN_FIELDS.overridesStatus]: {
    name: 'overridesStatus',
    filterType: FILTER_TYPES.options,
  },
  [COLUMN_FIELDS.contactEmailStatus]: {
    name: 'contactEmailStatus',
    filterType: FILTER_TYPES.options,
  },
  [COLUMN_FIELDS.activeDocuments]: {
    name: 'documentsCount',
    filterType: FILTER_TYPES.number,
  },
  [COLUMN_FIELDS.contextRecords]: {
    name: 'contextRecordId',
    filterType: FILTER_TYPES.id,
  },
  [COLUMN_FIELDS.types]: {
    name: 'typeId',
    filterType: FILTER_TYPES.id,
  },
  [COLUMN_FIELDS.lastActivity]: {
    name: 'lastActivity',
    filterType: FILTER_TYPES.date,
  },
  [COLUMN_FIELDS.lastRequest]: {
    name: 'lastRequest',
    filterType: FILTER_TYPES.date,
  },
  [COLUMN_FIELDS.nextComplianceExpiration]: {
    name: 'nextComplianceExpiration',
    filterType: FILTER_TYPES.date,
  },
  [COLUMN_FIELDS.messageFailures]: {
    name: 'messageFailures',
    filterType: FILTER_TYPES.date,
  },
  [COLUMN_FIELDS.tags]: {
    name: 'tagId',
    filterType: FILTER_TYPES.id,
  },
};
