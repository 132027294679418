import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const FETCH_PARTY_WITH_DETAILS_BY_REQUEST = `${namespace}/FETCH_PARTY_WITH_DETAILS_BY_REQUEST`;

const FETCH_PARTY_WITH_DETAILS_BY_REQUEST_QUERY = gql`
  query getPartyDataByRequest($organizationId: ObjectId!, $partyId: ObjectId!, $requestId: ObjectId!) {
    getPartyDataByRequest(organizationId: $organizationId, partyId: $partyId, requestId: $requestId) {
      _id
      name
      externalIds
      organization
      documents {
        _id
        friendlyName
        organization
        party {
          _id
        }
        createdAt
      }
      totalDocumentsToReviewCount
      status
      requests {
        _id
        deletedAt
        deletedBy
        forwardedAt
        forwardedTo
        sentByUser
        party
        message
        attachments {
          url
          friendlyName
          s3Key
        }
        documentChecklists
        sentOn
        organization
        dueDate
        project
        createdAt
        updatedAt
      }
      partyComplianceProfile {
        _id
        isProcessing
        party
        metadata
        complianceProfile
        name
        nextExpirationDate
        latestExpirationDate
        complianceStats {
          complianceScore
          compliantRequirementsCount
          nonCompliantRequirementsCount
          waivedRequirementsCount
          overriddenRequirementsCount
          totalRequirementsCount
          totalSubjectsCount
          compliantSubjectsCount
        }
        createdAt
        updatedAt
      }
      partyContacts {
        _id
        party
        contactPersonName
        email
        phone
        fax
        title
        avatar
        isPrimary
        createdAt
        updatedAt
      }
    }
  }
`;

export const fetchPartyWithDetailsByRequest = ({
  organizationId,
  partyId,
  requestId,
}) => ({
  type: FETCH_PARTY_WITH_DETAILS_BY_REQUEST,
  payload: {
    key: 'getPartyDataByRequest',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_PARTY_WITH_DETAILS_BY_REQUEST_QUERY,
      variables: {
        organizationId,
        partyId,
        requestId,
      },
    },
  },
});
