import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

import { useCallback } from 'react';

const RESEND_CONVERSATION_MESSAGE_ISSUE = graphql(`
  mutation ResendConversationMessageIssue($data: ResendConversationMessageIssueInput!) {
    resendConversationMessageIssue(data: $data) {
      _id
      resendable
      ignorable
      resolved
    }
  }
`);

export const useResendConversationMessageIssueMutation = () => {
  const [resendMessageIssue, { loading }] = useMutation(
    RESEND_CONVERSATION_MESSAGE_ISSUE,
  );

  const resendConversationMessageIssue = useCallback(
    ({
      issueId,
    }: {
      issueId: string;
    }) => {
      return resendMessageIssue({
        variables: {
          data: {
            id: issueId,
          },
        },
      });
    },
    [resendMessageIssue],
  );

  return {
    resendConversationMessageIssue,
    loading,
  };
};
