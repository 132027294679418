import { FullWidthSpace } from '@trustlayer/ui';
import { AutoComplete, Col, Input, Modal, Row, Switch, message } from 'antd';
import debounce from 'lodash/debounce';
import pluralize from 'pluralize';
import * as R from 'ramda';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import {
  associateDocumentsToProject,
  bulkAssociateDocumentsToProject,
  getViewedProjects,
} from '@modules/project/actions';
import { getGraphqlResponse } from '@store/helpers';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { trackEvent } from '@common/utils/track-helpers';
import { getDocument } from '../../selectors';

const AssociateToProjectModal = ({
  visible,
  documentId,
  documents,
  onCancel,
  onSuccess,
  documentsFilter,
  selectedDocumentsCount,
  areAllDocumentsSelected,
}: {
  visible: boolean;
  documentId?: string;
  documents?: string[];
  onCancel: () => void;
  onSuccess: () => void;
  documentsFilter?: Record<string, any>;
  selectedDocumentsCount?: number;
  areAllDocumentsSelected?: boolean;
}) => {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState<null | string>(
    null,
  );
  const [isAppliedToAllProjects, setIsAppliedToAllProjects] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const documentData = useAppSelector((state) =>
    getDocument(state, documentId),
  );

  const dispatch = useAppDispatch();

  const fetchViewedProjects = useCallback(
    async (value?: string) => {
      const payload = await dispatch(
        getViewedProjects({
          first: 100,
          filter: {
            name: value,
            isActive: true,
            ...(Number(documentId?.length) > 0 && {
              documentId: documentId,
            }),
          },
        }),
      );
      const viewedProjects = getGraphqlResponse(payload)?.nodes;
      setProjects(viewedProjects);
    },
    [dispatch, documentId],
  );

  useEffect(() => {
    if (visible) {
      fetchViewedProjects();
    }
  }, [fetchViewedProjects, visible]);

  useEffect(() => {
    if (!visible) {
      setSelectedProjectId(null);
      setSearchValue('');
    }
  }, [visible]);

  useEffect(() => {
    if (documentData) {
      setIsAppliedToAllProjects(documentData?.appliesToAllProjects);
    }
  }, [documentData]);

  const onOk = async () => {
    setIsLoading(true);

    // TODO: refactor with the BE to call a single mutation
    // under the hood bulkAssociateDocumentsToProject calls associateDocumentsToProject
    // document can be an empty string
    if (documentId) {
      await dispatch(
        associateDocumentsToProject({
          projectId: selectedProjectId,
          documentIds: [documentId],
          appliesToAllProjects: isAppliedToAllProjects,
        }),
      );

      message.success(
        `Document successfully associated to the selected project.`,
      );
    } else {
      const filterQuery = !areAllDocumentsSelected
        ? {
            ids: documents,
          }
        : documentsFilter;

      await dispatch(
        bulkAssociateDocumentsToProject({
          filterQuery,
          projectId: selectedProjectId,
          appliesToAllProjects: isAppliedToAllProjects,
        }),
      );

      message.success(
        `${pluralize(
          'document',
          selectedDocumentsCount,
          true,
        )} scheduled to associate to project`,
      );
    }
    setIsLoading(false);

    trackEvent('User associated documents to a project');

    if (onSuccess) {
      onSuccess();
    }
  };

  const debOnChangeHandle = useMemo(
    () => debounce((value) => fetchViewedProjects(value), 200),
    [fetchViewedProjects],
  );

  const onApplyToAllProjects = () => {
    if (!isAppliedToAllProjects) {
      setSelectedProjectId(null);
      setSearchValue('');
    }
    setIsAppliedToAllProjects(!isAppliedToAllProjects);
  };

  return (
    <Modal
      destroyOnClose
      title={`Associate ${
        documentId
          ? documentData?.friendlyName
          : pluralize('document', selectedDocumentsCount, true)
      } to project`}
      open={visible}
      onOk={onOk}
      okButtonProps={{
        disabled: (!selectedProjectId && !isAppliedToAllProjects) || isLoading,
        loading: isLoading,
        //@ts-expect-error
        'data-cy': 'associateToProjectModalOkButton',
      }}
      okText={isAppliedToAllProjects ? 'Apply' : 'Associate to project'}
      onCancel={onCancel}
    >
      <FullWidthSpace direction="vertical" size="middle">
        <StyledAutoComplete<string>
          disabled={isAppliedToAllProjects}
          data-cy="associateToProjectSelectProjectInput"
          options={projects.map(({ _id, name }) => ({
            value: _id,
            label: name,
          }))}
          allowClear
          onSearch={(value) => {
            setSearchValue(value);
            debOnChangeHandle(value);
            setSelectedProjectId(null);
          }}
          onSelect={(projectId) => {
            setSelectedProjectId(projectId);
            setSearchValue(
              //@ts-ignore
              R.prop('name', R.find(R.propEq('_id', projectId), projects)),
            );
          }}
          value={searchValue}
        >
          <Input.Search placeholder={'Search projects...'} />
        </StyledAutoComplete>
        <Row align="middle" justify="space-between">
          <Col>Applies to all projects</Col>
          <Col>
            <Switch
              data-cy="isAppliedToAllProjects"
              checked={isAppliedToAllProjects}
              onChange={onApplyToAllProjects}
            />
          </Col>
        </Row>
      </FullWidthSpace>
    </Modal>
  );
};

const StyledAutoComplete = styled(AutoComplete)`
  width: 100%;
  margin-top: 5px;
` as typeof AutoComplete;

export default AssociateToProjectModal;
