import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type { DeleteContextRecordAttachmentsInput } from '@graphql/types/graphql';

const DELETE_CONTEXT_RECORD_ATTACHMENTS_MUTATION = graphql(`
  mutation DeleteContextRecordAttachments($data: DeleteContextRecordAttachmentsInput!) {
    deleteContextRecordAttachments(data: $data)
  }
`);

export const useDeleteContextRecordAttachmentsMutation = (
  contextRecordId: string,
) => {
  const [
    deleteContextRecordAttachmentsMutation,
    { loading: deletingContextRecordAttachments },
  ] = useMutation(DELETE_CONTEXT_RECORD_ATTACHMENTS_MUTATION);

  const deleteContextRecordAttachments = (
    filter: DeleteContextRecordAttachmentsInput['filter'],
  ) => {
    return deleteContextRecordAttachmentsMutation({
      variables: {
        data: {
          contextRecordId,
          filter,
        },
      },
    });
  };

  return {
    deleteContextRecordAttachments,
    deletingContextRecordAttachments,
  };
};
