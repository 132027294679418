import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type { UpdateDocumentFormInput } from '@graphql/types/graphql';

const UPDATE_DOCUMENT_FORM_QUERY = graphql(`
  mutation FillableFormSignContainer_UpdateDocumentForm($data: UpdateDocumentFormInput!) {
    updateDocumentForm(data: $data) {
      _id
      url
      fillableForm {
        _id
        status
      }
    }
  }
`);

export const useUpdateDocumentFormMutation = () => {
  const [updateDocumentFormMutation, { loading }] = useMutation(
    UPDATE_DOCUMENT_FORM_QUERY,
  );

  const updateDocumentForm = async (data: UpdateDocumentFormInput) => {
    const res = await updateDocumentFormMutation({
      variables: { data },
      context: {
        hasUpload: !!data.documentFile,
      },
    });
    return res.data?.updateDocumentForm;
  };

  return {
    updateDocumentForm,
    isUpdatingDocumentForm: loading,
  };
};
