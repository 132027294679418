import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type { SendPrimaryRecordsRequestInput } from '@graphql/types/graphql';

const MUTATION = graphql(`
  mutation SendRequestDrawer_SendRequest($input: SendPrimaryRecordsRequestInput!) {
    sendPrimaryRecordsRequest(data: $input) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useSendRequestMutation = () => {
  const [mutate, { loading }] = useMutation(MUTATION);

  const sendRequest = (input: SendPrimaryRecordsRequestInput) => {
    return mutate({ variables: { input } });
  };

  return {
    sendRequest,
    isSendRequestLoading: loading,
  };
};
