import { FullWidthSpace } from '@trustlayer/ui';
import type React from 'react';
import styled from 'styled-components';

interface FlexProps {
  justify?:
    | 'start'
    | 'end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
}

// TODO: Remove this component once we update to antd >= 5.10
export const Flex = (
  props: FlexProps & React.ComponentPropsWithoutRef<typeof FullWidthSpace>,
) => {
  return <FlexDiv {...props} />;
};

const FlexDiv = styled(FullWidthSpace)<FlexProps>`
  justify-content: ${({ justify }) => justify};
`;

export default Flex;
