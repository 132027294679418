import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Spinner from '@common/components/Spinner';
import { fetchEmailTemplate } from '@modules/email-template/actions';
import EmailTemplateEditForm from '@modules/email-template/forms/EmailTemplateEditForm';
import { getEmailTemplate } from '@modules/email-template/selectors';
import { getActiveOrganizationId } from '@modules/organization/selectors';
import { Form } from 'antd';
import styled from 'styled-components';

const CustomizeEmailTemplateContainer = ({ onChange, templateId }) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const activeOrganizationId = useSelector(getActiveOrganizationId);
  const template = useSelector((state) => getEmailTemplate(state, templateId));
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadEmailTemplate = async () => {
      setIsLoading(true);
      await dispatch(fetchEmailTemplate({ payload: templateId }));
      setIsLoading(false);
    };

    loadEmailTemplate();
  }, [dispatch, templateId]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: More Deps Than Needed
  useEffect(() => {
    form.resetFields();
  }, [template, form]);

  const handleOnFormChange = (_, { customTemplate }) => {
    onChange({
      _id: templateId,
      organization: activeOrganizationId,
      type: template?.type,
      ...customTemplate,
    });
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <StyledForm
      form={form}
      layout="vertical"
      onValuesChange={handleOnFormChange}
    >
      <EmailTemplateEditForm
        template={template}
        layout={template?.layout}
        namePrefix={['customTemplate']}
      />
    </StyledForm>
  );
};

export default CustomizeEmailTemplateContainer;

const StyledForm = styled(Form)`
  label {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
  }
`;
