import * as R from 'ramda';

import { DEFAULT_PROJECT_FIELDS } from '../constants';

export const normalizeProjectToServer = (project) =>
  R.compose(
    (currentProject) => ({
      ...currentProject,
      customFields:
        project.customFields?.nodes.map((customField) => ({
          customField: customField.customField._id,
          value: customField.value,
        })) || [],
      partiesData: project.parties.map((party) => ({
        partyId: party._id,
        complianceProfileId: R.path(
          ['partyComplianceProfile', 'complianceProfile'],
          party,
        ),
      })),
      attachments:
        project.attachments?.map(({ url, ...attachment }) => attachment) || [],
    }),
    R.omit(['parties']),
    R.pick(DEFAULT_PROJECT_FIELDS),
  )(project);
