import type { PrimaryRecord } from '@graphql/types/graphql';
import { RequestsTable } from '@modules/request-records/containers/RequestsTable';
import type { PrimaryRecordsRoutesParams } from '@modules/router/types';
import { RECORD_TYPES } from '@trustlayer/common';
import { useOutletContext, useParams } from 'react-router-dom';

export function PrimaryRecordRequests() {
  const { recordId } = useParams<PrimaryRecordsRoutesParams>();

  const { primaryRecord } = useOutletContext<{
    primaryRecord?: PrimaryRecord;
  }>();

  return (
    <RequestsTable
      recordId={recordId!}
      source={RECORD_TYPES.Primary}
      relatedRecordStatus={primaryRecord?.status}
    />
  );
}
