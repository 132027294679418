import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ModalConfirm } from '@common/components/ModalConfirm';
import { BulkOperationStatus } from '@graphql/types/graphql';
import { useDeleteRequestRecordMutation } from '@modules/request-records/hooks/useDeleteRequestRecordMutation';
import { message } from 'antd';

type DeleteRequestRecordModalProps = {
  open: boolean;
  requestRecord: {
    _id?: string;
    name?: string;
  };
  onCancel?: () => void;
  onCompleted?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
};

export const DeleteRequestRecordModal = ({
  open,
  requestRecord,
  onCancel,
  onCompleted,
  onScheduled,
  onFailed,
}: DeleteRequestRecordModalProps) => {
  const { deleteRequestRecord, isDeletingRequestRecord } =
    useDeleteRequestRecordMutation();

  const handleDelete = async () => {
    if (!requestRecord._id) return;

    const res = await deleteRequestRecord(requestRecord._id);

    const status = res.data?.deleteRequestRecords.operation.status;

    if (status === BulkOperationStatus.Completed) {
      message.success(`${requestRecord.name} deleted successfully.`);
      onCompleted?.();
    }

    if (status === BulkOperationStatus.Scheduled) {
      message.success(`${requestRecord.name} has been scheduled for deletion.`);
      onScheduled?.();
    }

    if (status === BulkOperationStatus.Failed) {
      message.error(
        `The deletion of your ${requestRecord.name} has failed. Please try again or contact support.`,
      );
      onFailed?.();
    }
  };

  return (
    <ModalConfirm
      data-cy="delete-request-record-modal"
      open={open}
      icon={<ExclamationCircleOutlined />}
      okButtonProps={{
        danger: true,
        loading: isDeletingRequestRecord,
      }}
      okText="Delete"
      title={`Are you sure you want to delete ${requestRecord.name}?`}
      onCancel={onCancel}
      onOk={handleDelete}
    >
      <p>This action cannot be undone</p>
    </ModalConfirm>
  );
};
