import { getDownloads } from '../selectors';

import { useShallowEqualSelector } from '@common/hooks/useShallowEqualSelector';
import { Progress, notification } from 'antd';

const DownloadList = () => {
  const downloads = useShallowEqualSelector(getDownloads);
  const [progressNotification, contextHolder] = notification.useNotification();

  return (
    <>
      {contextHolder}
      {downloads.forEach((d) => {
        progressNotification.open({
          key: d.id,
          message: 'Download in progress...',
          description: <Progress percent={d.percentage} />,
          duration: d.percentage === 100 ? 1 : 0,
          placement: 'bottomRight',
          className: 'data-cy_ProgressNotification',
        });
      })}
    </>
  );
};

export default DownloadList;
