import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const CONTEXT_RECORD_QUERY = graphql(`
  query ContextRecord_MakeActiveContextRecordModal(
    $input: ContextRecordInput!
  ) {
    contextRecord(input: $input) {
      _id
      name
    }
  }
`);

export function useContextRecordQuery({
  id,
  skip,
}: {
  id: string;
  skip: boolean;
}) {
  const res = useQuery(CONTEXT_RECORD_QUERY, {
    variables: {
      input: {
        id,
      },
    },
    skip,
  });

  const { data, ...rest } = res;

  return {
    contextRecord: data?.contextRecord,
    ...rest,
  };
}
