import { parseCurrency, parseNumber } from '@trustlayer/common';
import * as R from 'ramda';

import {
  getRequirementsByModuleSubject,
  getSubjectsByModule,
  getUniqModules,
} from '@common/utils/compliance-attributes-helpers';
import {
  Operator,
  financialStrengthValues,
  ratingValues,
} from '@modules/compliance-profile/constants';
import {
  getDetectedValue,
  getEffectiveDate,
  getExpirationDate,
} from '@modules/document/utils/document-helpers';

import {
  FinancialSizeCategoryData,
  RequirementAttributeType,
  RequirementStatus,
} from '../constants';

/**
 * Takes multiple groups of requirements and merges them from left to right,
 * producing a group that also preserves the `isActive` flag.
 */
export const mergeByAttributeId = (...requirementsGroups) => {
  return R.compose(
    R.reduce((acc, val) => {
      const index = R.findIndex((el) => el.attributeId === val.attributeId)(
        acc,
      );
      // When replacing an existing item, we preserve the `isActive` flag.
      return ~index
        ? R.update(
            index,
            R.assoc('isActive', R.prop('isActive', acc[index]), val),
            acc,
          )
        : R.append(val, acc);
    }, []),
    R.flatten,
  )(requirementsGroups);
};

/**
 * Find most relevant target value for requirement.
 *
 * We found that this function is used to get the max target value between two equal requirements,
 * it is supposed to be possible when we have a custom field but it seems like it doesn't happen because
 * the associated requirement of the custom field is already merged in the requirement.
 * It looks like this function could be removed.
 */
export const findRequirementTargetValue = (
  requirements,
  attributeLabel,
  operator,
) =>
  requirements
    .filter(
      (x) => x.attributeLabel === attributeLabel && x.operator === operator,
    )
    .reduce(
      (accCurrentValue, requirement) =>
        Number(requirement.targetValue) > Number(accCurrentValue)
          ? requirement.targetValue
          : accCurrentValue,
      0,
    );

/**
 * Filter requirements list to get unique list of requirements with most relevant attribute.
 *
 * Since we could remove the function findRequirementTargetValue, if we decide to remove it,
 * then the flattenRequirements function will make no more sense to exist.
 */
export const flattenRequirements = (requirements) =>
  R.compose(
    R.uniq,
    R.map(
      ({
        subjectId,
        moduleId,
        attributeId,
        attributeType,
        attributeLabel,
        operator,
        requirementComplianceStatusValue,
      }) => ({
        subjectId,
        moduleId,
        attributeId,
        attributeType,
        attributeLabel,
        operator,
        requirementComplianceStatusValue,
        targetValue: findRequirementTargetValue(
          requirements,
          attributeLabel,
          operator,
        ),
      }),
    ),
  )(requirements);

/**
 * Filter requirements list by subject ID.
 */
export const filterRequirementsBySubject = R.curry((subjectId, requirements) =>
  requirements.filter((requirement) => requirement.subjectId === subjectId),
);

/**
 * Filter requirements list by compliance status.
 */
export const filterRequirementsByStatus = R.curry((status, requirements) =>
  requirements.filter(
    (requirement) => requirement.requirementComplianceStatusValue === status,
  ),
);

/**
 * Get compliance score by requirements.
 */
export const getRequirementsComplianceStats = (requirements) => {
  const uniqueRequirements = flattenRequirements(requirements);

  const totalRequirementsCount = uniqueRequirements.length;

  const compliantRequirementsCount = filterRequirementsByStatus(
    RequirementStatus.Compliant,
    uniqueRequirements,
  ).length;

  const waivedRequirementsCount = filterRequirementsByStatus(
    RequirementStatus.Waived,
    uniqueRequirements,
  ).length;

  const overriddenRequirementsCount = filterRequirementsByStatus(
    RequirementStatus.Overridden,
    uniqueRequirements,
  ).length;

  const nonCompliantRequirementsCount =
    totalRequirementsCount -
    compliantRequirementsCount -
    waivedRequirementsCount -
    overriddenRequirementsCount;

  const complianceScore =
    Math.round(
      ((compliantRequirementsCount +
        waivedRequirementsCount +
        overriddenRequirementsCount) /
        totalRequirementsCount) *
        100,
    ) || 0;

  return {
    complianceScore,
    compliantRequirementsCount,
    nonCompliantRequirementsCount,
    waivedRequirementsCount,
    overriddenRequirementsCount,
    totalRequirementsCount,
  };
};

/**
 * Get compliance score bu subjects.
 */
export const getSubjectsComplianceStats = (requirements) =>
  getUniqModules(requirements).reduce(
    (result, moduleData) => {
      const subjects = getSubjectsByModule(moduleData.moduleId, requirements);
      const totalSubjectsCount = subjects.length;
      const compliantSubjectsCount = subjects.filter((subjectData) => {
        const isCompliant =
          getRequirementsComplianceStats(
            getRequirementsByModuleSubject(
              moduleData.moduleId,
              subjectData.subjectId,
              requirements,
            ),
          ).nonCompliantRequirementsCount === 0;

        return isCompliant;
      }).length;

      return {
        totalSubjectsCount: result.totalSubjectsCount + totalSubjectsCount,
        compliantSubjectsCount:
          result.compliantSubjectsCount + compliantSubjectsCount,
      };
    },
    {
      totalSubjectsCount: 0,
      compliantSubjectsCount: 0,
    },
  );

/**
 * Return filtered requirements by document type.
 */
export const filterRequirementsByDocumentType = (
  filter,
  requirements,
  documentTypesEnum,
) => {
  if (!filter)
    return { filteredRequirements: requirements, otherRequirements: [] };

  const filteredRequirements = requirements.filter((requirement) =>
    R.propEq('moduleId', R.prop(filter, documentTypesEnum), requirement),
  );

  const otherRequirements = requirements.filter(
    (requirement) =>
      !R.propEq('moduleId', R.prop(filter, documentTypesEnum), requirement),
  );

  return { filteredRequirements, otherRequirements };
};

/**
 * Get active subjects with present data by document
 */
export const getActiveSubjects = (requirements, documentData) =>
  R.compose(
    R.uniq,
    R.map(({ subjectId }) => subjectId),
    R.filter((requirement) => {
      const { subjectId } = requirement;
      const hasEffectiveDate = Boolean(
        getEffectiveDate(documentData, subjectId),
      );
      const hasExpirationDate = Boolean(
        getExpirationDate(documentData, subjectId),
      );

      const detectedValue = getDetectedValue(documentData, requirement);
      const hasAttributeValue = detectedValue === 0 || Boolean(detectedValue);

      return hasEffectiveDate || hasExpirationDate || hasAttributeValue;
    }),
  )(requirements);

/**
 * Get parse value by attribute type.
 */
export const parseAttributeValue = (value, type) => {
  switch (type) {
    case RequirementAttributeType.Boolean:
    case RequirementAttributeType.Ai: {
      return Boolean(value) ? 'Present' : '';
    }
    case RequirementAttributeType.Number: {
      const amount = parseNumber(value);
      return amount !== null ? parseCurrency(amount) : null;
    }
    case RequirementAttributeType.AmBestRating: {
      return ratingValues[value];
    }
    case RequirementAttributeType.AmBestFinancialStrength: {
      return financialStrengthValues[value];
    }
    default: {
      return value;
    }
  }
};

export const valueMatchesRequirement = (value, requirement) => {
  const targetValue = R.prop('targetValue', requirement);
  const type = R.prop('attributeType', requirement);
  const sanitizedValue =
    typeof value === 'string'
      ? R.defaultTo('', value && String(value)).trim()
      : value;

  switch (type) {
    case RequirementAttributeType.AmBestRating:
    case RequirementAttributeType.AmBestFinancialStrength:
    case RequirementAttributeType.Number: {
      switch (requirement.operator) {
        case Operator.GREATER_OR_EQUAL:
          return parseNumber(sanitizedValue) >= parseNumber(targetValue);
        case Operator.LESS_OR_EQUAL:
          const val = parseNumber(sanitizedValue);
          return val !== null && val <= parseNumber(targetValue);
        default:
          throw new Error(
            `Unsupported operator for Number value: "${requirement.operator}"`,
          );
      }
    }
    case RequirementAttributeType.Ai:
    case RequirementAttributeType.Boolean:
    case RequirementAttributeType.FillableForm:
    case RequirementAttributeType.Connected: {
      return Boolean(sanitizedValue);
    }
    default: {
      return false;
    }
  }
};

/**
 * Given a list of attributes returns an array sorted with
 * those attributes first keeping the original order
 */
export const sortRequirementsByAttributeType = (
  attributeList = [],
  requirements = [],
) =>
  [...requirements].sort((a, b) => {
    const hasPrecedenceA = attributeList.includes(a.attributeType) ? -1 : 0;
    const hasPrecedenceB = attributeList.includes(b.attributeType) ? -1 : 0;
    return hasPrecedenceA - hasPrecedenceB;
  });

/**
 * convert financial size category to text information label.
 */
export const financialSizeCategoryToText = (financialSizeCategory) => {
  if (financialSizeCategory === 'I') {
    return `Less than 1`;
  }
  if (financialSizeCategory === 'XV') {
    return `($${R.pathOr(
      0,
      [financialSizeCategory, 'min'],
      FinancialSizeCategoryData,
    )} Million or greater)`;
  }
  return `($${R.pathOr(
    0,
    [financialSizeCategory, 'min'],
    FinancialSizeCategoryData,
  )} Million to ${R.pathOr(
    0,
    [financialSizeCategory, 'max'],
    FinancialSizeCategoryData,
  )} Million )`;
};

/**
 * Convert an integer into a Roman Numeral
 */

export const integerToRoman = (num) => {
  if (typeof num !== 'number') return '';

  const digits = String(+num).split('');
  const key = [
    '',
    'C',
    'CC',
    'CCC',
    'CD',
    'D',
    'DC',
    'DCC',
    'DCCC',
    'CM',
    '',
    'X',
    'XX',
    'XXX',
    'XL',
    'L',
    'LX',
    'LXX',
    'LXXX',
    'XC',
    '',
    'I',
    'II',
    'III',
    'IV',
    'V',
    'VI',
    'VII',
    'VIII',
    'IX',
  ];
  // eslint-disable-next-line fp/no-let
  let roman_num = '';
  // eslint-disable-next-line fp/no-let
  let i = 3;
  // eslint-disable-next-line fp/no-loops
  while (i--) roman_num = (key[+digits.pop() + i * 10] || '') + roman_num;
  return Array(+digits.join('') + 1).join('M') + roman_num;
};

export const getRatingLevel = (rating) => {
  switch (rating) {
    case 'A+':
    case 'A++':
    case 'A':
    case 'A-': {
      return 'level_1';
    }
    case 'B+':
    case 'B++':
    case 'B':
    case 'B-': {
      return 'level_2';
    }
    case 'C+':
    case 'C++':
    case 'C':
    case 'C-': {
      return 'level_3';
    }
    case 'D': {
      return 'level_4';
    }
    default: {
      return 'level_5';
    }
  }
};
