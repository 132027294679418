import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ModalConfirm } from '@common/components/ModalConfirm';
import { BulkOperationStatus } from '@graphql/types/graphql';
import { useArchiveRequestRecordMutation } from '@modules/request-records/hooks/useArchiveRequestRecordMutation';
import { Typography, message } from 'antd';

type ArchiveRequestRecordModalProps = {
  open: boolean;
  requestRecord: {
    _id: string;
    name: string;
  };
  onCancel?: () => void;
  onCompleted?: () => void;
};

export const ArchiveRequestRecordModal = ({
  open,
  requestRecord,
  onCancel,
  onCompleted,
}: ArchiveRequestRecordModalProps) => {
  const { archiveRequestRecord, isArchivingRequestRecord } =
    useArchiveRequestRecordMutation();

  const handleArchive = async () => {
    if (!requestRecord?._id) return;

    const res = await archiveRequestRecord(requestRecord._id);

    const status = res.data?.archiveRequestRecords.operation.status;

    if (status === BulkOperationStatus.Completed) {
      message.success('Request archived successfully.');
      onCompleted?.();
    }

    if (status === BulkOperationStatus.Failed) {
      message.error(
        'The archival of your request has failed. Please try again or contact support.',
      );
    }
  };

  return (
    <ModalConfirm
      data-cy="archive-request-record-modal"
      open={open}
      icon={<ExclamationCircleOutlined />}
      okButtonProps={{
        loading: isArchivingRequestRecord,
      }}
      okText="Archive"
      title={`Are you sure you want to archive ${requestRecord.name}?`}
      onCancel={onCancel}
      onOk={handleArchive}
    >
      <Typography.Paragraph>
        Please confirm that you want to archive {requestRecord.name}
      </Typography.Paragraph>
      <Typography.Paragraph>
        It will not show up in your reports and dashboard
      </Typography.Paragraph>
    </ModalConfirm>
  );
};
