import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { Modal, message } from 'antd';
import pluralize from 'pluralize';
import { useDeleteContextRecordAttachmentsMutation } from '../../hooks/useDeleteContextRecordAttachmentsMutation';

export const DeleteAttachmentsModal = ({
  open,
  filters,
  recordId,
  count,
  onCompleted,
  onCancel,
}: {
  open: boolean;
  filters: ServerFilterInput;
  recordId: string;
  count: number;
  onCompleted?: () => void;
  onCancel?: () => void;
}) => {
  const { deleteContextRecordAttachments, deletingContextRecordAttachments } =
    useDeleteContextRecordAttachmentsMutation(recordId);

  const handleOnOk = async () => {
    try {
      //? since is not a bulk operation, we don't need to check the status
      await deleteContextRecordAttachments(filters);

      message.success(
        `${count} ${pluralize('attachment', count)} deleted successfully.`,
      );
    } catch (error) {
      message.error('Failed to delete attachments');
    } finally {
      onCompleted?.();
    }
  };

  return (
    <Modal
      open={open}
      confirmLoading={deletingContextRecordAttachments}
      title={`Are you sure you want to delete ${count} ${pluralize('attachment', count)}?`}
      okText={'Delete attachments'}
      okType={'danger'}
      onOk={handleOnOk}
      onCancel={onCancel}
    />
  );
};
