import Spinner from '@common/components/Spinner';
import { RenderPreviewEmailTemplate } from '@modules/email-template/components/RenderPreviewEmailTemplate';
import { Form } from 'antd';
import { useRenderEmailTemplateQuery } from './hooks';

export const EmailPreview = ({
  primaryRecordId,
  skip = false,
}: {
  primaryRecordId: string;
  skip?: boolean;
}) => {
  const form = Form.useFormInstance();
  const customTemplate = Form.useWatch('customTemplate', form);
  const emailTemplateId = Form.useWatch('emailTemplateId', form);

  const { renderedEmailTemplate, isRenderingEmailTemplate } =
    useRenderEmailTemplateQuery({
      primaryRecordId,
      skip: skip || !emailTemplateId,
      template: {
        subject: customTemplate?.subject,
        regions: customTemplate?.regions,
        _id: emailTemplateId,
      },
    });

  if (isRenderingEmailTemplate) {
    return <Spinner />;
  }

  if (!renderedEmailTemplate) {
    return <>No template found.</>;
  }

  return <RenderPreviewEmailTemplate preview={renderedEmailTemplate} />;
};
