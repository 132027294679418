import { gql } from '@apollo/client';

import { PARTY_DATA_DOCUMENT_FRAGMENT } from './index';

export const PARTY_DATA_FRAGMENT = gql`
  fragment PartyData on PartyData {
    _id
    name
    type {
      _id
      name
    }
    isActive
    hasWaivedRequirements
    hasOverriddenRequirements
    automaticallyWaivedSubjects {
      subjectId
      status
    }
    automaticallyWaivedRequirements {
      attributeId
      status
    }
    deletedAt
    deletedBy
    status
    website
    isTrackingCompliance
    isSendingExpiringRequest
    address {
      rawAddress
      line1
      line2
      city
      region
      country
      postalCode
      latitude
      longitude
    }
    documents {
      ...PartyDataDocument
    }
    requests {
      _id
      deletedAt
      deletedBy
      forwardedAt
      forwardedTo
      sentByUser
      party
      message
      attachments {
        url
        friendlyName
        s3Key
      }
      documentChecklists
      sentOn
      organization
      dueDate
      project
      createdAt
      updatedAt
    }
    partyComplianceProfile {
      _id
      isProcessing
      party
      metadata
      complianceProfile
      name
      nextExpirationDate
      latestExpirationDate
      earliestExpirationDate
      complianceStats {
        complianceScore
        compliantRequirementsCount
        nonCompliantRequirementsCount
        waivedRequirementsCount
        overriddenRequirementsCount
        totalRequirementsCount
        totalSubjectsCount
        compliantSubjectsCount
      }
      createdAt
      updatedAt
    }
    subjects {
      subjectId
      subjectLabel
      notes
      requirementComplianceStatusValue
      resetRequirementOn
    }
    requirements {
      _id
      organization
      party
      partyComplianceProfile
      moduleId
      moduleLabel
      subjectId
      subjectLabel
      attributeId
      attributeLabel
      attributeType
      attributeDescription
      matchingCriteria
      automaticallyDetected
      masterDocumentAttributeId
      notes
      operator
      targetValue
      requirementComplianceStatusValue
      requirementComplianceStatusSource
      matchingDocumentsCount
      resetRequirementOn
      invalidatedBy
      invalidationScope
      publicNotes
      hidePublicNotesWhenCompliant
      createdAt
      updatedAt
    }
    documentChecklists {
      _id
      type {
        _id
        name
        pluralName
        isSystemType
      }
      attributeId
      attributeLabel
      complianceProfile
      isActive
      createdAt
      updatedAt
    }
    additionalNotes
    partyContacts {
      _id
      party
      contactPersonName
      companyName
      email
      phone
      fax
      title
      avatar
      isPrimary
      isDefaultRequestRecipient
      createdAt
      updatedAt
    }
    nonresponsiveSince
    lastActivityDate
    totalDocumentsCount
    totalDocumentsToReviewCount
    totalDocumentsReviewedCount
    totalApplicableDocumentsCount
    totalApplicableDocumentsReviewedCount
    totalApplicableDocumentsToReviewCount
    taggings {
      _id
      partyTag {
        _id
        name
        color
      }
      addedAt
      addedBy
      expiresAt
    }
    organization
    createdAt
    updatedAt
    lastRequestSentOn
    nearestFlagSeverityOrder
    externalIds
  }
  ${PARTY_DATA_DOCUMENT_FRAGMENT}
`;
