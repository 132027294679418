import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ModalConfirm } from '@common/components/ModalConfirm';
import { withControlledMount } from '@common/hoc/withControlledMount';
import { BulkOperationStatus } from '@graphql/types/graphql';
import { Typography, message } from 'antd';
import { useMakeActiveContextRecordMutation } from '../../hooks/useMakeActiveContextRecordMutation';
import { useContextRecordQuery } from './hooks/useContextRecordQuery';

type MakeActiveContextRecordModalProps = {
  open: boolean;
  contextRecordId: string;
  onCancel?: () => void;
  onCompleted?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
};

const MakeActiveContextRecordModalComponent = ({
  open,
  contextRecordId,
  onCancel,
  onCompleted,
  onScheduled,
  onFailed,
}: MakeActiveContextRecordModalProps) => {
  const { contextRecord, loading } = useContextRecordQuery({
    id: contextRecordId,
    skip: !open || !contextRecordId,
  });

  const { makeActiveContextRecord, isMakingActiveContextRecord } =
    useMakeActiveContextRecordMutation();

  const handleMakeActive = async () => {
    if (!contextRecordId) {
      throw new Error('Context record ID is required');
    }

    const res = await makeActiveContextRecord(contextRecordId);

    const status = res.data?.activateContextRecords.operation.status;

    if (status === BulkOperationStatus.Completed) {
      message.success(`${contextRecord?.name} is now active.`);
      onCompleted?.();
    }

    if (status === BulkOperationStatus.Scheduled) {
      message.success(
        `${contextRecord?.name} has been scheduled for activation.`,
      );
      onScheduled?.();
    }

    if (status === BulkOperationStatus.Failed) {
      message.error(
        `The activation of ${contextRecord?.name} has failed. Please try again or contact support.`,
      );
      onFailed?.();
    }
  };

  return (
    <ModalConfirm
      loading={loading}
      data-cy="make-active-context-record-modal"
      open={open}
      icon={<ExclamationCircleOutlined />}
      okButtonProps={{
        loading: isMakingActiveContextRecord,
      }}
      okText="Make active"
      title={`Are you sure you want to make active ${contextRecord?.name}?`}
      onCancel={onCancel}
      onOk={handleMakeActive}
    >
      <Typography.Paragraph>
        Please confirm that you want to make active {contextRecord?.name}
      </Typography.Paragraph>
      <ul>
        <li>It will show up in your reports and dashboard</li>
        <li>Automatic requests for expired documents will be sent</li>
      </ul>
    </ModalConfirm>
  );
};

export const MakeActiveContextRecordModal = withControlledMount(
  MakeActiveContextRecordModalComponent,
);
