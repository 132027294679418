import { ThreeDotsMenu, type ThreeDotsMenuItem } from '@trustlayer/ui';
import { Button, type ButtonProps } from 'antd';
import styled from 'styled-components';

type CellActionsProps = {
  children: React.ReactNode;
};

type ThreeDotsMenuProps = {
  menuItems: ThreeDotsMenuItem[];
};

export const CellActions = ({ children }: CellActionsProps) => {
  return <StyledActions>{children}</StyledActions>;
};

CellActions.Button = (props: ButtonProps) => <Button {...props} />;

CellActions.ThreeDotsMenu = ({ menuItems }: ThreeDotsMenuProps) => (
  <ThreeDotsMenu border items={menuItems} />
);

const StyledActions = styled.div`
  height: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
`;
