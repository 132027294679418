import {
  BulkOperationStatus,
  type ContextRecord,
  type ReportEntity as ViewEntity,
  ReportType as ViewType,
} from '@graphql/types/graphql';
import styled from 'styled-components';

import { PlusOutlined } from '@ant-design/icons';
import { ActionsMenuWithButtons } from '@common/components/ActionsMenuWithButtons';
import { PageTitle } from '@common/components/PageTitleWrapper';
import RefreshDataBanner from '@common/components/RefreshDataBanner';
import SearchInput from '@common/components/SearchInput';
import SelectionDropdown from '@common/components/SelectionDropdown';
import {
  type CustomSelectionChangedEvent,
  FilterButton,
  SortButton,
  type SortDirection,
  type SortingItems,
} from '@common/components/Table';
import { TableHeader } from '@common/components/TableHeader';
import useSetState from '@common/hooks/useSetState';
import { mapTableFiltersToServerFilters } from '@common/utils/filters';
import { getIdsFilter } from '@common/utils/filters/getIdsSelectionFilter';
import { getAntdIconOutlinedByName } from '@common/utils/get-antd-icon-by-name';
import {
  ArchiveContextRecordModal,
  ArchiveContextRecordsModal,
  CONTEXT_RECORDS_ACTION_TYPES,
  ContextRecordsTable,
  type ContextRecordsTableRef,
  CreateContextRecordModal,
  type CreateContextRecordModalState,
  DeleteContextRecordModal,
  DeleteContextRecordsModal,
  MakeActiveContextRecordModal,
  MakeActiveContextRecordsModal,
  type TableFiltersModel,
  getActionsMenuItems,
} from '@modules/context-records';
import {
  CONTEXT_RECORDS_TABLE_FIELDS,
  CONTEXT_RECORD_STATUS,
} from '@modules/context-records';
import {
  type ActionType,
  CONTEXT_RECORDS_ASYNC_OPERATIONS_NAMES,
} from '@modules/context-records/constants';
import {
  FILTERABLE_COLUMN_FIELDS,
  SERVER_FILTERS_CONFIG,
} from '@modules/context-records/containers/ContextRecordsTable/constants';
import { useOperationsUpdates } from '@modules/organization/hooks/useOperationsUpdates';
import type { LegacyProjectReportsParams } from '@modules/report/types';
import { DEFAULT_VIEWS, ViewsSelector } from '@modules/view';
import { Button, Skeleton, Space } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useContextObjectQuery } from './hooks/useContextObjectQuery';
import { mapLegacyProjectReportsToTableParams } from './utils/mapLegacyProjectReportsToTableParams';
import { mapTableParamsToLegacyProjectParams } from './utils/mapTableParamsToLegacyProjectParams';

export type ContextRecordsTableState = {
  filters: TableFiltersModel;
  sorting: {
    columnId?: string;
    direction?: SortDirection;
  };
  pagination: {
    currentPage?: number;
    pageSize?: number;
  };
  viewId?: string;
};

type SelectionState = {
  isSelectAllChecked: boolean;
  selectedContextRecordsIds: string[];
  selectedContextRecordId?: string;
  selectedRowsData: any[];
};

const filterInitialState = {
  status: {
    filterType: 'set' as const,
    values: [CONTEXT_RECORD_STATUS.Active],
  },
};

const sortingInitialState = {
  columnId: CONTEXT_RECORDS_TABLE_FIELDS.compliance,
  direction: 'desc' as const,
};

const paginationInitialState = {
  pageSize: 12,
  currentPage: 0,
};

const DEFAULT_TABLE_STATE = {
  filters: filterInitialState,
  sorting: sortingInitialState,
  pagination: paginationInitialState,
  viewId: undefined,
};

const initState = (
  initialState?: ContextRecordsTableState,
): ContextRecordsTableState => {
  if (!initialState) {
    return DEFAULT_TABLE_STATE;
  }

  if (isEmpty(initialState.filters)) {
    return DEFAULT_TABLE_STATE;
  }

  return {
    ...initialState,
    filters: initialState.filters,
  };
};

const createRecordModalInitialState = {
  visible: false,
  contextObjectId: '',
  contextObjectName: '',
};

export const ContextObject = () => {
  const { objectSlug } = useParams<{ objectSlug: string }>();
  const tableRef = useRef<ContextRecordsTableRef>(null);
  const [areBulkActionsButtonsVisible, setAreBulkActionsButtonsVisible] =
    useState(false);

  const [tableState, setTableState] = useSetState<ContextRecordsTableState>(
    initState(),
  );

  const [createContextRecordModalState, setCreateContextRecordModalState] =
    useSetState<CreateContextRecordModalState>(createRecordModalInitialState);

  const [selection, setSelection] = useSetState<SelectionState>({
    isSelectAllChecked: false,
    selectedContextRecordsIds: [],
    selectedContextRecordId: undefined,
    selectedRowsData: [],
  });

  const [counters, setCounters] = useSetState({
    totalRows: 0,
    selectedRows: 0,
    visibleRows: 0,
    activeFilters: 0,
  });

  const inputSearchValue =
    tableState.filters[FILTERABLE_COLUMN_FIELDS.name]?.filter || '';

  const [visibleColumns, setVisibleColumns] = useState<SortingItems>([]);

  const [isBulkArchiveModalVisible, setIsBulkArchiveModalVisible] =
    useState(false);

  const [isBulkDeleteModalVisible, setIsBulkDeleteModalVisible] =
    useState(false);

  const [isBulkMakeActiveModalVisible, setIsBulkMakeActiveModalVisible] =
    useState(false);

  const [singleArchiveModalState, setSingleArchiveModalState] = useSetState<{
    visible: boolean;
    contextRecordId: string;
  }>({
    visible: false,
    contextRecordId: '',
  });

  const [singleMakeActiveModalState, setSingleMakeActiveModalState] =
    useSetState<{
      visible: boolean;
      contextRecordId: string;
    }>({
      visible: false,
      contextRecordId: '',
    });

  const [singleDeleteModalState, setSingleDeleteModalState] = useSetState<{
    visible: boolean;
    contextRecordId: string;
    contextRecordName: string;
  }>({
    visible: false,
    contextRecordId: '',
    contextRecordName: '',
  });

  const [shouldRefreshData, setShouldRefreshData] = useState(false);

  useOperationsUpdates({
    operationNames: Object.values(CONTEXT_RECORDS_ASYNC_OPERATIONS_NAMES),
    operationStatuses: [BulkOperationStatus.Completed],
    onUpdate: () => {
      setShouldRefreshData(true);
    },
  });

  const { contextObject, isContextObjectLoading } =
    useContextObjectQuery(objectSlug);

  const viewParams = useMemo(() => {
    return mapTableParamsToLegacyProjectParams({
      filters: tableState.filters,
      sorting: tableState.sorting,
    });
  }, [tableState.filters, tableState.sorting]);

  const handleOnFiltersChanged = (params: {
    activeFiltersCount: number;
    activeFilters: TableFiltersModel;
  }) => {
    setCounters({
      activeFilters: params.activeFiltersCount,
    });

    setTableState({
      filters: params.activeFilters,
    });
  };

  const handleOnSelectionChanged = (params: CustomSelectionChangedEvent) => {
    setAreBulkActionsButtonsVisible(
      params.isSelectAllChecked || params.selectedIds.length > 0,
    );

    setSelection({
      isSelectAllChecked: params.isSelectAllChecked,
      selectedContextRecordsIds: params.selectedIds,
      selectedRowsData: params.selectedRowsData,
    });

    setCounters({
      totalRows: params.rowsTotalCount,
      selectedRows: params.isSelectAllChecked
        ? params.rowsTotalCount
        : params.selectedIds.length,
      visibleRows: params.visibleRowsCount,
    });
  };

  const handleOnPaginationChanged = (params: any) => {
    setTableState({
      pagination: params,
    });
  };

  const handleBulkActionClick = (code: ActionType) => {
    switch (code) {
      case CONTEXT_RECORDS_ACTION_TYPES.archive:
        setIsBulkArchiveModalVisible(true);
        break;
      case CONTEXT_RECORDS_ACTION_TYPES.delete:
        setIsBulkDeleteModalVisible(true);
        break;
      case CONTEXT_RECORDS_ACTION_TYPES.makeActive:
        setIsBulkMakeActiveModalVisible(true);
        break;
      default:
        break;
    }
  };

  const bulkActionsMenuItems = useMemo(() => getActionsMenuItems({}), []);

  const handleSelectAll = () => {
    tableRef.current?.selectAllRows();
  };

  const handleSelectVisible = () => {
    tableRef.current?.selectVisibleRows();
  };

  const handleTableSelectionReset = () => {
    tableRef.current?.resetSelection();
  };

  const handleOnSortChanged = (sorting: {
    columnId?: string;
    direction?: SortDirection;
  }) => {
    setTableState({
      sorting: sorting || sortingInitialState,
    });
  };

  const handleRecordActionClick = (
    code: ActionType,
    { _id, name }: ContextRecord,
  ) => {
    switch (code) {
      case CONTEXT_RECORDS_ACTION_TYPES.archive:
        setSingleArchiveModalState({
          visible: true,
          contextRecordId: _id,
        });
        break;
      case CONTEXT_RECORDS_ACTION_TYPES.delete:
        setSingleDeleteModalState({
          visible: true,
          contextRecordId: _id,
          contextRecordName: name,
        });
        break;
      case CONTEXT_RECORDS_ACTION_TYPES.makeActive:
        setSingleMakeActiveModalState({
          visible: true,
          contextRecordId: _id,
        });
        break;
      default:
        break;
    }
  };

  const refreshData = () => {
    setShouldRefreshData(false);
    tableRef?.current?.refreshTable();
  };

  const handleCreateContextRecord = () => {
    if (!contextObject) return;

    setCreateContextRecordModalState({
      visible: true,
      contextObjectId: contextObject._id,
      contextObjectName: contextObject.name,
    });
  };

  const bulkActionFilterValue = useMemo(() => {
    return selection.isSelectAllChecked
      ? mapTableFiltersToServerFilters({
          tableFilters: tableState.filters,
          serverFiltersConfig: SERVER_FILTERS_CONFIG,
        })
      : getIdsFilter(selection.selectedContextRecordsIds);
  }, [
    selection.isSelectAllChecked,
    selection.selectedContextRecordsIds,
    tableState.filters,
  ]);

  const handleChangeView = useCallback(
    ({
      viewId,
      viewParams,
    }: {
      viewId?: string;
      viewParams: LegacyProjectReportsParams;
    }) => {
      const { filters, sorting } = mapLegacyProjectReportsToTableParams(
        viewParams || {},
      );

      setTableState({ viewId, filters, sorting });

      tableRef?.current?.setFilters(filters);
      tableRef?.current?.setSorting(
        sorting.columnId || sortingInitialState.columnId,
        sorting.direction || sortingInitialState.direction,
      );
    },
    [setTableState],
  );

  const handleDeleteView = () => {
    setTableState({
      viewId: undefined,
      sorting: sortingInitialState,
      filters: filterInitialState,
    });

    tableRef?.current?.setFilters(filterInitialState);
  };

  return (
    <StyledWrapper>
      <ArchiveContextRecordsModal
        open={isBulkArchiveModalVisible}
        count={counters.selectedRows}
        contextObject={{
          name: contextObject?.name ?? '',
          pluralName: contextObject?.pluralName ?? '',
        }}
        filters={bulkActionFilterValue}
        onCancel={() => setIsBulkArchiveModalVisible(false)}
        onCompleted={() => {
          tableRef.current?.refreshTable();
          tableRef.current?.resetSelection();
          setIsBulkArchiveModalVisible(false);
        }}
        onScheduled={() => {
          tableRef.current?.resetSelection();
          setIsBulkArchiveModalVisible(false);
        }}
      />

      <ArchiveContextRecordModal
        open={singleArchiveModalState.visible}
        contextRecordId={singleArchiveModalState.contextRecordId}
        onCancel={() => setSingleArchiveModalState({ visible: false })}
        onCompleted={() => {
          tableRef.current?.refreshTable();
          tableRef.current?.resetSelection();
          setSingleArchiveModalState({ visible: false });
        }}
      />
      <DeleteContextRecordModal
        open={singleDeleteModalState.visible}
        contextRecord={{
          _id: singleDeleteModalState.contextRecordId,
          name: singleDeleteModalState.contextRecordName,
        }}
        onCancel={() => setSingleDeleteModalState({ visible: false })}
        onCompleted={() => {
          setSingleDeleteModalState({ visible: false });
          tableRef.current?.refreshTable();
          tableRef.current?.resetSelection();
        }}
      />
      <DeleteContextRecordsModal
        open={isBulkDeleteModalVisible}
        count={counters.selectedRows}
        filters={bulkActionFilterValue}
        contextObject={{
          name: contextObject?.name ?? '',
          pluralName: contextObject?.pluralName ?? '',
        }}
        onCancel={() => setIsBulkDeleteModalVisible(false)}
        onCompleted={() => {
          tableRef.current?.refreshTable();
          tableRef.current?.resetSelection();
          setIsBulkDeleteModalVisible(false);
        }}
        onScheduled={() => {
          tableRef.current?.resetSelection();
          setIsBulkDeleteModalVisible(false);
        }}
      />

      <MakeActiveContextRecordModal
        open={singleMakeActiveModalState.visible}
        contextRecordId={singleMakeActiveModalState.contextRecordId}
        onCancel={() => setSingleMakeActiveModalState({ visible: false })}
        onCompleted={() => {
          tableRef.current?.refreshTable();
          tableRef.current?.resetSelection();
          setSingleMakeActiveModalState({ visible: false });
        }}
      />
      <MakeActiveContextRecordsModal
        open={isBulkMakeActiveModalVisible}
        count={counters.selectedRows}
        contextObject={{
          name: contextObject?.name ?? '',
          pluralName: contextObject?.pluralName ?? '',
        }}
        filters={bulkActionFilterValue}
        onCancel={() => setIsBulkMakeActiveModalVisible(false)}
        onCompleted={() => {
          tableRef.current?.refreshTable();
          tableRef.current?.resetSelection();
          setIsBulkMakeActiveModalVisible(false);
        }}
        onScheduled={() => {
          tableRef.current?.resetSelection();
          setIsBulkMakeActiveModalVisible(false);
        }}
        onFailed={() => {
          setIsBulkMakeActiveModalVisible(false);
        }}
      />

      <CreateContextRecordModal
        modalState={createContextRecordModalState}
        onCompleted={() => {
          tableRef.current?.refreshTable();
          setCreateContextRecordModalState((currentState) => ({
            ...currentState,
            visible: false,
          }));
        }}
        onCancel={() => {
          setCreateContextRecordModalState((currentState) => ({
            ...currentState,
            visible: false,
          }));
        }}
      />
      <TableHeader>
        {isContextObjectLoading ? (
          <PageTitle.Skeleton />
        ) : (
          <PageTitle
            title={contextObject?.pluralName}
            icon={getAntdIconOutlinedByName(contextObject?.icon)}
          />
        )}

        <TableHeader.TopRightActions>
          {isContextObjectLoading ? (
            <Skeleton.Input style={{ width: 30 }} active />
          ) : (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleCreateContextRecord}
            >
              New {contextObject?.name}
            </Button>
          )}
        </TableHeader.TopRightActions>

        {areBulkActionsButtonsVisible ? (
          <TableHeader.BottomLeftActions>
            <Space>
              <SelectionDropdown
                areAllSelected={selection.isSelectAllChecked}
                selectedEntitiesCount={counters.selectedRows}
                onSelectAll={handleSelectAll}
                onSelectAllVisible={handleSelectVisible}
                onSelectNone={handleTableSelectionReset}
                totalCount={counters.totalRows}
                totalVisible={counters.visibleRows}
              />
            </Space>
            <ActionsMenuWithButtons
              items={bulkActionsMenuItems}
              onClick={handleBulkActionClick}
            />
          </TableHeader.BottomLeftActions>
        ) : (
          <>
            <TableHeader.BottomLeftActions>
              <StyledSearchInput
                key={inputSearchValue}
                defaultValue={inputSearchValue}
                onSearch={(value) => {
                  tableRef.current?.setFilters(
                    {
                      name: {
                        filterType: 'text',
                        filter: value,
                        type: 'contains',
                      },
                    },
                    tableState.filters,
                  );
                }}
              />

              <ViewsSelector
                type={ViewType.Projects}
                defaultView={
                  DEFAULT_VIEWS.contextObject(
                    contextObject?.pluralName ?? '',
                  ) as ViewEntity
                }
                viewParams={viewParams}
                viewId={tableState.viewId}
                onCreated={handleChangeView}
                onDeleted={handleDeleteView}
                onReset={handleDeleteView}
                onSelectChanged={handleChangeView}
              />
            </TableHeader.BottomLeftActions>
            <TableHeader.BottomRightActions>
              <FilterButton
                activeFiltersCount={counters.activeFilters}
                onClick={() => {
                  tableRef?.current?.toggleFiltersPanel();
                }}
              />
              <SortButton
                items={visibleColumns}
                initialValues={tableState.sorting}
                onChange={(sort) => {
                  tableRef.current!.setSorting(sort.columnId, sort.direction);
                }}
                onClick={() => {
                  const columns = tableRef?.current
                    ?.getSortableColumns()
                    ?.sort((a, b) => (a.name > b.name ? 1 : -1));
                  setVisibleColumns(columns || []);
                }}
              />
            </TableHeader.BottomRightActions>
          </>
        )}
      </TableHeader>
      {shouldRefreshData && <RefreshDataBanner onRefreshClick={refreshData} />}
      <StyledTable>
        <ContextRecordsTable
          contextObjectSlug={objectSlug!}
          ref={tableRef}
          onSelectionChanged={handleOnSelectionChanged}
          onFilterChanged={handleOnFiltersChanged}
          onSortChanged={handleOnSortChanged}
          onPaginationChanged={handleOnPaginationChanged}
          onRowActionClick={handleRecordActionClick}
          tableState={tableState}
        />
      </StyledTable>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTable = styled.section`
  padding: 24px;
  flex: 1;
`;

const StyledSearchInput = styled(SearchInput)`
  width: 280px;
`;
