import { FILTER_OPERATORS, FILTER_TYPES } from '@common/constants/filters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const MAKE_ACTIVE_CONTEXT_RECORD_MUTATION = graphql(`
  mutation MakeActiveContextRecordModal($data: ActivateContextRecordsInput!) {
    activateContextRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useMakeActiveContextRecordMutation = () => {
  const [
    makeActiveContextRecordMutation,
    { loading: isMakingActiveContextRecord },
  ] = useMutation(MAKE_ACTIVE_CONTEXT_RECORD_MUTATION);

  const makeActiveContextRecord = (id: string) => {
    return makeActiveContextRecordMutation({
      variables: {
        data: {
          filter: {
            and: [
              {
                name: FILTER_TYPES.id,
                operator: FILTER_OPERATORS.in,
                value: [id],
              },
            ],
          },
        },
      },
    });
  };

  return {
    makeActiveContextRecord,
    isMakingActiveContextRecord,
  };
};
