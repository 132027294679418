export const namespace = 'FEATURE_FLAGS';
export const STATE_KEY = 'feature-flags';

export const unleashConfig = {
  url: import.meta.env.VITE_UNLEASH_PROXY_URL,
  clientKey: import.meta.env.VITE_UNLEASH_PROXY_CLIENT_KEYS,
  environment: import.meta.env.VITE_UNLEASH_ENVIRONMENT,
  appName: 'TrustLayer CWT',
  refreshInterval: 15,
};

export const FeatureFlagsMap = {
  testFeatureFlag: 'test',
  PULSE_INTEGRATION: 'release.pulse-integration.sc-14471',
  METABASE_REPORTS: 'release.metabase-reports.sc-14090',
  LIST_REQUIREMENTS: 'release.list-requirements.sc-15405',
  EMBEDDED_INSURANCE_CTA: 'experiment.embedded-insurance-CTA.sc-17272',
  SHOW_GLOBAL_REQUIREMENTS_WITH_PROJECTS:
    'release.show-global-requirements-with-projects.sc-16875',
  SEPARATE_EXPIRING_REQUEST_LANDING:
    'release.separate-expiring-request-landing.sc-18831',
  SAVE_PROGRESS_ON_FILL_SIGN: 'release.save-progress-on-fill-sign.sc-19990',
  SUBJECT_WAIVER_OVERRIDE_ACROSS_PROJECTS:
    'release.subject-waiver-override-across-projects.sc-20356',
  TL_V2: 'release.tl-v2.sc-21799',
  AUTOMATION_TRIGGER_EXIT_ACTION:
    'release.automation-trigger-exit-action.sc-21414',
  DISABLE_PROJECT_COMPLIANCE: 'release.disable-project-compliance.sc-24129',
  DYNAMIC_REQUIREMENTS: 'release.dynamic-requirements.sc-25577',
  COMPLIANCE_PROFILE_EDITOR: 'release.compliance-profile-editor.sc-23492',
  AI_REQUIREMENTS: 'release.ai-requirements.sc-25475',
  SURVEY_FORMS: 'experiment.survey-forms-poc.sc-26920',
  WEB_FORM_DOCUMENT_REQUEST_PAGE:
    'experiment.web-form-document-request-page.sc-27486',
} as const;
