import { Button, Drawer, Form, Tabs, message } from 'antd';
import styled from 'styled-components';

import Spinner from '@common/components/Spinner';
import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import {
  AUTOMATION_CODES,
  DocumentRemindersAutomationSchedules,
} from '@modules/automations';
import { EMAIL_TEMPLATE_TYPES } from '@modules/email-template';
import type { EmailTemplateEditFormFormValues } from '@modules/email-template/forms/EmailTemplateEditForm';
import { DocumentRemindersAutomationCheckbox } from '@modules/party';
import { usePartyAutomationUpdate } from '@modules/party/hooks';
import { useEffect, useMemo, useState } from 'react';
import { CustomizeEmailTemplate } from './CustomizeEmailTemplate';
import { EmailPreview } from './EmailPreview';
import { RequestForm } from './RequestForm';
import {
  useEmailTemplatesQuery,
  usePrimaryRecordQuery,
  useSendRequestMutation,
} from './hooks';

type SendRequestDrawerContainerProps = {
  open: boolean;
  filters?: ServerFilterInput;
  primaryRecordId: string;
  onCancel?: () => void;
  onCompleted?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
};

type FormValues = {
  contacts: string[];
  emailTemplateId: string;
  message: string;
  customTemplate: EmailTemplateEditFormFormValues;
};

export const SendRequestDrawerContainer = ({
  open,
  onCancel,
  primaryRecordId,
}: SendRequestDrawerContainerProps) => {
  const [form] = Form.useForm<FormValues>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentTab, setCurrentTab] = useState('send');

  const [
    isDocumentRemindersCheckboxChecked,
    setIsDocumentRemindersCheckboxChecked,
  ] = useState(false);

  const { sendRequest } = useSendRequestMutation();
  const { updatePartyAutomation } = usePartyAutomationUpdate();

  const { emailTemplates, isEmailTemplatesLoading } = useEmailTemplatesQuery({
    skip: !open,
  });

  const {
    contacts,
    hasRequestConversation,
    automationsMap,
    isPrimaryRecordLoading,
  } = usePrimaryRecordQuery({
    primaryRecordId: primaryRecordId,
    skipQuery: !open,
  });

  const documentRemindersAutomation =
    automationsMap?.[AUTOMATION_CODES.documentReminders];

  useEffect(() => {
    setIsDocumentRemindersCheckboxChecked(
      !!documentRemindersAutomation?.isEnabled,
    );
  }, [documentRemindersAutomation]);

  const isLoadingData = isEmailTemplatesLoading || isPrimaryRecordLoading;

  const defaultTemplateType = hasRequestConversation
    ? EMAIL_TEMPLATE_TYPES.PartyComplianceRequestReminder
    : EMAIL_TEMPLATE_TYPES.NewPartyComplianceRequest;

  const relevantEmailTemplates = useMemo(
    () =>
      emailTemplates?.filter((template) =>
        [EMAIL_TEMPLATE_TYPES.Custom, defaultTemplateType].includes(
          template.type,
        ),
      ) || [],
    [emailTemplates, defaultTemplateType],
  );

  useEffect(() => {
    if (isLoadingData || !emailTemplates?.length) return;

    const selectedTemplate = emailTemplates.find(
      (template) => template.type === defaultTemplateType,
    );

    form.setFields([
      {
        name: 'emailTemplateId',
        value: selectedTemplate?._id,
      },
    ]);
  }, [isLoadingData, emailTemplates, defaultTemplateType, form]);

  const handleOnSubmit = async (values: FormValues) => {
    setIsSubmitting(true);

    const template = emailTemplates.find(
      (template) => template._id === values.emailTemplateId,
    );
    try {
      const hasAutomation = documentRemindersAutomation !== undefined;
      const wasCheckboxChanges =
        isDocumentRemindersCheckboxChecked !==
        documentRemindersAutomation?.isEnabled;

      if (
        (hasAutomation && wasCheckboxChanges) ||
        (!hasAutomation && isDocumentRemindersCheckboxChecked)
      ) {
        await updatePartyAutomation({
          isEnabled: isDocumentRemindersCheckboxChecked,
          id: primaryRecordId,
          automationCode: AUTOMATION_CODES.documentReminders,
        });
      }

      await sendRequest({
        filter: {
          and: [
            {
              name: 'id',
              operator: 'in',
              value: [primaryRecordId],
            },
          ],
        },
        recipientsMap: {
          [primaryRecordId]: values.contacts,
        },
        emailTemplate: {
          id: template!._id,
          subject: values.customTemplate?.subject ?? template!.subject,
          regions: values.customTemplate?.regions ?? undefined,
        },
      });

      message.success(`Email successfully sent`);
      onCancel?.();
      form.resetFields();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Drawer
      title={`Send Email`}
      open={open}
      onClose={onCancel}
      width={700}
      maskClosable={false}
      destroyOnClose
      keyboard={false}
    >
      <Form form={form} onFinish={handleOnSubmit} layout="vertical">
        {isLoadingData ? (
          <Spinner />
        ) : (
          <Tabs
            defaultActiveKey="send"
            activeKey={currentTab}
            onChange={setCurrentTab}
          >
            <Tabs.TabPane tab="Send" key="send">
              <section>
                <RequestForm
                  contacts={contacts}
                  emailTemplates={relevantEmailTemplates}
                />
                <StyledActionsWrapper>
                  <section>
                    <DocumentRemindersAutomationCheckbox
                      isChecked={isDocumentRemindersCheckboxChecked}
                      isVisible
                      onChange={setIsDocumentRemindersCheckboxChecked}
                      popoverContent={() => (
                        <DocumentRemindersAutomationSchedules
                          branches={
                            documentRemindersAutomation?.branches?.nodes || []
                          }
                        />
                      )}
                    />
                  </section>

                  <section>
                    <StyledButton onClick={onCancel}>Cancel</StyledButton>
                    <StyledButton
                      type="primary"
                      onClick={form.submit}
                      loading={isSubmitting}
                      data-cy="confirmSendRequestButton"
                    >
                      Confirm and send
                    </StyledButton>
                  </section>
                </StyledActionsWrapper>
              </section>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Customize email " key="customize">
              <CustomizeEmailTemplate />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Preview email" key="preview">
              <EmailPreview
                primaryRecordId={primaryRecordId}
                skip={currentTab !== 'preview'}
              />
            </Tabs.TabPane>
          </Tabs>
        )}
      </Form>
    </Drawer>
  );
};

const StyledActionsWrapper = styled.section`
  margin: 40px 0 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin-left: 16px;
`;
