import styled from 'styled-components';
import type { Attribute } from '../constants';
import { formatValue } from '../utils/formatValue';
import { isAttributeValueEmpty } from '../utils/isAttributeValueEmpty';

type AttributesGridProp = {
  attributes?: Attribute[];
};

const getValue = (attribute: Attribute) => {
  if (isAttributeValueEmpty(attribute)) {
    return '-';
  }

  return formatValue(attribute);
};

export const AttributesGrid = ({ attributes = [] }: AttributesGridProp) => {
  return (
    <section>
      <StyledAttributesWrapper>
        {attributes?.map((attribute) => (
          <StyledAttribute key={attribute._id}>
            <p className="name">{attribute.name}</p>
            <p className="value">{getValue(attribute)}</p>
          </StyledAttribute>
        ))}
      </StyledAttributesWrapper>
    </section>
  );
};

const StyledAttributesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 8px;
  grid-row-gap: 10px;
`;

const StyledAttribute = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: 41px;

  > p {
    margin: 0;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .name {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grayText};
  }

  .value {
    color: ${({ theme }) => theme.colors.mineShaft};
  }
`;
