import { Checkbox, Collapse, Input, Select } from 'antd';
import * as R from 'ramda';
import styled from 'styled-components';

import DateFilterSelector, {
  KeyType,
} from '@common/components/DateFilterSelector';
import {
  adaptFilter,
  adaptFilterToStartOfDayUtc,
  getSearchParamsToOmit,
  getSelectFilterUtcDate,
  getSelectedFilter,
  pickOptionsFromFilterTypeOptions,
} from '@common/utils/date';

import {
  useIsComplianceRequirementsAddOnEnabled,
  useIsContextsAddOnEnabled,
} from '@modules/add-on';
import { FlagSeverityFilter } from '@modules/document/components/FlagSeverityFilter';
import { ReviewStatusFilter } from '@modules/document/components/ReviewStatusFilter';
import { DocumentFilterFields } from '../constants';
import { DocumentProjectFilter } from './DocumentProjectFilter';
import { SubjectsSelect } from './SubjectsSelect';

const DocumentsFilterContent = ({
  loadedProjects,
  searchParams,
  documentTypes,
  updateFilters,
}) => {
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();
  const { isContextsAddOnEnabled } = useIsContextsAddOnEnabled();
  return (
    <DocumentsFilterContent.Wrapper data-cy="documentsFilterContent">
      <Collapse bordered={false}>
        <Collapse.Panel
          header={
            R.has(DocumentFilterFields.Type, searchParams) ? (
              <em>Type</em>
            ) : (
              'Type'
            )
          }
        >
          <DocumentsFilterContent.FixedSelect
            mode="multiple"
            placeholder="Enter type..."
            value={R.flatten([
              R.propOr([], DocumentFilterFields.Type, searchParams),
            ])}
            onChange={(values) =>
              updateFilters({ [DocumentFilterFields.Type]: values })
            }
          >
            {documentTypes.map((documentType) => (
              <Select.Option
                key={R.prop('_id', documentType)}
                value={R.prop('_id', documentType)}
              >
                {R.prop('name', documentType)}
              </Select.Option>
            ))}
          </DocumentsFilterContent.FixedSelect>
        </Collapse.Panel>
        <Collapse.Panel
          header={
            searchParams?.[DocumentFilterFields.AssignedParty] ? (
              <em>Assigned to a party</em>
            ) : (
              'Assigned to a party'
            )
          }
        >
          <Checkbox.Group
            value={
              Array.isArray(searchParams?.[DocumentFilterFields.AssignedParty])
                ? searchParams[DocumentFilterFields.AssignedParty]
                : [searchParams?.[DocumentFilterFields.AssignedParty]]
            }
            onChange={(values) =>
              updateFilters({ [DocumentFilterFields.AssignedParty]: values })
            }
          >
            <div>
              <Checkbox value="assignedToParty">Assigned to a party</Checkbox>
            </div>
            <div>
              <Checkbox value="notAssignedToParty">
                Not assigned to any party
              </Checkbox>
            </div>
          </Checkbox.Group>
        </Collapse.Panel>
        {isContextsAddOnEnabled && (
          <Collapse.Panel
            header={
              searchParams?.[DocumentFilterFields.AssociatedProjects] ||
              searchParams?.[DocumentFilterFields.AssociatedToAnyProject] ||
              searchParams?.[DocumentFilterFields.NotAssociatedToAnyProject] ? (
                <em>Associated projects</em>
              ) : (
                'Associated projects'
              )
            }
          >
            <DocumentProjectFilter
              searchParams={searchParams}
              updateFilters={updateFilters}
              initialProjects={loadedProjects}
            />
          </Collapse.Panel>
        )}
        <Collapse.Panel
          header={
            searchParams?.[DocumentFilterFields.Status] ? (
              <em>Review status</em>
            ) : (
              'Review status'
            )
          }
        >
          <ReviewStatusFilter
            value={searchParams?.[DocumentFilterFields.Status]}
            onChange={(values) =>
              updateFilters({ [DocumentFilterFields.Status]: values })
            }
          />
        </Collapse.Panel>
        <Collapse.Panel
          header={
            R.has(DocumentFilterFields.Subject, searchParams) ? (
              <em>Subject</em>
            ) : (
              'Subject'
            )
          }
        >
          <SubjectsSelect
            value={R.flatten([
              R.propOr([], DocumentFilterFields.Subject, searchParams),
            ])}
            onChange={(values) =>
              updateFilters({ [DocumentFilterFields.Subject]: values })
            }
          />
        </Collapse.Panel>
        <Collapse.Panel
          header={
            R.has(DocumentFilterFields.Verification, searchParams) ? (
              <em>Verification</em>
            ) : (
              'Verification'
            )
          }
        >
          <Checkbox.Group
            value={R.flatten([
              R.prop(DocumentFilterFields.Verification, searchParams),
            ])}
            onChange={(values) =>
              updateFilters({ [DocumentFilterFields.Verification]: values })
            }
          >
            <div>
              <Checkbox value="verified">Verified</Checkbox>
            </div>
            <div>
              <Checkbox value="notVerified">Not verified</Checkbox>
            </div>
          </Checkbox.Group>
        </Collapse.Panel>
        <Collapse.Panel
          header={
            searchParams?.[DocumentFilterFields.Flag] ? <em>Flag</em> : 'Flag'
          }
        >
          <FlagSeverityFilter
            value={searchParams?.[DocumentFilterFields.Flag]}
            onChange={(values) =>
              updateFilters({ [DocumentFilterFields.Flag]: values })
            }
          />
        </Collapse.Panel>
        {isComplianceRequirementsAddOnEnabled && (
          <Collapse.Panel
            header={
              R.has(DocumentFilterFields.RequirementId, searchParams) ? (
                <em>Requirement ID</em>
              ) : (
                'Requirement ID'
              )
            }
          >
            <Input
              data-cy="filterRequirementIdInput"
              allowClear
              placeholder="Enter requirement ID..."
              value={R.flatten([
                R.propOr([], DocumentFilterFields.RequirementId, searchParams),
              ])}
              onChange={(e) =>
                updateFilters({
                  [DocumentFilterFields.RequirementId]: e.target.value,
                })
              }
            />
          </Collapse.Panel>
        )}
        <Collapse.Panel
          header={
            R.has(DocumentFilterFields.ExpirationDate, searchParams) ? (
              <em>Expiration</em>
            ) : (
              'Expiration'
            )
          }
        >
          <DateFilterSelector
            selectedFilter={getSelectFilterUtcDate(
              DocumentFilterFields.ExpirationDate,
              searchParams,
            )}
            filterTypes={pickOptionsFromFilterTypeOptions([
              KeyType.NEXT,
              KeyType.LAST,
              KeyType.BETWEEN,
              KeyType.ON,
            ])}
            onChange={(selectedFilter) =>
              updateFilters(
                adaptFilterToStartOfDayUtc(
                  DocumentFilterFields.ExpirationDate,
                  selectedFilter,
                ),
                getSearchParamsToOmit(
                  searchParams,
                  DocumentFilterFields.ExpirationDate,
                ),
              )
            }
          />
        </Collapse.Panel>
        <Collapse.Panel
          header={
            R.has(DocumentFilterFields.UploadDate, searchParams) ? (
              <em>Upload date</em>
            ) : (
              'Upload date'
            )
          }
        >
          <DateFilterSelector
            selectedFilter={getSelectedFilter(
              DocumentFilterFields.UploadDate,
              searchParams,
            )}
            filterTypes={pickOptionsFromFilterTypeOptions([
              KeyType.NEXT,
              KeyType.LAST,
              KeyType.BETWEEN,
              KeyType.ON,
            ])}
            onChange={(selectedFilter) =>
              updateFilters(
                adaptFilter(DocumentFilterFields.UploadDate, selectedFilter),
                getSearchParamsToOmit(
                  searchParams,
                  DocumentFilterFields.UploadDate,
                ),
              )
            }
          />
        </Collapse.Panel>
      </Collapse>
    </DocumentsFilterContent.Wrapper>
  );
};

DocumentsFilterContent.Wrapper = styled.div`
  width: 400px;
  margin: -12px;
  overflow-y: auto;
  max-height: 65vh;

  em {
    font-style: normal;
    color: rgb(24, 144, 255);
  }

  .ant-collapse {
    & > .ant-collapse-item {
      & > .ant-collapse-header {
        padding: 10px 16px;
      }

      .ant-collapse-arrow {
        left: 0;
      }
    }
  }
`;

DocumentsFilterContent.FixedSelect = styled(Select)`
  width: 192px;
`;

export default DocumentsFilterContent;
