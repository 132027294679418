import { Alert, Button, Col, Modal, Row, message } from 'antd';
import pluralize from 'pluralize';
import qs from 'query-string';
import * as R from 'ramda';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import useFetchListPageData from '@common/hooks/useFetchListPageData';
import useFilters from '@common/hooks/useFilters';
import useListPagination from '@common/hooks/useListPagination';
import useOrganizationSlugify from '@common/hooks/useOrganizationSlugify';
import { getDateFilters } from '@common/utils/date';
import { fetchPartyWithDetails } from '@modules/party/actions';
import {
  bulkDetachDocumentsFromProject,
  detachDocumentsFromProject as detachDocumentsFromProjectAction,
  fetchProjects,
} from '@modules/project/actions';
import { APPLIES_TO_ALL_PROJECTS_LIST_ELEMENT } from '@modules/project/containers/ProjectMultipleSelectContainer';
import { ListView } from '@modules/system-settings/constants';
import usePreferredSearchParams from '@modules/system-settings/hooks/usePreferredSearchParams';
import { getCurrentProjectId } from '@modules/system-settings/selectors';
import { getGraphqlPayload } from '@store/helpers';

import * as DocumentActions from '../actions';
import DocumentsCardView from '../components/DocumentsCardView';
import { DocumentFilterFields, REVIEW_STATUS } from '../constants';
import DocumentsFilterContainer from '../containers/DocumentsFilterContainer';
import DocumentsFilterTagsContainer from '../containers/DocumentsFilterTagsContainer';
import DocumentsHeaderContainer from '../containers/DocumentsHeaderContainer';
import DocumentsTableContainer from '../containers/DocumentsTableContainer';
import AddDocumentTypesModal from '../modals/AddDocumentTypesModal';
import AddRequirementValueModal from '../modals/AddRequirementValueModal';
import AssignDocumentModal from '../modals/AssignDocumentModal';
import AssignOneDocumentModal from '../modals/AssignOneDocumentModal';
import AssociateToProjectModal from '../modals/AssociateToProjectModal';
import BulkMatchRequirementsModal from '../modals/BulkMatchRequirementsModal';
import RemoveDocumentTypesModal from '../modals/RemoveDocumentTypesModal';
import {
  getDocuments,
  getDocumentsByIds,
  getDocumentsByParty,
  getTotalDocumentsCount,
} from '../selectors';

const containerConfig = {
  xs: { span: 24 },
};

const DocumentsPage = ({ showCurrentContext = true }) => {
  const { partyId, organizationSlugifyName } = useParams();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [loading, setLoading] = useState(false);
  const [selectedDocumentsCount, setSelectedDocumentsCount] = useState(0);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState('');
  const [areAllDocumentsSelected, setAreAllDocumentsSelected] = useState(false);
  const [isVisibleAssignModal, setIsVisibleAssignModal] = useState(false);
  const [isVisibleAssignOneModal, setIsVisibleAssignOneModal] = useState(false);
  const [
    isVisibleAssociateToProjectModal,
    setIsVisibleAssociateToProjectModal,
  ] = useState(false);
  const [
    isVisibleBulkMatchRequirementsModal,
    setIsVisibleBulkMatchRequirementsModal,
  ] = useState(false);
  const [
    isVisibleAddRequirementValueModal,
    setIsVisibleAddRequirementValueModal,
  ] = useState(false);
  const [isVisibleAddDocumentTypesModal, setIsVisibleAddDocumentTypesModal] =
    useState(false);
  const [
    isVisibleRemoveDocumentTypesModal,
    setIsVisibleRemoveDocumentTypesModal,
  ] = useState(false);
  const [loadedProjects, setLoadedProjects] = useState([]);
  const documentsTotalCount = useSelector(getTotalDocumentsCount);
  const currentProjectId = useSelector(getCurrentProjectId);

  const globalDocuments = useSelector(getDocuments);
  const partyDocuments = useSelector((state) =>
    getDocumentsByParty(state, partyId),
  );
  const selectedDocumentsData = useSelector((state) =>
    getDocumentsByIds(state, selectedDocuments),
  );

  const documents = partyId ? partyDocuments : globalDocuments;

  const searchParams = useMemo(() => qs.parse(search), [search]);

  const isShowingArchived = R.propEq('status', 'archived', searchParams);

  const {
    sortField,
    sortDirection,
    view: documentsView,
    size: pageSize,
  } = usePreferredSearchParams();

  const documentsVisibleCount =
    documents.length > pageSize ? pageSize : documents.length;

  const filtersCount = R.compose(
    R.length,
    R.keys,
    R.pick(R.values(DocumentFilterFields)),
  )(searchParams);

  const dispatch = useDispatch();

  useOrganizationSlugify();

  const { onChangePagination } = useListPagination();

  const fetchAssociatedProjects = useCallback(
    async (projectsIds) => {
      if (!projectsIds.length) {
        return [];
      }

      const res = await dispatch(
        fetchProjects({
          filters: { _id: projectsIds },
          sort: { field: 'name', order: 'asc' },
        }),
      );
      const projects = R.propOr([], 'data', getGraphqlPayload(res));
      return projects;
    },
    [dispatch],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    const visiblySelectedDocuments = selectedDocuments.filter(
      (selectedDocumentId) =>
        documents.map(({ _id }) => _id).includes(selectedDocumentId),
    );
    if (
      !loading &&
      !areAllDocumentsSelected &&
      selectedDocumentsCount > visiblySelectedDocuments.length
    ) {
      setSelectedDocuments(visiblySelectedDocuments);
      setSelectedDocumentsCount(visiblySelectedDocuments.length);
    }
  }, [
    areAllDocumentsSelected,
    documents,
    loading,
    pageSize,
    selectedDocuments,
    selectedDocumentsCount,
  ]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    const associatedProjects = R.compose(
      R.flatten,
      (e) => [e],
      R.propOr([], DocumentFilterFields.AssociatedProjects),
    )(searchParams);

    const appliesToAllProjects = R.includes(
      'appliesToAllProjects',
      associatedProjects,
    )
      ? [APPLIES_TO_ALL_PROJECTS_LIST_ELEMENT]
      : [];

    fetchAssociatedProjects(
      R.without('appliesToAllProjects', associatedProjects),
    ).then((projects) => {
      setLoadedProjects(R.concat(projects, appliesToAllProjects));
    });
  }, [dispatch, searchParams, fetchAssociatedProjects]);

  /**
   * Custom hook to load documents data.
   * Using previous data to avoid extra request
   * when changing one of the filter parameter.
   */
  const { shouldRefreshData, refreshData } = useFetchListPageData(
    searchParams,
    () => buildFetchParams(searchParams),
    (...args) => fetchDocumentsData(...args),
    'documents',
  );

  const { updateFilters, resetFilters, updateNameFilter, updateSort } =
    useFilters(['isActive']);

  const onResetDocumentsFilters = () => {
    resetFilters();
  };

  /**
   * useEffect to set default query string params
   */
  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    navigate(
      `${pathname}?${qs.stringify(
        R.compose(
          R.filter((param) => Boolean(param)),
          R.assoc('sortField', sortField),
          R.assoc('sortDirection', sortDirection),
          R.assoc('project', currentProjectId),
          R.assoc('associatedProjects', currentProjectId),
        )(searchParams),
      )}`,
      {
        replace: true,
      },
    );
  }, [currentProjectId]);

  // reset document selection on a project change
  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    resetSelections();
  }, [search, sortField, sortDirection]);

  const selectAllVisibleDocuments = () => {
    setAreAllDocumentsSelected(false);
    setSelectedDocuments(documents.map(R.prop('_id')));
    setSelectedDocumentsCount(documents.length);
  };

  const selectAllDocuments = () => {
    setSelectedDocuments([]);
    setAreAllDocumentsSelected(true);
    setSelectedDocumentsCount(documentsTotalCount);
  };

  const fetchDocumentsData = async (fetchParams) => {
    setLoading(true);
    await dispatch(DocumentActions.fetchDocuments(fetchParams));
    setLoading(false);
  };

  /**
   * Prepares possible filter data to load.
   */
  const buildFetchParams = (searchParams) => {
    //eslint-disable-next-line fp/no-let
    let filter = {};

    const sort = {
      field: sortField,
      order: sortDirection,
    };

    if (R.propEq('status', 'archived', searchParams)) {
      filter.archivedAt = { $ne: null };
    } else {
      filter.archivedAt = null;
    }

    if (Boolean(partyId)) {
      filter.party = partyId;
    }

    // Filtering by status.
    if (R.has(DocumentFilterFields.Status, searchParams)) {
      const reviewStatus = R.prop(DocumentFilterFields.Status, searchParams);
      if (!Array.isArray(reviewStatus)) {
        filter.reviewedAt =
          reviewStatus === REVIEW_STATUS.Reviewed ? { $ne: null } : null;
      }
    }

    // Filtering by document type.
    if (R.has(DocumentFilterFields.Type, searchParams)) {
      const types = R.flatten([
        R.propOr([], DocumentFilterFields.Type, searchParams),
      ]);
      filter.types = types;
    }

    // Filtering by document flag.
    if (R.has(DocumentFilterFields.Flag, searchParams)) {
      filter['flag.severityLevel'] = {
        $in: R.flatten([R.propOr([], DocumentFilterFields.Flag, searchParams)]),
      };
    }

    // Filtering by verification.
    if (R.has(DocumentFilterFields.Verification, searchParams)) {
      const verification = R.prop(
        DocumentFilterFields.Verification,
        searchParams,
      );
      if (!Array.isArray(verification)) {
        filter.verification =
          verification === 'verified' ? { $ne: null } : null;
      }
    }

    // Filtering by subject.
    if (R.has(DocumentFilterFields.Subject, searchParams)) {
      const subjects = R.flatten([
        R.propOr([], DocumentFilterFields.Subject, searchParams),
      ]);
      filter.subjects = subjects;
    }

    // Filtering by name.
    if (R.has(DocumentFilterFields.Name, searchParams)) {
      filter.friendlyName = R.prop(DocumentFilterFields.Name, searchParams);
    }

    // Filtering by requirement id.
    if (R.has(DocumentFilterFields.RequirementId, searchParams)) {
      filter.requirementId = R.prop(
        DocumentFilterFields.RequirementId,
        searchParams,
      );
    }

    // Filtering by upload date.
    if (R.has(DocumentFilterFields.UploadDate, searchParams)) {
      filter = {
        ...filter,
        ...getDateFilters({
          activeFilters: searchParams,
          filterType: DocumentFilterFields.UploadDate,
        }),
      };
    }

    // Filtering by expiration date.
    if (R.has(DocumentFilterFields.ExpirationDate, searchParams)) {
      filter = {
        ...filter,
        ...getDateFilters({
          activeFilters: searchParams,
          filterType: DocumentFilterFields.ExpirationDate,
        }),
      };
    }

    // Filtering by associated projects.
    if (
      R.has(DocumentFilterFields.AssociatedProjects, searchParams) ||
      currentProjectId
    ) {
      const associatedProjects = R.compose(
        R.flatten,
        (e) => [e],
        R.propOr(
          currentProjectId ? [currentProjectId] : [],
          DocumentFilterFields.AssociatedProjects,
        ),
      )(searchParams);

      filter.associatedProjects = R.without(
        'appliesToAllProjects',
        associatedProjects,
      );

      if (R.includes('appliesToAllProjects', associatedProjects)) {
        filter.appliesToAllProjects = true;
      }
    }

    if (searchParams?.[DocumentFilterFields.AssignedParty]) {
      const assignedParty = searchParams[DocumentFilterFields.AssignedParty];
      if (!Array.isArray(assignedParty)) {
        filter.assignedParty = assignedParty === 'assignedToParty';
      }
    }

    if (searchParams?.[DocumentFilterFields.AssociatedToAnyProject]) {
      filter.associatedToAnyProject = true;
    }

    if (searchParams?.[DocumentFilterFields.NotAssociatedToAnyProject]) {
      filter.notAssociatedToAnyProject = true;
    }

    return {
      skip: (R.propOr(1, 'page', searchParams) - 1) * pageSize,
      limit: pageSize,
      filter,
      sort,
    };
  };

  const detachFromCurrentParty = ({ friendlyName, _id }) => {
    Modal.confirm({
      title: `Do you want to detach ${friendlyName} from the current party?`,
      okButtonProps: { 'data-cy': 'detachFromPartyModalOkButton' },
      okText: 'Detach',
      onOk: async () => {
        await dispatch(
          DocumentActions.detachDocumentsFromParty({
            documentIds: [_id],
          }),
        );
      },
    });
  };

  const detachDocumentsFromParty = () => {
    const filterQuery = !areAllDocumentsSelected
      ? {
          ids: selectedDocuments,
        }
      : bulkOperationDocumentsFilters;

    Modal.confirm({
      title: `Do you want to detach ${pluralize(
        'document',
        selectedDocumentsCount,
        true,
      )} from the current party?`,
      onOk: async () => {
        await dispatch(
          DocumentActions.bulkDetachDocumentsFromParty({
            filterQuery,
          }),
        );

        message.success(
          `${pluralize(
            'document',
            selectedDocumentsCount,
            true,
          )} scheduled to detach from party`,
        );

        resetSelections();
      },
    });
  };

  const detachDocumentFromCurrentProject = ({ friendlyName, _id }) => {
    Modal.confirm({
      title: `Do you want to detach ${friendlyName} from the current project?`,
      okButtonProps: { 'data-cy': 'detachFromProjectModalOkButton' },
      okText: 'Detach',
      onOk: async () => {
        await dispatch(
          detachDocumentsFromProjectAction({
            documentIds: [_id],
          }),
        );
        resetSelections();
      },
    });
  };

  const detachDocumentsFromProject = () => {
    const filterQuery = !areAllDocumentsSelected
      ? {
          ids: selectedDocuments,
        }
      : bulkOperationDocumentsFilters;

    Modal.confirm({
      title: `Do you want to detach ${pluralize(
        'document',
        selectedDocumentsCount,
        true,
      )} documents from the current project?`,
      onOk: async () => {
        await dispatch(
          bulkDetachDocumentsFromProject({
            filterQuery,
          }),
        );

        message.success(
          `${pluralize(
            'document',
            selectedDocumentsCount,
            true,
          )} scheduled to detach from project`,
        );

        resetSelections();
      },
    });
  };

  const updateDocumentArchiveStatus = ({ _id, archivedAt, friendlyName }) => {
    Modal.confirm({
      title: `Are you sure you want to ${
        archivedAt ? 'unarchive' : 'archive'
      } ${friendlyName}?`,
      okText: `${archivedAt ? 'Unarchive' : 'Archive'} document`,
      onOk: async () => {
        await dispatch(
          DocumentActions.archiveDocument({
            id: _id,
            isArchived: !Boolean(archivedAt),
          }),
        );

        resetSelections();
      },
    });
  };

  const updateDocumentsArchiveStatus = () => {
    const filterQuery = !areAllDocumentsSelected
      ? {
          ids: selectedDocuments,
        }
      : bulkOperationDocumentsFilters;

    Modal.confirm({
      title: `Are you sure you want to ${
        !isShowingArchived ? 'archive' : 'unarchive'
      } ${pluralize('document', selectedDocumentsCount, true)}?`,
      onOk: async () => {
        await dispatch(
          DocumentActions.bulkArchiveDocuments({
            filterQuery,
            isArchived: !isShowingArchived,
          }),
        );

        message.success(
          `${pluralize(
            'document',
            selectedDocumentsCount,
            true,
          )} scheduled to be ${!isShowingArchived ? 'archived' : 'unarchived'}`,
        );

        resetSelections();
      },
    });
  };

  const deleteDocument = ({ _id, friendlyName, compliantAttributes }) => {
    Modal.confirm({
      title: `${
        !R.isEmpty(compliantAttributes) && Boolean(partyId)
          ? 'This document is being used to match compliance requirements.'
          : ''
      } Are you sure you want to delete ${friendlyName}? This action cannot be undone`,
      okText: 'Delete document',
      okType: 'danger',
      onOk: async () => {
        await dispatch(DocumentActions.deleteDocument(_id));

        resetSelections();

        if (partyId) {
          dispatch(fetchPartyWithDetails(partyId));
        }
      },
    });
  };

  const deleteDocuments = () => {
    const filterQuery = !areAllDocumentsSelected
      ? {
          ids: selectedDocuments,
        }
      : bulkOperationDocumentsFilters;

    const isHaveProveCompliance = selectedDocumentsData.some(
      (document) => !R.isEmpty(document.compliantAttributes),
    );

    Modal.confirm({
      title: `${
        isHaveProveCompliance && Boolean(partyId)
          ? 'Some of the selected documents are being used to match compliance requirements.'
          : ''
      } Are you sure you want to delete ${pluralize(
        'document',
        selectedDocumentsCount,
        true,
      )}?`,
      okType: 'danger',
      okText: 'Delete documents',
      onOk: async () => {
        await dispatch(
          DocumentActions.bulkDeleteDocuments({
            filterQuery,
          }),
        );

        message.success(
          `${pluralize(
            'document',
            selectedDocumentsCount,
            true,
          )} scheduled for deletion`,
        );

        resetSelections();

        if (partyId) {
          await dispatch(fetchPartyWithDetails(partyId));
        }
      },
    });
  };

  const markSelectedAsReviewed = () => {
    const filterQuery = !areAllDocumentsSelected
      ? {
          ids: selectedDocuments,
        }
      : bulkOperationDocumentsFilters;

    Modal.confirm({
      title: `Do you want to mark ${pluralize(
        'document',
        selectedDocumentsCount,
        true,
      )} as reviewed?`,
      onOk: async () => {
        await dispatch(
          DocumentActions.bulkReviewDocuments({
            filterQuery,
            isReviewed: true,
          }),
        );

        message.success(
          `${pluralize(
            'document',
            selectedDocumentsCount,
            true,
          )} scheduled to mark as "Reviewed"`,
        );

        resetSelections();
      },
    });
  };

  const markSelectedAsToReview = () => {
    const filterQuery = !areAllDocumentsSelected
      ? {
          ids: selectedDocuments,
        }
      : bulkOperationDocumentsFilters;

    Modal.confirm({
      title: `Do you want to mark ${pluralize(
        'document',
        selectedDocumentsCount,
        true,
      )} as to review?`,
      onOk: async () => {
        await dispatch(
          DocumentActions.bulkReviewDocuments({
            filterQuery,
            isReviewed: false,
          }),
        );

        message.success(
          `${pluralize(
            'document',
            selectedDocumentsCount,
            true,
          )} scheduled to mark as "To review"`,
        );

        resetSelections();
      },
    });
  };

  const reviewDocument = ({ _id, reviewedAt, friendlyName }) => {
    const isReviewed = !!reviewedAt;

    Modal.confirm({
      title: `Are you sure you want to ${
        isReviewed ? 'mark as to review' : 'mark as reviewed'
      } ${friendlyName}?`,
      onOk: async () => {
        await dispatch(
          DocumentActions.reviewDocument({
            id: _id,
            isReviewed: !isReviewed,
          }),
        );
      },
    });
  };

  const handleOnDocumentActionClick = ({ actionType, document }) => {
    const { _id } = document;
    switch (actionType) {
      case 'assignToParty':
        setSelectedDocument(_id);
        setIsVisibleAssignModal(true);
        break;
      case 'associateToProject':
        setSelectedDocument(_id);
        setIsVisibleAssociateToProjectModal(true);
        break;
      case 'documentNameClick':
        setSelectedDocument(_id);
        setIsVisibleAssignOneModal(true);
        break;
      case 'detachDocumentFromCurrentProject':
        detachDocumentFromCurrentProject(document);
        break;
      case 'detachFromCurrentParty':
        detachFromCurrentParty(document);
        break;
      case 'reviewDocument':
        reviewDocument(document);
        break;
      case 'updateDocumentArchiveStatus':
        updateDocumentArchiveStatus(document);
        break;
      case 'deleteDocument':
        deleteDocument(document);
        break;
      default:
        break;
    }
  };

  const paginationOptions = {
    showSizeChanger: true,
    showQuickJumper: true,
    onChange: onChangePagination,
    size: 'small',
    current: parseInt(R.propOr('1', 'page', searchParams)),
    pageSize,
    pageSizeOptions: ['12', '24', '36', '48', '60'],
    total: documentsTotalCount,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
  };

  const resetSelections = () => {
    setSelectedDocument('');
    setAreAllDocumentsSelected(false);
    setSelectedDocuments([]);
    setSelectedDocumentsCount(0);
  };

  const bulkOperationDocumentsFilters = buildFetchParams(searchParams)?.filter;

  return (
    <Row style={{ padding: '0 20px 50px' }}>
      <AssignOneDocumentModal
        visible={isVisibleAssignOneModal}
        onSuccess={() => {
          setIsVisibleAssignOneModal(false);
          setSelectedDocument('');
        }}
        onCancel={() => {
          setIsVisibleAssignOneModal(false);
          setSelectedDocument('');
        }}
        documentId={selectedDocument}
      />
      <AssignDocumentModal
        areAllDocumentsSelected={areAllDocumentsSelected}
        visible={isVisibleAssignModal}
        documentsFilter={bulkOperationDocumentsFilters}
        setIsVisible={setIsVisibleAssignModal}
        selectedDocumentsCount={selectedDocumentsCount}
        documentId={selectedDocument}
        documents={selectedDocuments}
        onCancel={() => {
          setIsVisibleAssignModal(false);
          setSelectedDocument('');
        }}
        onSuccess={() => {
          setIsVisibleAssignModal(false);
          resetSelections();
        }}
      />
      <AssociateToProjectModal
        areAllDocumentsSelected={areAllDocumentsSelected}
        visible={isVisibleAssociateToProjectModal}
        selectedDocumentsCount={selectedDocumentsCount}
        documentsFilter={bulkOperationDocumentsFilters}
        documents={selectedDocuments}
        documentId={selectedDocument}
        onCancel={() => {
          setIsVisibleAssociateToProjectModal(false);
          setSelectedDocument('');
        }}
        onSuccess={() => {
          setIsVisibleAssociateToProjectModal(false);
          resetSelections();
        }}
      />
      <AddDocumentTypesModal
        visible={isVisibleAddDocumentTypesModal}
        onCancel={() => {
          setIsVisibleAddDocumentTypesModal(false);
          setSelectedDocument('');
        }}
        onSuccess={() => {
          setIsVisibleAddDocumentTypesModal(false);
          resetSelections();
        }}
        documentsIds={selectedDocuments}
        selectedDocumentsCount={selectedDocumentsCount}
      />
      <RemoveDocumentTypesModal
        visible={isVisibleRemoveDocumentTypesModal}
        onCancel={() => {
          setIsVisibleRemoveDocumentTypesModal(false);
          setSelectedDocument('');
        }}
        onSuccess={() => {
          setIsVisibleRemoveDocumentTypesModal(false);
          resetSelections();
        }}
        documents={selectedDocuments}
        selectedDocumentsCount={selectedDocumentsCount}
      />
      <BulkMatchRequirementsModal
        visible={isVisibleBulkMatchRequirementsModal}
        onCancel={() => {
          setIsVisibleBulkMatchRequirementsModal(false);
          setSelectedDocument('');
        }}
        onSuccess={() => {
          setIsVisibleBulkMatchRequirementsModal(false);
          resetSelections();
        }}
        documentsIds={selectedDocuments}
        selectedDocumentsCount={selectedDocumentsCount}
      />
      <AddRequirementValueModal
        areAllDocumentsSelected={areAllDocumentsSelected}
        visible={isVisibleAddRequirementValueModal}
        selectedDocumentsCount={selectedDocumentsCount}
        documentsFilter={bulkOperationDocumentsFilters}
        onCancel={() => {
          setIsVisibleAddRequirementValueModal(false);
          setSelectedDocument('');
        }}
        onSuccess={() => {
          setIsVisibleAddRequirementValueModal(false);
          resetSelections();
        }}
        documents={selectedDocuments}
      />
      <Col {...containerConfig}>
        <DocumentsHeaderContainer
          url={pathname}
          showCurrentContext={showCurrentContext}
        />
        <DocumentsFilterTagsContainer
          url={pathname}
          loadedProjects={loadedProjects}
          onResetFilters={onResetDocumentsFilters}
        />
        <DocumentsFilterContainer
          loading={loading}
          setSelectedDocumentsCount={setSelectedDocumentsCount}
          loadedProjects={loadedProjects}
          sortField={sortField}
          documentsFilter={bulkOperationDocumentsFilters}
          sortDirection={sortDirection}
          filtersCount={filtersCount}
          documentsTotalCount={documentsTotalCount}
          selectedDocumentsCount={selectedDocumentsCount}
          documentsVisibleCount={documentsVisibleCount}
          updateFilters={updateFilters}
          resetFilters={onResetDocumentsFilters}
          updateNameFilter={updateNameFilter}
          selectedDocuments={selectedDocuments}
          setSelectedDocuments={setSelectedDocuments}
          areAllDocumentsSelected={areAllDocumentsSelected}
          setAreAllDocumentsSelected={setAreAllDocumentsSelected}
          setIsVisibleAssignModal={setIsVisibleAssignModal}
          setIsVisibleAssociateToProjectModal={
            setIsVisibleAssociateToProjectModal
          }
          setIsVisibleBulkMatchRequirementsModal={
            setIsVisibleBulkMatchRequirementsModal
          }
          setIsVisibleAddDocumentTypesModal={setIsVisibleAddDocumentTypesModal}
          setIsVisibleRemoveDocumentTypesModal={
            setIsVisibleRemoveDocumentTypesModal
          }
          setIsVisibleAddRequirementValueModal={
            setIsVisibleAddRequirementValueModal
          }
          selectAllDocuments={selectAllDocuments}
          selectAllVisibleDocuments={selectAllVisibleDocuments}
          markSelectedAsReviewed={markSelectedAsReviewed}
          markSelectedAsToReview={markSelectedAsToReview}
          updateDocumentsArchiveStatus={updateDocumentsArchiveStatus}
          deleteDocuments={deleteDocuments}
          detachDocumentsFromParty={detachDocumentsFromParty}
          detachDocumentsFromProject={detachDocumentsFromProject}
        />
        {shouldRefreshData && (
          <Alert
            message={
              <>
                New data is available.
                <Button
                  type="link"
                  onClick={() => {
                    refreshData();
                  }}
                >
                  Refresh now
                </Button>
              </>
            }
            banner
          />
        )}
        {documentsView === ListView.Card ? (
          <DocumentsCardView
            loading={loading}
            documents={documents}
            areAllDocumentsSelected={areAllDocumentsSelected}
            setSelectedDocumentsCount={setSelectedDocumentsCount}
            setAreAllDocumentsSelected={setAreAllDocumentsSelected}
            organizationNamespace={organizationSlugifyName}
            paginationOptions={paginationOptions}
            selectedDocuments={selectedDocuments}
            setSelectedDocuments={setSelectedDocuments}
            setSelectedDocument={setSelectedDocument}
            setIsVisibleAssignOneModal={setIsVisibleAssignOneModal}
          />
        ) : (
          <DocumentsTableContainer
            loading={loading}
            documents={documents}
            sortField={sortField}
            sortDirection={sortDirection}
            areAllDocumentsSelected={areAllDocumentsSelected}
            onRowSelection={(selectedTableDocuments) => {
              setSelectedDocuments(selectedTableDocuments);
              setSelectedDocumentsCount(selectedTableDocuments.length);
              setAreAllDocumentsSelected(false);
            }}
            onDocumentActionClick={handleOnDocumentActionClick}
            updateSort={updateSort}
            selectedDocuments={selectedDocuments}
            paginationOptions={paginationOptions}
          />
        )}
      </Col>
    </Row>
  );
};

export default DocumentsPage;
