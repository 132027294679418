import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const VISIBLE_ATTRIBUTES_COUNT = 8;

export const RECORD_ATTACHMENTS_QUERY = graphql(`
  query ContextRecordAttachmentsSidebarPanel(
    $input: ContextRecordInput!
    $attachmentsInput: ContextRecordAttachmentsInput
  ) {
    contextRecord(input: $input) {
      _id
      attachments(input: $attachmentsInput) {
        nodes {
          friendlyName
          s3Key
          uploadedAt
        }
        totalCount
      }
    }
  }
`);

export const useAttachmentsSidebarPanel = ({
  contextRecordId,
}: { contextRecordId: string }) => {
  const { data, loading, error, refetch } = useQuery(RECORD_ATTACHMENTS_QUERY, {
    variables: {
      input: {
        id: contextRecordId,
      },
      attachmentsInput: {
        first: VISIBLE_ATTRIBUTES_COUNT,
        offset: 0,
      },
    },
    skip: !contextRecordId,
    notifyOnNetworkStatusChange: true,
  });

  return {
    attachments: data?.contextRecord.attachments?.nodes || [],
    attachmentsTotalCount: data?.contextRecord.attachments?.totalCount || 0,
    loading,
    error,
    refetchAttachments: refetch,
  };
};
