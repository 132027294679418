import { humanizeDate } from '@trustlayer/common';
import { ATTRIBUTE_TYPES, type Attribute } from '../constants';

export const formatValue = (attribute: Attribute) => {
  switch (attribute.type) {
    case ATTRIBUTE_TYPES.Address:
      const addressValue =
        typeof attribute.value === 'string'
          ? attribute.value
          : attribute.value!.raw;

      return addressValue;
    case ATTRIBUTE_TYPES.MultiSelect:
      return attribute.selectedOptions;
    case ATTRIBUTE_TYPES.Dropdown:
      return (
        (attribute.selectedOptions && attribute.selectedOptions[0]?.value) || []
      );
    case ATTRIBUTE_TYPES.Date:
      return humanizeDate(attribute.value);
    default:
      return attribute.value;
  }
};
