import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const REMOVE_CONVERSATION_CONTACTS = graphql(`
  mutation RemoveConversationContacts($data: [RemoveContactInput!]!) {
    removeContacts(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useRemoveConversationContactsMutation = ({
  conversationId,
}: {
  conversationId: string;
}) => {
  const [removeContacts, { loading }] = useMutation(
    REMOVE_CONVERSATION_CONTACTS,
  );

  const handleRemoveConversationContacts = (contactIds: string[]) => {
    return removeContacts({
      variables: {
        data: contactIds.map((contactId) => ({
          id: contactId,
          conversations: [conversationId],
        })),
      },
    });
  };

  return {
    removeConversationContacts: handleRemoveConversationContacts,
    loading,
  };
};
