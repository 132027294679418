import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Form, Row, Typography } from 'antd';
import { useMemo } from 'react';
import styled from 'styled-components';

import RichTextEditor from '@common/components/RichTextEditor';
import {
  getEditorHTMLContent,
  getEditorPlainText,
} from '@common/components/RichTextEditor/helpers';
import type { EmailLayout, EmailTemplate } from '@graphql/types/graphql';
import { TextRegionType } from '@modules/email-layout/constants';
import { getTextRegions } from '@modules/email-layout/utils/mjml.helpers';
import { usePlaceholdersByEmailTemplateType } from '../hooks';

type EmailTemplateEditFormProps = {
  template: Pick<EmailTemplate, 'subject' | 'regions' | 'type'>;
  layout: Pick<EmailLayout, 'mjml'>;
  namePrefix: string[];
};

export type EmailTemplateEditFormFormValues = {
  subject: string;
  regions: Record<string, string>;
};

const EmailTemplateEditForm = ({
  template,
  layout,
  namePrefix,
}: EmailTemplateEditFormProps) => {
  const layoutMjml = layout?.mjml || '';
  const layoutRegions = useMemo(() => getTextRegions(layoutMjml), [layoutMjml]);

  const { emailPlaceholders } = usePlaceholdersByEmailTemplateType(
    template?.type,
  );

  return (
    <>
      <Form.Item
        data-cy="templateSubjectInput"
        name={[...namePrefix, 'subject']}
        label="Subject"
        getValueFromEvent={(editorState) => getEditorPlainText(editorState)}
      >
        <RichTextEditor
          data-cy="templateSubjectInput"
          isValid
          minHeight={32}
          maxHeight={32}
          placeholder={`Enter subject...`}
          defaultContent={template?.subject}
          placeholders={emailPlaceholders}
          disableInlineStyleButtons
          isOneLineEditor
        />
      </Form.Item>
      <StyledInfoSection>
        <div className="title">Layout regions</div>
        <Row wrap={false}>
          <Col>
            <InfoCircleOutlined />
          </Col>
          <Col className="description">
            You can use special tokens to customize content in layout regions.
            Type the open curly bracket character{' '}
            <Typography.Text code>{`{`}</Typography.Text> and select from the
            list of available tokens.
          </Col>
        </Row>
      </StyledInfoSection>

      {layoutRegions.map(({ name: regionName, type }) => {
        const isSimpleRegion = TextRegionType.Simple === type;

        return (
          <Form.Item
            data-cy={`templateRegion-${regionName}`}
            key={regionName}
            name={[...namePrefix, 'regions', regionName]}
            label={regionName}
            getValueFromEvent={(editorState) =>
              isSimpleRegion
                ? getEditorPlainText(editorState)
                : getEditorHTMLContent(editorState)
            }
          >
            <RichTextEditor
              isValid
              minHeight={isSimpleRegion ? 32 : 120}
              maxHeight={isSimpleRegion ? 32 : 120}
              placeholder={`Enter ${regionName}...`}
              defaultContent={template?.regions[regionName] || ''}
              placeholders={emailPlaceholders}
              disableInlineStyleButtons={isSimpleRegion}
              isOneLineEditor={isSimpleRegion}
            />
          </Form.Item>
        );
      })}
    </>
  );
};

const StyledInfoSection = styled.section`
  margin-bottom: 24px;

  .title {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 8px;
    margin-bottom: 12px;
    border-bottom: 1px solid #e8e8e8;
  }

  .description {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);

    code {
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  .anticon {
    color: rgba(0, 0, 0, 0.65);
    margin-right: 8px;
  }
`;

export default EmailTemplateEditForm;
