import type { SortDirection } from '@common/components/Table';
import { NUMBER_FILTER_OPTION_TYPES } from '@common/components/Table/constants';
import { DocumentsCountOptionsType } from '@modules/party/constants';
import {
  OVERRIDDEN_STATUS,
  WAIVERS_STATUS,
} from '@modules/primary-records/containers/PrimaryRecordsTable/constants';
import {
  LEGACY_PARTIES_STATUS,
  type LegacyPartiesReportParams,
} from '@modules/report/types';
import {
  getArrayFilter,
  getIdFilter,
  getNumberFilter,
  getSetTypeFilter,
  getTableDateFilterFromReportFilter,
  getTextTypeFilter,
} from '@modules/report/utils';
import { PrimaryRecordStatus } from '@trustlayer/common';
import type { ISimpleFilterModelType } from 'ag-grid-community/dist/types/core/filter/provided/iSimpleFilter';
import type { TableFiltersModel } from '../PrimaryObject';

type TableParams = {
  filters: TableFiltersModel;
  sorting: {
    columnId?: string;
    direction?: SortDirection;
  };
};

export const mapLegacyPartiesReportParamsToTableParams = (
  reportParams: LegacyPartiesReportParams,
): TableParams => {
  // eslint-disable-next-line fp/no-let
  let tableFilters: TableFiltersModel = {};

  if (reportParams.name) {
    tableFilters = {
      ...tableFilters,
      name: getTextTypeFilter(reportParams.name),
    };
  }

  if (reportParams.tags) {
    tableFilters = {
      ...tableFilters,
      tags: getIdFilter(reportParams.tags.value, reportParams.tags.operator),
    };
  }

  if (reportParams.documentsCount) {
    const [reportOperator, value] = reportParams.documentsCount.split('-');

    let operator = null;

    if (reportOperator === DocumentsCountOptionsType.LessThan.value) {
      operator = NUMBER_FILTER_OPTION_TYPES.lessThan;
    }

    if (reportOperator === DocumentsCountOptionsType.MoreThan.value) {
      operator = NUMBER_FILTER_OPTION_TYPES.greaterThan;
    }
    if (reportOperator === DocumentsCountOptionsType.Exactly.value) {
      operator = NUMBER_FILTER_OPTION_TYPES.equals;
    }

    tableFilters = {
      ...tableFilters,
      activeDocuments: getNumberFilter(
        value,
        operator as ISimpleFilterModelType,
      ),
    };
  }

  if (reportParams.reviewStatus) {
    tableFilters = {
      ...tableFilters,
      reviewStatus: getSetTypeFilter(reportParams.reviewStatus),
    };
  }

  if (reportParams.flag) {
    tableFilters = {
      ...tableFilters,
      flagsSeverity: getSetTypeFilter(reportParams.flag),
    };
  }
  if (reportParams.status) {
    const filterValue =
      reportParams.status === LEGACY_PARTIES_STATUS.Inactive
        ? PrimaryRecordStatus.Archived
        : PrimaryRecordStatus.Active;

    tableFilters = {
      ...tableFilters,
      status: getSetTypeFilter(filterValue),
    };
  }

  if (reportParams.type) {
    tableFilters = {
      ...tableFilters,
      types: getIdFilter(reportParams.type, 'in'),
    };
  }

  if (reportParams.assignedToProjects) {
    tableFilters = {
      ...tableFilters,
      contextRecords: getIdFilter(reportParams.assignedToProjects, 'in'),
    };
  }

  if (reportParams.waivers) {
    const tableWaivers = getArrayFilter(reportParams.waivers).map((value) => {
      if (value === 'withWaived') {
        return WAIVERS_STATUS.WithRequirements;
      }
      if (value === 'withoutWaived') {
        return WAIVERS_STATUS.WithoutRequirements;
      }
    }) as string[];

    tableFilters = {
      ...tableFilters,
      waiversStatus: getSetTypeFilter(tableWaivers),
    };
  }

  if (reportParams.overrides) {
    const tableOverrides = getArrayFilter(reportParams.overrides).map(
      (value) => {
        if (value === 'withOverride') {
          return OVERRIDDEN_STATUS.WithOverriddenRequirements;
        }
        if (value === 'withoutOverride') {
          return OVERRIDDEN_STATUS.WithoutOverriddenRequirements;
        }
      },
    ) as string[];

    tableFilters = {
      ...tableFilters,
      overridesStatus: getSetTypeFilter(tableOverrides),
    };
  }

  if (reportParams.nextExpirationDateFilterType) {
    tableFilters = {
      ...tableFilters,
      nextComplianceExpiration: getTableDateFilterFromReportFilter(
        reportParams,
        'nextExpirationDate',
      ),
    };
  }

  if (reportParams.lastActivityDateFilterType) {
    tableFilters = {
      ...tableFilters,
      lastActivity: getTableDateFilterFromReportFilter(
        reportParams,
        'lastActivityDate',
      ),
    };
  }

  if (reportParams.failedMessagesFilterType) {
    tableFilters = {
      ...tableFilters,
      messageFailures: getTableDateFilterFromReportFilter(
        reportParams,
        'failedMessages',
      ),
    };
  }

  if (reportParams.contactEmail) {
    tableFilters = {
      ...tableFilters,
      contactEmailStatus: getSetTypeFilter(reportParams.contactEmail),
    };
  }

  return {
    filters: tableFilters,
    sorting: {
      columnId: reportParams.sortField,
      direction: reportParams.sortDirection,
    },
  };
};
