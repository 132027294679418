import { FILTER_OPERATORS, FILTER_TYPES } from '@common/constants/filters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

// TODO: In this mutation in the future will be added the return type for handling bulk operations
const ENABLE_PRIMARY_RECORD_AUTOMATION_MUTATION = graphql(`
  mutation EnablePrimaryRecordAutomation($data: PrimaryRecordsAutomationInput!) {
    enablePrimaryRecordsAutomation(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useEnableAutomationMutation = () => {
  const [enablePrimaryRecordAutomation, { loading: isEnablingAutomation }] =
    useMutation(ENABLE_PRIMARY_RECORD_AUTOMATION_MUTATION);

  const enableAutomation = (id: string, automationId: string) => {
    return enablePrimaryRecordAutomation({
      variables: {
        data: {
          automationId,
          filter: {
            and: [
              {
                name: FILTER_TYPES.id,
                operator: FILTER_OPERATORS.in,
                value: [id],
              },
            ],
          },
        },
      },
    });
  };

  return {
    enableAutomation,
    isEnablingAutomation,
  };
};
