import { graphql } from '@graphql/types';
import { namespace } from '../constants';

export const VALIDATE_REQUEST_TOKEN = `${namespace}/VALIDATE_REQUEST_TOKEN`;

export const VALIDATE_REQUEST_TOKEN_QUERY = graphql(`
  query validateRequestToken($data: ValidateRequestTokenInput!) {
    validateRequestToken(data: $data) {
      token
      requestId
      recipientEmail
      primaryRecordId
    }
  }
`);
