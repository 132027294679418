import { PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { BoxIcon } from '@common/icons/BoxIcon';
import { OutlinedBoxIcon } from '@common/icons/OutlinedBoxIcon';
import { ComplianceProfilesSelect } from '@modules/compliance-profile/containers/ComplianceProfilesSelect/ComplianceProfilesSelect';
import { RequirementLabel, ThreeDotsMenu } from '@trustlayer/ui';
import { Alert, Button, Card, Empty } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { CheckboxesList } from '../../components/CheckboxesList';
import { AddDocumentChecklistModal } from '../AddDocumentChecklistModal';
import { AddRequirementForm } from '../AddRequirementForm';
import {
  getAttributesWarningText,
  getRequirementsWarningText,
  reorderComplianceProfile,
} from './ComplianceEditor.utils';
import { AddModuleModal } from './components/AddModuleModal';
import {
  ComplianceProfileDragAndDrop,
  type OnChangedParams,
} from './components/ComplianceProfileDragAndDrop';
import { ComplianceProfileSkeleton } from './components/ComplianceProfileSkeleton';
import { DragAndDropIconProvider } from './components/DragAndDropIconProvider';
import { useComplianceEditor } from './hooks/useComplianceEditor';
import { ComplianceProfileEntity } from './type';

const { Droppable, Draggable } = ComplianceProfileDragAndDrop;

type ComplianceEditorProps = {
  requestId: string;
  complianceProfileId: string;
  onClose: () => void;
  onCompleted?: () => void;
};

const availableForms: any[] = [];

export const ComplianceEditor = ({
  requestId,
  complianceProfileId,
  onClose,
  onCompleted,
}: ComplianceEditorProps) => {
  const [selectedProfileId, setSelectedProfileId] = useState<
    string | undefined
  >(complianceProfileId);
  const [
    isAddDocumentChecklistModalVisible,
    setIsAddDocumentChecklistModalVisible,
  ] = useState(false);
  const [isAddModuleModalVisible, setIsAddModuleModalVisible] = useState(false);
  const { modules, onChanged, loading } = useComplianceEditor({ requestId });
  const handleOnSave = () => {
    onCompleted?.();
  };

  const onDragAndDropChanged = (result: OnChangedParams | undefined) => {
    if (!result) {
      return;
    }

    onChanged((currentModules) =>
      reorderComplianceProfile({
        modules: currentModules,
        ...result,
      }),
    );
  };

  /**
   * @note In order to use properly the DrangAndDrop library in the local env, you should disable the React.StrictMode.
   * see @link https://github.com/atlassian/react-beautiful-dnd/issues/2350
   *
   * @note The proposed workaround doesn't work properly in our context.
   */

  const mainContent = !modules.length ? (
    <Empty
      description="No requirements to show"
      image={Empty.PRESENTED_IMAGE_SIMPLE}
    />
  ) : (
    <>
      <Alert
        message={`Editing requirements will create a custom compliance profile,
            assigned only to this request record.`}
        type="warning"
      />
      <ComplianceProfileDragAndDrop onChanged={onDragAndDropChanged}>
        <Droppable
          id={ComplianceProfileEntity.modules}
          type={ComplianceProfileEntity.modules}
        >
          {modules.map((module, index) => (
            <Draggable key={module.code} id={module.code} index={index}>
              <StyledModuleCard
                title={
                  <DragAndDropIconProvider>
                    <OutlinedBoxIcon />
                    <h3>{module.label}</h3>
                  </DragAndDropIconProvider>
                }
              >
                <Droppable
                  id={module.code}
                  type={ComplianceProfileEntity.subjects}
                >
                  {module.subjects.map((subject, index) => (
                    <Draggable
                      key={subject.code}
                      id={`${module.code}-${subject.code}`}
                      index={index}
                    >
                      <StyledSubject>
                        <DragAndDropIconProvider>
                          <BoxIcon />
                          <span className="title">{subject.label}</span>
                          <span className="subtitle">{`${subject.requirements.length} requirements`}</span>
                          <ThreeDotsMenu
                            items={[
                              {
                                label: 'Remove requirements group',
                                code: 'remove',
                              },
                            ]}
                          />
                        </DragAndDropIconProvider>
                        <Droppable
                          id={subject.code}
                          type={ComplianceProfileEntity.requirements}
                        >
                          {subject.requirements.map((requirement, index) => (
                            <Draggable
                              key={requirement.attributeCode}
                              id={`${module.code}-${subject.code}-${requirement.attributeCode}`}
                              index={index}
                            >
                              <StyledRequirement>
                                <DragAndDropIconProvider>
                                  <RequirementLabel
                                    attributeLabel={requirement.attributeLabel}
                                    operator={requirement.operator}
                                    attributeType={requirement.attributeType}
                                    targetValue={requirement.targetValue}
                                    custom={Boolean(requirement.custom)}
                                    status={requirement.status}
                                    matchingCriteria={
                                      requirement.matchingCriteria
                                    }
                                    documentAttributeCode={
                                      requirement.documentAttributeCode
                                    }
                                  />

                                  <ThreeDotsMenu
                                    items={[
                                      {
                                        label: 'Delete requirement',
                                        code: 'remove',
                                      },
                                      {
                                        label: 'Edit requirement',
                                        code: 'edit',
                                      },
                                    ]}
                                  />
                                </DragAndDropIconProvider>
                              </StyledRequirement>
                            </Draggable>
                          ))}
                        </Droppable>
                        <StyledRequirement>
                          <AddRequirementForm
                            subjectId={subject.code}
                            onConfirm={() => {
                              console.log('Requirement added');
                            }}
                          />
                        </StyledRequirement>
                      </StyledSubject>
                    </Draggable>
                  ))}
                </Droppable>
                <StyledSubject>
                  <StyledAddButton type="dashed" icon={<PlusOutlined />}>
                    Add requirements group
                  </StyledAddButton>
                </StyledSubject>
              </StyledModuleCard>
            </Draggable>
          ))}
        </Droppable>
      </ComplianceProfileDragAndDrop>
    </>
  );

  return (
    <>
      <AddDocumentChecklistModal
        visible={isAddDocumentChecklistModalVisible}
        onClose={() => setIsAddDocumentChecklistModalVisible(false)}
        onAddDocumentChecklist={(typeId) => {
          setIsAddDocumentChecklistModalVisible(false);
        }}
        excludedDocumentTypeIds={[]}
      />

      <AddModuleModal
        visible={isAddModuleModalVisible}
        onSend={(moduleName) => {
          setIsAddModuleModalVisible(false);
        }}
        onClose={() => setIsAddModuleModalVisible(false)}
      />

      <StyledEditor>
        <StyledHeader>
          <div className="row main">
            <span className="subtitle">Compliance profile name</span>
            <ComplianceProfilesSelect
              className="select"
              defaultValue={selectedProfileId}
              onChange={({ _id }) => {
                setSelectedProfileId(_id);
              }}
            />
          </div>
          <div className="row">
            <Button
              onClick={() => {
                onClose?.();
              }}
            >
              Close
            </Button>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              onClick={handleOnSave}
            >
              Save changes
            </Button>
          </div>
        </StyledHeader>
        <StyledGrid>
          <StyledMainColumn>
            {loading ? <ComplianceProfileSkeleton /> : mainContent}
          </StyledMainColumn>
          <StyledAside>
            <section>
              <CheckboxesList.ListTitle>
                Compliance Modules
              </CheckboxesList.ListTitle>
              <CheckboxesList
                showWarning
                items={modules.map((module) => ({
                  label: module.label,
                  id: module.code,
                  isActive: true,
                }))}
                onChange={() => {}}
                getWarningText={getRequirementsWarningText}
              />
              <StyledAddButton
                type="dashed"
                icon={<PlusOutlined />}
                onClick={() => {
                  setIsAddModuleModalVisible(true);
                }}
              >
                Add custom module
              </StyledAddButton>
            </section>

            {Boolean(availableForms.length) && (
              <section>
                <CheckboxesList.ListTitle>
                  Form modules
                </CheckboxesList.ListTitle>
                <CheckboxesList
                  showWarning
                  items={availableForms}
                  onChange={() => {}}
                  getWarningText={getRequirementsWarningText}
                />
              </section>
            )}

            <section>
              <CheckboxesList.ListTitle>
                Document checklists
              </CheckboxesList.ListTitle>
              <CheckboxesList
                showWarning
                items={[]}
                onChange={() => {}}
                getWarningText={getAttributesWarningText}
              />
              <StyledAddButton
                type="dashed"
                icon={<PlusOutlined />}
                onClick={() => {
                  setIsAddDocumentChecklistModalVisible(true);
                }}
              >
                Add new checklist
              </StyledAddButton>
            </section>
          </StyledAside>
        </StyledGrid>
      </StyledEditor>
    </>
  );
};

const StyledEditor = styled.section`
  height: 100%;
`;

const StyledHeader = styled.section`
  position: sticky;
  top: 0;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  background-color: white;
  align-items:  center;
  gap: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  z-index: 1;

  .row {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .subtitle {
    white-space: nowrap;
    font-weight: 500;
    font-size: 16px;
    opacity: 0.8;
  }

  .main {
    flex: 1;
  }

  .select {
    max-width: 400px;
  }
`;

const StyledGrid = styled.section`
  padding: 24px;
  display: grid;
  grid-template-columns: 1fr 300px;
  position: relative;
  min-height: 90%;
`;

const StyledMainColumn = styled.section`
  padding: 0 24px 24px 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const StyledAside = styled.aside`
  border-left: 1px solid ${({ theme }) => theme.colors.gray};
  padding: 0 0 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const StyledModuleCard = styled(Card)`
  margin: 24px 0;

  .ant-card-head {
    min-height: auto;
    border: none;
    padding: 16px 8px 8px;
  }

  .ant-card-body {
    padding: 0 16px 16px;
  }

  h3 {
    margin: 0;
  }
`;

const StyledRequirement = styled.div`
  padding: 4px 0 4px 56px;
`;

const StyledSubject = styled.div`
  padding: 8px 0 8px 24px ;

  .title {
    font-weight: 500;
    font-size: 14px;
  }

  .subtitle {
    padding: 0 4px;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.darkGrayText};
  }
`;

const StyledAddButton = styled(Button)`

  &&.ant-btn {
    box-shadow: none;
  }
`;
