import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type {
  OrganizationMember,
  OrganizationMembersQuery,
} from '@graphql/types/graphql';

type Member = Omit<OrganizationMember, 'organization' | 'role'>;

const TAGGABLE_ORGANIZATION_MEMBERS_QUERY = graphql(`
  query CommentEventLog_OrganizationMembers {
    listOrganizationMembers {
      _id
      user {
        email
        _id
        profile {
          name
          avatar
        }
      }
    }
    me {
      _id
      email
      profile {
        name
        avatar
      }
    }
  }
`);

const excludeMembersWithoutName = (members: Member[]) =>
  members.filter(({ user }) => user?.profile?.name?.trim());

const excludeUserById = (idToExclude: string, members: Member[]) => {
  return members.filter((member) => member.user._id !== idToExclude);
};

const getTaggableMembers = (data?: OrganizationMembersQuery) => {
  if (!data) return data;

  const validMembers = excludeMembersWithoutName(
    data?.listOrganizationMembers || [],
  );
  const taggableMembers = excludeUserById(data.me?._id, validMembers);

  return taggableMembers.map((member) => ({
    id: member.user._id,
    name: `@${member.user.profile.name.replace(/ /g, '')}`,
  }));
};

export const useTaggableMembers = () => {
  const { data, loading } = useQuery(TAGGABLE_ORGANIZATION_MEMBERS_QUERY);

  const taggableMembers = getTaggableMembers(data);

  return {
    taggableMembers,
    loading,
    currentUser: data?.me,
  };
};
