import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const FETCH_DOCUMENTS = `${namespace}/FETCH_DOCUMENTS`;

const FETCH_DOCUMENTS_QUERY = gql`
  query listDocuments($filter: Any, $sort: Any, $skip: NonNegativeInt, $limit: NonNegativeInt) {
    listDocuments(filter: $filter, sort: $sort, skip: $skip, limit: $limit) {
      _id
      friendlyName
      expirationDate
      notes
      projects {
        _id
        name
      }
      appliesToAllProjects
      types {
        _id
        name
        pluralName
        isSystemType
      }
      party {
        _id
        name
        requirements {
          subjectId
          subjectLabel
        }
        partyComplianceProfile {
          _id
          name
        }
      }
      flag {
        addedOn
        severityLevel
        affectedSubjects
        notes
        user {
          _id
          email
          profile {
            name
          }
        }
        authorName
      }
      verification {
        verifiedOn
        verifiedVia
        affectedSubjects
        notes
        user {
          _id
          email
          profile {
            name
          }
        }
      }
      metadata
      organization
      url
      archivedAt
      createdAt
      reviewedAt
      deletedAt
      s3Key
      connectedLicense {
        _id
      }
    }
    countDocuments(filter: $filter, skip: $skip, limit: $limit)
  }
`;

export const fetchDocuments = (payload) => ({
  type: FETCH_DOCUMENTS,
  payload: {
    key: 'listDocuments',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_DOCUMENTS_QUERY,
      variables: payload,
    },
  },
});
