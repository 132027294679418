import {
  FILTER_OPERATORS,
  FILTER_TYPES,
} from '@common/constants/filters/serverFilters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const ARCHIVE_CONTEXT_RECORD_MUTATION = graphql(`
  mutation ArchiveContextRecordModal($data: ArchiveContextRecordsInput!) {
    archiveContextRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useArchiveContextRecordMutation = () => {
  const [archiveContextRecordMutation, { loading }] = useMutation(
    ARCHIVE_CONTEXT_RECORD_MUTATION,
  );

  const archiveContextRecord = (contextRecordId: string) => {
    return archiveContextRecordMutation({
      variables: {
        data: {
          filter: {
            and: [
              {
                name: FILTER_TYPES.id,
                operator: FILTER_OPERATORS.in,
                value: [contextRecordId],
              },
            ],
          },
        },
      },
    });
  };

  return {
    archiveContextRecord,
    isArchivingContextRecord: loading,
  };
};
