import type { TableSortingState } from '@common/components/Table';
import type { TableFiltersModel } from '@modules/context-records';
import { CONTEXT_RECORD_COMPLIANCE } from '@modules/context-records/constants';
import type { CustomFieldTypes } from '@modules/custom-field/constants';
import { ProjectComplianceStatus as LEGACY_PROJECT_COMPLIANCE_STATUS } from '@modules/project/constants';
import type { LegacyProjectReportsParams } from '@modules/report/types';
import { getReportDateFilter } from '@modules/report/utils';

export const mapTableParamsToLegacyProjectParams = ({
  filters,
  sorting,
}: {
  filters: TableFiltersModel;
  sorting: TableSortingState;
}): LegacyProjectReportsParams => {
  let reportFilters: LegacyProjectReportsParams = {
    sortField: sorting.columnId,
    sortDirection: sorting.direction,
  };

  if (filters?.name?.filter) {
    reportFilters.name = filters.name.filter;
  }

  if (filters?.status) {
    reportFilters.status = filters.status.values as string | string[];
  }

  if (filters.complianceStatus?.values) {
    reportFilters.complianceStatus = filters.complianceStatus.values.map(
      (value) => {
        if (value === CONTEXT_RECORD_COMPLIANCE.Compliant) {
          return LEGACY_PROJECT_COMPLIANCE_STATUS.Compliant;
        }
        if (value === CONTEXT_RECORD_COMPLIANCE.NonCompliant) {
          return LEGACY_PROJECT_COMPLIANCE_STATUS.NonCompliant;
        }
      },
    ) as typeof reportFilters.complianceStatus;
  }

  if (filters.attributes) {
    const { _id: attributeId, type, value } = filters.attributes;
    reportFilters[`customField:${attributeId}:${type as CustomFieldTypes}`] =
      value;
  }

  if (filters.endDate) {
    const lastActivityDateParams = getReportDateFilter(
      filters.endDate,
      'endDate',
    );
    reportFilters = {
      ...reportFilters,
      ...lastActivityDateParams,
    };
  }

  if (filters.startDate) {
    const lastActivityDateParams = getReportDateFilter(
      filters.startDate,
      'startDate',
    );
    reportFilters = {
      ...reportFilters,
      ...lastActivityDateParams,
    };
  }

  return reportFilters;
};
