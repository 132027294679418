import { EditOutlined } from '@ant-design/icons';
import type { FillableForm } from '@graphql/types/graphql';
import { FillableFormRequirementAction } from '@modules/fillable-form/components/FillableFormRequirementAction';
import { Typography } from 'antd';
import styled from 'styled-components';

type SubjectFillableFormProps = {
  fillableForm?: FillableForm;
  onFillClick?: (form: FillableForm) => void;
};

export const SubjectFillableForm = ({
  fillableForm,
  onFillClick,
}: SubjectFillableFormProps) => {
  if (!fillableForm || fillableForm.status === 'filled') return null;

  return (
    <StyledFillableForm>
      <div className="title-wrapper">
        <EditOutlined className="icon" />
        <Typography.Title level={5} style={{ margin: 0 }}>
          {fillableForm.name}
        </Typography.Title>
      </div>

      <FillableFormRequirementAction
        fillableForm={fillableForm}
        onFillClick={() => onFillClick?.(fillableForm)}
      />
    </StyledFillableForm>
  );
};

const StyledFillableForm = styled.div`
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: 8px;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;

  .title-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .icon {
    font-size: 28px;
    color: ${({ theme }) => theme.colors.blue};
  }
`;
