import { useLazyQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

export const SIGNED_URL_QUERY = graphql(`
  query SignedUrl($s3Key: NonEmptyString!) {
    signedUrl(s3Key: $s3Key)
  }
`);

export const useSignedUrl = () => {
  const [signUrl, { loading, error }] = useLazyQuery(SIGNED_URL_QUERY);

  return {
    signUrl,
    loading,
    error,
  };
};
