import { FILTER_OPERATORS } from '@common/constants/filters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const DELETE_CONTEXT_RECORD_ATTACHMENT_MUTATION = graphql(`
  mutation DeleteContextRecordAttachment($data: DeleteContextRecordAttachmentsInput!) {
    deleteContextRecordAttachments(data: $data)
  }
`);

export const useDeleteContextRecordAttachmentMutation = (
  contextRecordId: string,
) => {
  const [
    deleteContextRecordAttachmentMutation,
    { loading: deletingContextRecordAttachment },
  ] = useMutation(DELETE_CONTEXT_RECORD_ATTACHMENT_MUTATION);

  const deleteContextRecordAttachment = (s3Key: string) => {
    return deleteContextRecordAttachmentMutation({
      variables: {
        data: {
          contextRecordId,
          filter: {
            and: [
              {
                name: 's3Key',
                operator: FILTER_OPERATORS.equal,
                value: s3Key,
              },
            ],
          },
        },
      },
    });
  };

  return {
    deleteContextRecordAttachment,
    deletingContextRecordAttachment,
  };
};
