import { forwardRef, useMemo } from 'react';

import {
  CellActions,
  type CustomSelectionChangedEvent,
  DateCustomFilter,
  type FilterChangedParams,
  LinkCell,
  Table,
  type TableSortingState,
  type TableState,
} from '@common/components/Table';
import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';

import { ComplianceStats } from '@common/components/ComplianceStats';
import {
  ContextRecordStatus,
  RECORD_TYPES,
  humanizeDate,
} from '@trustlayer/common';
import {
  type ActionType,
  CONTEXT_RECORDS_ACTION_TYPES,
  CONTEXT_RECORDS_TABS,
  CONTEXT_RECORD_COMPLIANCE,
  CONTEXT_RECORD_STATUS,
} from '../../constants';
import { COLUMN_FIELDS, type ContextRecordsTableRef } from './constants';

import { AttributeCustomFilter } from '@common/components/Table/components/AttributeCustomFilter';
import { useContextRecords } from './hooks/useContextRecords';

type RecordsTableProps = {
  contextObjectSlug: string;
  onSelectionChanged: (params: CustomSelectionChangedEvent) => void;
  onFilterChanged: (params: FilterChangedParams) => void;
  onSortChanged: (params: TableSortingState) => void;
  onPaginationChanged: (params: any) => void;
  onRowActionClick: (
    actionType: ActionType,
    record: any,
    options?: {
      totalDocumentsCount?: number;
    },
  ) => void;
  tableState?: TableState;
};

type SetFilterValue = {
  code: string;
  name: string;
};

const commonSetFilterParams = {
  keyCreator: ({ value }: { value: SetFilterValue }) => value.code,
  valueFormatter: ({ value }: { value: SetFilterValue }) => value.name,
};

export const ContextRecordsTable = forwardRef<
  ContextRecordsTableRef,
  RecordsTableProps
>(
  (
    {
      contextObjectSlug,
      onSelectionChanged,
      onFilterChanged,
      onSortChanged,
      onPaginationChanged,
      onRowActionClick,
      tableState = { filters: {}, sorting: {}, pagination: {} },
    },
    recordsTableRef,
  ) => {
    const { getContextRecords } = useContextRecords({ contextObjectSlug });

    const getActionType = (status: ContextRecordStatus) => {
      if (status === 'archived') {
        return CONTEXT_RECORDS_ACTION_TYPES.makeActive;
      }

      return CONTEXT_RECORDS_ACTION_TYPES.archive;
    };
    // biome-ignore lint/correctness/useExhaustiveDependencies: More Deps Than Needed
    const columns = useMemo(
      () => [
        {
          headerName: 'Name',
          field: COLUMN_FIELDS.name,
          width: 400,
          sortable: true,
          pinned: 'left' as const,
          headerCheckboxSelection: true,
          checkboxSelection: true,
          lockPosition: 'left' as const,
          filter: 'agTextColumnFilter',
          filterParams: {
            filterOptions: ['contains'],
          },
          valueGetter: ({ data }: { data: any }) => data,
          cellRenderer: ({ value: record }: { value: any }) => {
            const route = `${record._id}/${CONTEXT_RECORDS_TABS.Requests.key}`;
            return record?._id && <LinkCell to={route}>{record.name}</LinkCell>;
          },
        },
        {
          headerName: 'Compliance',
          field: COLUMN_FIELDS.compliance,
          sortable: true,
          valueGetter: ({ data }: { data: any }) => data.stats?.requestRecords,
          filter: 'agSetColumnFilter',
          filterParams: {
            ...commonSetFilterParams,
            values: [
              {
                code: CONTEXT_RECORD_COMPLIANCE.Compliant,
                name: 'Compliant',
              },
              {
                code: CONTEXT_RECORD_COMPLIANCE.NonCompliant,
                name: 'Non-Compliant',
              },
            ],
          },
          cellRenderer: ({ value: recordStats }: { value: any }) => {
            return (
              <ComplianceStats
                compliantCount={recordStats.compliantCount}
                totalCount={recordStats.totalCount}
              />
            );
          },
        },
        {
          headerName: 'Status',
          field: COLUMN_FIELDS.status,
          hide: true,
          filter: 'agSetColumnFilter',
          filterParams: {
            ...commonSetFilterParams,
            values: [
              {
                code: CONTEXT_RECORD_STATUS.Active,
                name: 'Active',
              },
              {
                code: CONTEXT_RECORD_STATUS.Archived,
                name: 'Archived',
              },
            ],
          },
        },
        {
          headerName: 'Start Date',
          field: COLUMN_FIELDS.startDate,
          sortable: true,
          filter: DateCustomFilter,
          valueGetter: ({ data }: { data: any }) =>
            data.startDate ? humanizeDate(data.startDate) : '—',
        },
        {
          headerName: 'End Date',
          field: COLUMN_FIELDS.endDate,
          sortable: true,
          filter: DateCustomFilter,
          valueGetter: ({ data }: { data: any }) =>
            data.endDate ? humanizeDate(data.endDate) : '—',
        },
        {
          headerName: 'Attributes',
          field: COLUMN_FIELDS.attributes,
          hide: true,
          filter: AttributeCustomFilter,
          filterParams: {
            recordType: RECORD_TYPES.Context,
          },
        },
        {
          headerName: '',
          field: COLUMN_FIELDS.actions,
          lockPosition: 'right' as const,
          pinned: 'right' as const,
          width: 90,
          maxWidth: 100,
          sortable: false,
          valueGetter: ({ data }: { data: any }) => data,
          //eslint-disable-next-line
          cellRenderer: ({ value: data }: { value: any }) => (
            <CellActions>
              <HideForViewerRole>
                <CellActions.ThreeDotsMenu
                  menuItems={[
                    {
                      label:
                        data?.status === ContextRecordStatus.Archived
                          ? 'Make Active'
                          : 'Archive',
                      code: getActionType(data?.status),
                      disabled: false,
                      onClick: () => {
                        onRowActionClick(getActionType(data?.status), data);
                      },
                    },
                    {
                      label: 'Delete',
                      code: 'delete',
                      disabled: false,
                      alert: true,
                      onClick: () => {
                        onRowActionClick(
                          CONTEXT_RECORDS_ACTION_TYPES.delete,
                          data,
                        );
                      },
                    },
                  ]}
                />
              </HideForViewerRole>
            </CellActions>
          ),
        },
      ],
      //eslint-disable-next-line
      [contextObjectSlug],
    );

    return (
      <Table
        ref={recordsTableRef}
        columnDefs={columns}
        getRowData={getContextRecords}
        onSelectionChanged={onSelectionChanged}
        onFilterChanged={onFilterChanged}
        onSortChanged={onSortChanged}
        onPaginationChanged={onPaginationChanged}
        tableState={tableState}
      />
    );
  },
);
