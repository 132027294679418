import { FILTER_OPERATORS } from '@common/constants/filters';

import { RECORD_TYPES, type RecordTypes } from '@trustlayer/common';
import { COLUMN_FIELDS, REQUESTS_QUERY_FILTER_NAMES } from './constants';

export const mapTableFiltersToQueryFilters = (
  tableFilters: Record<string, any> | null = {},
) => {
  const filters = [];

  if (tableFilters?.[COLUMN_FIELDS.name]) {
    filters.push({
      name: REQUESTS_QUERY_FILTER_NAMES.name,
      operator: FILTER_OPERATORS.contains,
      value: tableFilters[COLUMN_FIELDS.name].filter,
    });
  }

  return filters;
};

const RECORD_SOURCE_ID: Record<
  RecordTypes,
  keyof typeof REQUESTS_QUERY_FILTER_NAMES
> = {
  [RECORD_TYPES.Primary]: 'primaryRecordId',
  [RECORD_TYPES.Context]: 'contextRecordId',
};

export const getDeleteFilters = ({
  recordId,
  source,
  ids,
  textValue,
}: {
  recordId: string;
  source: RecordTypes;
  ids?: string[];
  textValue?: string;
}) => {
  if (ids?.length) {
    return {
      and: [
        {
          name: REQUESTS_QUERY_FILTER_NAMES.id,
          operator: FILTER_OPERATORS.in,
          value: ids,
        },
      ],
    };
  }

  return {
    and: [
      {
        name: REQUESTS_QUERY_FILTER_NAMES[RECORD_SOURCE_ID[source]],
        operator: FILTER_OPERATORS.equal,
        value: recordId,
      },
      ...(textValue
        ? [
            {
              name: REQUESTS_QUERY_FILTER_NAMES.name,
              operator: FILTER_OPERATORS.contains,
              value: textValue,
            },
          ]
        : []),
    ],
  };
};
