import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const MAKE_ACTIVE_CONTEXT_RECORDS_MUTATION = graphql(`
  mutation MakeActiveContextRecordsModal($data: ActivateContextRecordsInput!) {
    activateContextRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useMakeActiveContextRecordsMutation = () => {
  const [
    makeActiveContextRecordsMutation,
    { loading: isMakingActiveContextRecords },
  ] = useMutation(MAKE_ACTIVE_CONTEXT_RECORDS_MUTATION);

  const makeActiveContextRecords = (filters?: ServerFilterInput) => {
    return makeActiveContextRecordsMutation({
      variables: {
        data: {
          filter: filters,
        },
      },
    });
  };

  return {
    makeActiveContextRecords,
    isMakingActiveContextRecords,
  };
};
