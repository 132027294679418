import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { ORGANIZATION_MEMBER_FRAGMENT } from '../fragments';

export const DELETE = `${namespace}/DELETE`;

const DELETE_ORGANIZATION_MEMBER_MUTATION = gql`
  mutation deleteOrganizationMember($id: ObjectId!) {
    deleteOrganizationMember(id: $id) {
      ...OrganizationMember
    }
  }
  ${ORGANIZATION_MEMBER_FRAGMENT}
`;

export const deleteMember = (id) => ({
  type: DELETE,
  payload: {
    key: 'deleteOrganizationMember',
    graphql: {
      mutation: DELETE_ORGANIZATION_MEMBER_MUTATION,
      variables: {
        id,
      },
    },
  },
});
