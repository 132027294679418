import { useEffect, useState } from 'react';
import styled from 'styled-components';
import ProjectMultipleSelectContainer from '../ProjectMultipleSelectContainer';
import { ProjectFilterTypeSelect } from './ProjectFilterTypeSelect';
import type { ProjectFilterType, ProjectFilterTypeOption } from './constants';

type ProjectItem = { _id: string; name: string };
type ProjectItemOption = { value: string; label: string };

type ProjectFilterProps = {
  options: ProjectFilterTypeOption[];
  filterType?: ProjectFilterType;
  selectedProjects?: string[];
  onChange: ({
    type,
    projects,
  }: { type: ProjectFilterType; projects?: string[] }) => void;
  initialProjects: ProjectItem[];
  withAppliesToAllProjects?: boolean;
  visibleInactiveProjects?: boolean;
};

export const ProjectFilter = ({
  options,
  filterType,
  selectedProjects,
  onChange,
  initialProjects,
  withAppliesToAllProjects,
  visibleInactiveProjects,
}: ProjectFilterProps) => {
  const [currentFilterType, setCurrentFilterType] = useState<
    ProjectFilterType | undefined
  >(filterType);

  const handleFilterTypeChange = (value: ProjectFilterType) => {
    setCurrentFilterType(value);
    if (value === 'assignedToProjects') {
      onChange({
        type: value,
        projects: selectedProjects,
      });
      return;
    }

    onChange({ type: value });
  };

  const handleProjectsChange = (options: ProjectItemOption[]) => {
    onChange({
      type: filterType ?? 'assignedToProjects',
      projects: options.map(({ value }) => value),
    });
  };

  useEffect(() => {
    setCurrentFilterType(filterType ?? currentFilterType);
  }, [filterType, currentFilterType]);

  return (
    <StyledContainer>
      <ProjectFilterTypeSelect
        options={options}
        value={currentFilterType}
        onChange={handleFilterTypeChange}
      />
      {currentFilterType === 'assignedToProjects' && (
        <ProjectMultipleSelectContainer
          initialProjects={initialProjects}
          onChange={handleProjectsChange}
          withAppliesToAllProjects={withAppliesToAllProjects}
          visibleInactiveProjects={visibleInactiveProjects}
        />
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
