import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

export const CONTEXT_OBJECT_QUERY = graphql(`
  query ContextObject($input: ContextObjectInput!) {
    contextObject(input: $input) {
      _id
      name
      icon
      pluralName
      slug
    }
  }
`);

export const useContextObjectQuery = (objectSlug: string | undefined) => {
  const { data, loading } = useQuery(CONTEXT_OBJECT_QUERY, {
    variables: { input: { slug: objectSlug } },
    skip: !objectSlug,
  });

  return {
    contextObject: data?.contextObject,
    isContextObjectLoading: loading,
  };
};
