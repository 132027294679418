import { FullWidthSelect } from '@trustlayer/ui';
import { Select } from 'antd';
import type { ProjectFilterType, ProjectFilterTypeOption } from './constants';

type ProjectFilterTypeSelectProps = {
  options: ProjectFilterTypeOption[];
  value?: ProjectFilterType;
  onChange: (value: ProjectFilterType) => void;
};

export const ProjectFilterTypeSelect = ({
  value,
  options,
  onChange,
}: ProjectFilterTypeSelectProps) => {
  return (
    <div data-cy="projectFilterTypeSelect">
      <FullWidthSelect
        allowClear
        placeholder="Select..."
        defaultValue={value}
        onChange={onChange}
      >
        {options.map((option) => (
          <Select.Option key={option.key} value={option.key}>
            {option.label}
          </Select.Option>
        ))}
      </FullWidthSelect>
    </div>
  );
};
