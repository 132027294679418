import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { PartyConversationType } from '@modules/party-conversation/constants';
import { getPartyAutomationsMap } from '@modules/party/utils';
import { useMemo } from 'react';

export const PRIMARY_RECORD_QUERY = graphql(`
  query SendPrimaryRecordRequestDrawer_PrimaryRecord($primaryRecordInput: PrimaryRecordInput!, $conversationsFilter: ConversationsInput!) {
    primaryRecord(input: $primaryRecordInput) {
      _id
      contacts {
        nodes {
          _id
          contactPersonName
          email
        }
      }
      conversations(input: $conversationsFilter) {
        nodes {
          _id
        }
      }
      automations {
        _id
        code
        name
        actionsCount
        branches {
          nodes {
            _id
            trigger {
              event
              type
              filter
            }
            steps {
              ... on Action {
                type
                job
                data {
                  ... on EmailActionData {
                    emailTemplate {
                      _id
                    }
                  }
                }
              }
              ... on Wait {
                type
                duration
              }
            }
          }
        }
        status
        processing
        scheduledActions {
          status
          scheduledAt
          executedAt
          result
        }
      }
    }
  }
`);

export const usePrimaryRecordQuery = ({
  primaryRecordId,
  skipQuery,
}: {
  primaryRecordId: string;
  skipQuery: boolean;
}) => {
  const { data, loading } = useQuery(PRIMARY_RECORD_QUERY, {
    includeTypename: true,
    returnPartialData: false,
    variables: {
      primaryRecordInput: {
        id: primaryRecordId,
      },
      conversationsFilter: {
        filter: {
          and: [
            {
              name: 'type',
              operator: 'in',
              value: [PartyConversationType.DocumentRequest],
            },
          ],
        },
      },
    },
    skip: skipQuery,
  });

  const automationsMap = useMemo(() => {
    return getPartyAutomationsMap({
      // @ts-expect-error - branches is typed to expect more props that required
      partyAutomations: data?.primaryRecord?.automations || [],
    });
  }, [data?.primaryRecord?.automations]);

  return {
    contacts: data?.primaryRecord?.contacts?.nodes || [],
    hasRequestConversation: !!data?.primaryRecord?.conversations?.nodes?.length,
    automationsMap,
    isPrimaryRecordLoading: loading,
  };
};
